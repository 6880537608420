import { PressLink } from "components/Button";
import styled from "styled-components";
import { SquigglyLines } from "./SquigglyLines";
import { Huge } from "theme/typography";
import { GradientText } from "components/GradientText";

export const RadialLanding = () => {
  return (
    <Radial>
      <MainWrapper>
        <LandingTitle>
          <StyledSpan>
            Unleash the power
            <SquigglyLines />
          </StyledSpan>{" "}
          of personalization and automation
        </LandingTitle>
        <Description>
          Create highly targeted email campaigns that convert.
        </Description>
        <StyledPressLink text="Start for free" to="/register" variant="cta" />
      </MainWrapper>
    </Radial>
  );
};

const StyledPressLink = styled(PressLink)`
  margin-top: 2rem;
`;

const Radial = styled.div`
  margin-top: 8rem;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 76, 41, 0.376) 0%,
    rgba(37, 38, 44, 0) 100%
  );
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5rem;
  padding-right: 5rem;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const StyledSpan = styled.span`
  display: block;
  position: relative;
  ${GradientText}
`;

const LandingTitle = styled(Huge)`
  text-align: center;
  margin-bottom: 1rem;
  ${GradientText}
`;

const Description = styled.p`
  text-align: center;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    font-size: 1.2rem;
  }
`;
