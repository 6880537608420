import { Link } from "react-router-dom";
import { type ListDetails } from "../api/types";
import { styled } from "styled-components";
import { P2Regular, SmallText } from "theme/typography";
import { formattedDate } from "utils/formattedDate";

interface ListRowProps {
  list: ListDetails;
}

const getSubscriberText = (value: number) => {
  if (value === 1) return `${value} subscriber`;
  return `${value} subscribers`;
};

export const ListRow = ({ list }: ListRowProps) => {
  return (
    <Container to={`${list.id}/subscribers`}>
      <LeftWrapper>
        <Text>{list.name}</Text>
        <DateText>Created at: {formattedDate(new Date(list.created))}</DateText>
      </LeftWrapper>
      <RightText>{getSubscriberText(list.total_email_subscribers)}</RightText>
    </Container>
  );
};

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Text = styled(P2Regular)``;

const DateText = styled(SmallText)`
  color: ${({ theme }) => theme.colors.primary};
`;

const RightText = styled(P2Regular)`
  font-size: 0.8rem;
`;

const Container = styled(Link)`
  border-radius: ${({ theme }) => theme.dimensions.radius};
  padding: 1rem;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.background};
  margin-bottom: 1rem;
  transition: 0.4s;

  &:hover {
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.white};
  }
`;
