import { Icon } from "assets/icons";
import styled from "styled-components";

interface SortButtonProps {
  sort?: "ascending" | "descending";
  onClick: () => void;
}

export const SortButton = ({ sort, onClick }: SortButtonProps) => {
  return (
    <SortContainer onClick={onClick}>
      <Icon
        name="triangle"
        color="white"
        size={10}
        fill={sort === "ascending" ? "white" : "transparent"}
      />
      <DownIcon>
        <Icon
          name="triangle"
          color="white"
          size={10}
          fill={sort === "descending" ? "white" : "transparent"}
        />
      </DownIcon>
    </SortContainer>
  );
};

const SortContainer = styled.button`
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: flex-start;
  cursor: pointer;
`;

const DownIcon = styled.div`
  transform: rotate(180deg);
  display: flex;
`;
