import styled from "styled-components";
import { landingSections } from "../utils/landingSections";
import { BlurBall } from "components/BlurBall";
import { generateRandomNumber } from "utils/randomNumber";

export const LandingSections = () => {
  const generateRandomCoordinates = () => {
    const top = generateRandomNumber(0, 60);
    const left = generateRandomNumber(0, 80);
    const width = generateRandomNumber(200, 500);
    const height = generateRandomNumber(200, 500);
    return {
      top: `${top}%`,
      left: `${left}%`,
      width: `${width}px`,
      height: `${height}px`,
    };
  };
  return (
    <Sections>
      {landingSections.map((el, index) => {
        const { top, left, width, height } = generateRandomCoordinates();
        return (
          <Row key={el.title} isOdd={index % 2 !== 0}>
            <BlurBall
              top={top}
              left={left}
              width={width}
              height={height}
              animation
            />
            <Info>
              <h1>{el.title}</h1>
              <p>{el.description}</p>
            </Info>
            <StyledImage isOdd={index % 2 !== 0} src={el.image} />
          </Row>
        );
      })}
    </Sections>
  );
};

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.L}) {
    gap: 10rem;
  }
`;

const StyledImage = styled.img<{ isOdd: boolean }>`
  border-radius: ${({ theme }) => theme.dimensions.radius};
  background-color: ${({ theme }) => theme.colors.grey10};
  width: 50%;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 0.4rem;
  transform: translate(${({ isOdd }) => (isOdd ? "2rem" : "-2rem")}, 0)
    rotate(${({ isOdd }) => (!isOdd ? "-45deg" : "45deg")}) skewX(0)
    skewY(${({ isOdd }) => (!isOdd ? "12deg" : "-12deg")}) scaleX(0.8)
    scaleY(0.8);
  z-index: 1;
  filter: drop-shadow(0px 2rem 2rem ${({ theme }) => theme.colors.main});
  @media (max-width: ${({ theme }) => theme.breakpoints.L}) {
    width: 100%;
    transform: none;
  }
`;

const Info = styled.div`
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 10;

  @media (max-width: ${({ theme }) => theme.breakpoints.L}) {
    width: 100%;
  }
`;

const Row = styled.div<{ isOdd: boolean }>`
  text-align: start;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.dimensions.radius};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: ${({ isOdd }) => (isOdd ? "row" : "row-reverse")};
  gap: 2.5rem;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    scale: 1;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.L}) {
    flex-direction: column;
    overflow: hidden;
  }
`;
