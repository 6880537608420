import { useTheme } from 'styled-components';
import { Label, Wrapper } from './sharedStyled';
import Select, {
	type ActionMeta,
	type MultiValue,
	type SingleValue,
} from 'react-select';
import { type ErrorType } from '.';
import { getErrorMsg } from './Input';

export interface Option {
	label: string;
	value: string;
}

export type MultiType = MultiValue<Option>;
export type SingleType = SingleValue<Option>;
export type DropdownValue = SingleType | MultiType;

export type DropdownNewValue = SingleType | MultiType;
export type DropdownAction = ActionMeta<Option>;
export type OnDropdownChangeType = (
	newValue: DropdownNewValue,
	actionMeta: DropdownAction
) => void;

interface DropdownProps {
	options: Option[];
	placeholder?: string;
	onValueChange?: OnDropdownChangeType;
	// selectedValue: Option;
	label?: string;
	isMulti?: boolean;
	error?: ErrorType;
	value?: DropdownValue;
	isDisabled?: boolean;
}

export const Dropdown = ({
	options,
	onValueChange,
	placeholder,
	label,
	isMulti,
	error,
	value,
	isDisabled,
}: DropdownProps) => {
	const theme = useTheme();
	return (
		<Wrapper isDisabled={isDisabled}>
			{label && <Label>{label}</Label>}
			<Select
				value={value}
				placeholder={placeholder}
				defaultValue={null}
				onChange={onValueChange}
				options={options}
				isMulti={isMulti}
				isDisabled={isDisabled}
				styles={{
					control: (baseStyles, { isFocused, menuIsOpen }) => ({
						...baseStyles,
						padding: '0.3rem',
						borderRadius: theme?.dimensions.radius,
						borderColor: isFocused ? theme?.colors.main : theme?.colors.primary,
						boxShadow: 'none',
						fontSize: theme?.text.small,
						fontFamily: 'Poppins',
						fontWeight: 400,
						borderBottomLeftRadius: menuIsOpen ? 0 : theme?.dimensions.radius,
						borderBottomRightRadius: menuIsOpen ? 0 : theme?.dimensions.radius,
						'&:hover': {
							borderColor: isFocused
								? theme?.colors.main
								: theme?.colors.primary,
						},
						borderBottomWidth: menuIsOpen ? 0 : theme?.colors.main,
					}),
					option: (baseStyles, { isSelected, isFocused }) => ({
						...baseStyles,
						fontFamily: 'inherit',
						fontSize: theme?.text.small,
						background:
							isSelected || isFocused ? theme?.colors.grey10 : 'transparent',
						color: theme?.colors.primary,
						'&:hover': {
							background: theme?.colors.grey10,
						},
					}),
					menu: (baseStyles, state) => ({
						...baseStyles,
						marginTop: 0,
						borderRadius: 0,
						boxShadow: 'none',
						border: '1px solid ' + theme?.colors.main,
						borderTopWidth: 0,
						borderBottomLeftRadius: theme?.dimensions.radius,
						borderBottomRightRadius: theme?.dimensions.radius,
						padding: 0,
					}),
					menuList: (baseStyles, state) => ({
						...baseStyles,
						paddingTop: 0,
						paddingBottom: 10,
					}),
				}}
				components={{
					IndicatorSeparator: () => null,
				}}
				// value={selectedValue}
			/>
			{getErrorMsg(error)}
		</Wrapper>
	);
};
