import { useOutletContext, useParams } from "react-router-dom";

import { Spinner } from "components/Spinner";
import { type ListContextType } from "../components/ListDetailsLayout";
import { BaseToggle } from "components/Toggle";
import { CardContainer } from "components/CardContainer";
import { useContactDetails } from "../hooks/useContactDetails";
import { BackLink } from "components/BackLink";
import styled from "styled-components";
import { Dropdown, Input } from "components/Forms";
import { Button } from "components";
import { type MultiType } from "components/Forms/Dropdown";

export const ContactDetails = () => {
  const { listId, detail } = useOutletContext<ListContextType>();
  const { contactId = "" } = useParams();

  const {
    isLoading,
    values,
    contactData,
    tags,
    onValueChange,
    onUpdateContact,
    onDropdownChange,
    onToggleSubscribe,
  } = useContactDetails({
    contactId,
    listId,
  });

  if (isLoading) return <Spinner />;
  if (!contactData) return <p>No data found</p>;

  const dropdownOptions = tags?.map((item) => ({ label: item.name, value: item.id })) ?? []

  return (
    <CardContainer>
      <BackLink
        to={`/lists/${listId}/subscribers`}
        text={`Back to ${detail.name}`}
      />

      <StyledForm>
        <BaseToggle
          isChecked={values.is_subscribed}
          label="Subscribed"
          leftLabel="Unsubscribed"
          onChange={onToggleSubscribe}
        />
        <Input
          label="Email"
          value={values.email}
          onChange={onValueChange}
          name="email"
        />
        <NameWrapper>
          <Input
            label="First name"
            name="first_name"
            value={values.first_name}
            onChange={onValueChange}
          />
          <Input
            label="Last name"
            name="last_name"
            value={values.last_name}
            onChange={onValueChange}
          />
        </NameWrapper>
        <Dropdown
          options={
            dropdownOptions
          }
          onValueChange={(value) => { onDropdownChange("tags", value as MultiType); }}
          placeholder="Tags"
          label="Tags"
          value={dropdownOptions.filter((item) => values.tags.includes(item.value))}
          isMulti
        />
        <Button type="submit" text="Update" onClick={onUpdateContact} />
      </StyledForm>
    </CardContainer>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const NameWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
