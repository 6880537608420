import { api } from "services/api/api";
import {
  type AccountAddress,
  type AccountDetails,
  type AccountSettings,
  type SendersType,
  type UserExtraInfo,
  type UserInfoType,
} from "./types";
import { type FormOptions } from "types/FormOptions";

export const ACCOUNT_TAG = "Account";
export const USER_INFO_TAG = "UserInfo";
export const USER_EXTRA_INFO_TAG = "UserExtraInfo";
export const DETAILS_TAG = "UserInfo";
export const ADDRESS_TAG = "Address";
export const DETAILS_OPTIONS_TAG = "DetailsOptions";
export const ADDRESS_OPTIONS_TAG = "AddressOptions";
export const USER_INFO_OPTIONS_TAG = "UserOptions";
export const SENDERS_TAG = "Senders";
export const VERIFIED_SENDERS_TAG = "VerifiedSenders";

export const settingsApi = api
  .enhanceEndpoints({
    addTagTypes: [
      ACCOUNT_TAG,
      USER_INFO_TAG,
      DETAILS_OPTIONS_TAG,
      USER_INFO_OPTIONS_TAG,
      SENDERS_TAG,
      USER_EXTRA_INFO_TAG,
      ADDRESS_OPTIONS_TAG,
      VERIFIED_SENDERS_TAG,
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getAccountSettings: build.query<AccountSettings, void>({
        query: () => ({
          method: "GET",
          url: "/settings/v1/",
        }),
        providesTags: [ACCOUNT_TAG],
      }),

      postAccountAddress: build.mutation<AccountAddress, AccountAddress>({
        query: (args) => ({
          body: args,
          method: "POST",
          url: "/settings/v1/address/create",
        }),
        invalidatesTags: (result, error) => (error ? [] : [ACCOUNT_TAG]),
      }),
      updateAccountAddress: build.mutation<AccountAddress, AccountAddress>({
        query: (args) => ({
          body: args,
          method: "PUT",
          url: "/settings/v1/address",
        }),
        invalidatesTags: [ACCOUNT_TAG],
      }),
      getAccountAddressOptions: build.query<FormOptions, void>({
        query: () => ({
          method: "OPTIONS",
          url: "/settings/v1/address/create",
        }),
        providesTags: [ADDRESS_OPTIONS_TAG],
      }),
      postAccountDetails: build.mutation<AccountDetails, AccountDetails>({
        query: (args) => ({
          body: args,
          method: "POST",
          url: "/settings/v1/details/create",
        }),
        invalidatesTags: (result, error) => (error ? [] : [ACCOUNT_TAG]),
      }),
      updateAccountDetails: build.mutation<AccountDetails, AccountDetails>({
        query: (args) => ({
          body: args,
          method: "PUT",
          url: "/settings/v1/details",
        }),
        invalidatesTags: (result, error) => (error ? [] : [ACCOUNT_TAG]),
      }),
      getAccountDetailsOptions: build.query<FormOptions, void>({
        query: () => ({
          method: "OPTIONS",
          url: "/settings/v1/details/create",
        }),
        providesTags: [DETAILS_OPTIONS_TAG],
      }),
      getUserInformation: build.query<UserInfoType, void>({
        query: () => ({
          method: "GET",
          url: "/users/v1/me/",
        }),
        providesTags: [USER_INFO_TAG],
      }),
      getUserExtraInformation: build.query<UserExtraInfo, void>({
        query: () => ({
          method: "GET",
          url: "/users/v1/info/",
        }),
        providesTags: [USER_EXTRA_INFO_TAG],
      }),
      getUserInformationOptions: build.query<FormOptions, void>({
        query: () => ({
          method: "OPTIONS",
          url: "/users/v1/me/",
        }),
        providesTags: [USER_INFO_OPTIONS_TAG],
      }),
      postUserInformation: build.mutation<UserInfoType, UserInfoType>({
        query: (args) => ({
          body: args,
          method: "PATCH",
          url: "/users/v1/me/",
        }),
        invalidatesTags: (result, error) => (error ? [] : [USER_INFO_TAG]),
      }),
      getSenders: build.query<SendersType[], void>({
        query: () => ({
          method: "GET",
          url: "/settings/v1/senders",
        }),
        providesTags: [SENDERS_TAG],
      }),
      getVerifiedSenders: build.query<SendersType[], void>({
        query: () => ({
          method: "GET",
          url: "/settings/v1/senders/verified",
        }),
        providesTags: [VERIFIED_SENDERS_TAG],
      }),
      createSender: build.mutation<SendersType, { email: string }>({
        query: ({ email }) => ({
          body: { email },
          method: "POST",
          url: "/settings/v1/senders",
        }),
        invalidatesTags: [SENDERS_TAG],
      }),
      deleteSender: build.mutation<SendersType, { id: string }>({
        query: ({ id }) => ({
          method: "DELETE",
          url: "/settings/v1/senders/" + id,
        }),
        invalidatesTags: [SENDERS_TAG],
      }),
    }),
  });

export const {
  useGetAccountSettingsQuery,
  useGetVerifiedSendersQuery,
  usePostAccountAddressMutation,
  useUpdateAccountAddressMutation,
  useGetUserInformationQuery,
  useGetUserExtraInformationQuery,
  useGetAccountAddressOptionsQuery,
  usePostAccountDetailsMutation,
  usePostUserInformationMutation,
  useGetAccountDetailsOptionsQuery,
  useUpdateAccountDetailsMutation,
  useGetUserInformationOptionsQuery,
  useGetSendersQuery,
  useCreateSenderMutation,
  useDeleteSenderMutation,
} = settingsApi;
