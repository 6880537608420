import { Toast } from "components";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface ToastContextProviderProps {
  children: React.ReactNode;
}

export type ToasterMode = "success" | "danger";

interface ShowToasterProps {
  text: string;
  mode?: ToasterMode;
}

export type ShowToasterType = ({ mode, text }: ShowToasterProps) => void;

interface ToastContextState {
  isVisible: boolean;
  text: string;
  onToastClose: () => void;
  showToaster: ShowToasterType;
  mode: ToasterMode;
}

const initialState: ToastContextState = {
  isVisible: false,
  text: "",
  onToastClose: () => null,
  showToaster: () => null,
  mode: "success",
};

export const ToastContext = createContext<ToastContextState>({
  ...initialState,
});

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error("ToastContext was used outside of its Provider");
  }

  return context;
};

export const ToastContextProvider = ({
  children,
}: ToastContextProviderProps) => {
  const [isVisible, setIsVisible] = useState(initialState.isVisible);
  const [text, setText] = useState(initialState.text);
  const [mode, setMode] = useState<ToasterMode>(initialState.mode);

  const onToastClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  const showToaster = useCallback(
    ({ text, mode = "success" }: ShowToasterProps) => {
      setText(text);
      setMode(mode);
      setIsVisible(true);
    },
    []
  );

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
  }, [isVisible]);

  return (
    <ToastContext.Provider
      value={{
        onToastClose,
        showToaster,
        isVisible,
        text,
        mode,
      }}
    >
      <>
        <Toast />
        {children}
      </>
    </ToastContext.Provider>
  );
};
