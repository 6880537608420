import { Dropdown, Input } from 'components/Forms';
import styled from 'styled-components';
import { useCampaignContext } from '../context/campaignContext';
import { useGetVerifiedSendersQuery } from 'modules/settings/api/settingsApi';
import { useUserInfo } from 'hooks/useUserInfo';
import { type SingleType, type Option } from 'components/Forms/Dropdown';
import { BaseToggle } from 'components/Toggle';
import { useCreateSegmentOptionsQuery } from '../api/campaignsApi';
import { useListTagsQuery } from 'modules/lists/api/listsApi';
import { Button } from 'components';
import { Icon } from 'assets/icons';

export const ConfigCampaign = () => {
	const {
		details,
		listsData: lists,
		onChange,
		onListChange,
		onFromEmailChange,
		hasSegment,
		onSegmentToggle,
		onSegmentChange,
		segments,
		onAddSegment,
		onRemoveSegment,
	} = useCampaignContext();
	const { data } = useGetVerifiedSendersQuery();
	const { data: Tags } = useListTagsQuery();
	const { userInfo } = useUserInfo();
	const { data: segmentOptions } = useCreateSegmentOptionsQuery();
	const options = segmentOptions?.actions.POST;
	if (!lists) return null;
	const { name, subject, from_name, lists: emailLists } = details;
	const dropdownOptions = lists.map((list) => ({
		value: list.id,
		label: `${list.name} (${list.total_email_subscribers} subscribers)`,
	}));

	const getVerifiedUsers = (): Option[] => {
		const verifiedUsers: Option[] = [];
		if (data) {
			for (const verified of data) {
				const obj = {
					label: verified.email,
					value: verified.email,
				};
				verifiedUsers.push(obj);
			}
		}
		if (userInfo) {
			verifiedUsers.push({
				label: userInfo.email,
				value: userInfo.email,
			});
		}
		return verifiedUsers;
	};

	const fieldChoices =
		options?.field.choices?.map((item) => ({
			label: item.display_name,
			value: item.value,
		})) || [];

	const logicChoices =
		options?.logic.choices?.map((item) => ({
			label: item.display_name,
			value: item.value,
		})) || [];

	const tagsChoices =
		Tags?.map((item) => ({
			label: item.name,
			value: item.id,
		})) || [];

	return (
		<Container>
			<Input
				label="Campaign name"
				name="name"
				value={name}
				onChange={onChange}
			/>
			<TopForm>
				<Input
					label="From name"
					name="from_name"
					value={from_name}
					onChange={onChange}
				/>
				<Dropdown
					label="From email"
					options={getVerifiedUsers()}
					onValueChange={(newValue) => {
						onFromEmailChange(newValue as SingleType);
					}}
					placeholder="From email"
				/>
			</TopForm>
			<Input
				label="Subject"
				name="subject"
				value={subject}
				onChange={onChange}
			/>
			<Dropdown
				label="Send to"
				options={dropdownOptions}
				onValueChange={(newValue) => {
					onListChange(newValue);
				}}
				value={dropdownOptions.filter((item) =>
					emailLists.includes(item.value)
				)}
				placeholder="Select a list"
				isMulti
			/>
			<BaseToggle
				isChecked={hasSegment}
				onChange={onSegmentToggle}
				leftLabel="All subscribers"
				label="New segment"
			/>
			{hasSegment && (
				<SegmentContainer>
					{segments.map((segment, index) => (
						<SegmentRow key={segment.field + index}>
							<Dropdown
								options={fieldChoices}
								onValueChange={(newValue) => {
									onSegmentChange('field', newValue?.value, index);
								}}
								value={fieldChoices.filter(
									(item) => item.value === segment.field
								)}
								label="Field"
							/>
							<Dropdown
								label="Logic"
								options={logicChoices.filter((item) =>
									segment.field === 'email'
										? ['contains', 'not_contains'].includes(item.value)
										: ['equal', 'not_equal'].includes(item.value)
								)}
								onValueChange={(newValue) => {
									onSegmentChange('logic', newValue?.value, index);
								}}
								value={logicChoices.filter(
									(item) => item.value === segment.logic
								)}
								isDisabled={!segment.field}
							/>
							{segment.field === 'tags' ? (
								<Dropdown
									label="Value"
									options={tagsChoices}
									onValueChange={(newValue) => {
										onSegmentChange('value', newValue?.value, index);
									}}
									value={tagsChoices.filter(
										(item) => item.value === segment.value
									)}
									isDisabled={!segment.logic}
								/>
							) : (
								<Input
									label="Value"
									onChange={(e) =>
										onSegmentChange('value', e.target.value, index)
									}
									value={segment.value}
									disabled={!segment.logic}
								/>
							)}
							<DeleteButton onClick={() => onRemoveSegment(index)}>
								<Icon name="delete" color="error" />
							</DeleteButton>
						</SegmentRow>
					))}
					<ButtonWrapper>
						<Button
							text="add segment"
							variant="tertiary"
							mode="light"
							onClick={onAddSegment}
						/>
					</ButtonWrapper>
				</SegmentContainer>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const TopForm = styled.div`
	display: flex;
	gap: 1rem;
`;

const SegmentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const SegmentRow = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const DeleteButton = styled.button`
	align-self: flex-end;
	padding: 0.5rem 0.2rem;
	cursor: pointer;
`;
