import { type SyntheticEvent, useEffect, useState } from "react";
import {
  useGetAccountSettingsQuery,
  usePostAccountDetailsMutation,
  useUpdateAccountDetailsMutation,
} from "../api/settingsApi";
import { type AccountDetails } from "../api/types";
import { useToastContext } from "context/toastContext";
import {
  isFetchBaseQueryError,
  type ErrorDataMessage,
} from "services/api/rtkQueryError";
import { type SingleType } from "components/Forms/Dropdown";

export const useDetailsAccount = () => {
  const { data } = useGetAccountSettingsQuery();
  const [
    postDetails,
    { isLoading: isPostLoading, isSuccess: isPostSuccess, error: errorPost },
  ] = usePostAccountDetailsMutation();
  const [
    updateDetails,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      error: errorUpdate,
    },
  ] = useUpdateAccountDetailsMutation();
  const { showToaster } = useToastContext();

  const [values, setValues] = useState<AccountDetails>({
    company_name: "",
    industry: "",
    website: "",
    referrer: "",
  });

  const [errors, setErrors] = useState<Record<string, string[]>>({});

  const saveAccountDetails = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (data?.details) {
      await updateDetails(values);
    } else {
      await postDetails(values);
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onDropdownChange = (key: string, newValue: SingleType) => {
    if (!newValue) return;
    setValues({
      ...values,
      [key]: newValue.value,
    });
  };
  useEffect(() => {
    if (isPostSuccess || isUpdateSuccess) {
      showToaster({
        text: "Account details saved successfully!",
      });
    }
  }, [isPostSuccess, isUpdateSuccess, showToaster]);

  useEffect(() => {
    if (data) {
      const { details } = data;
      if (details) {
        setValues({
          ...details,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (errorPost || errorUpdate) {
      if (isFetchBaseQueryError(errorPost) && errorPost?.data) {
        setErrors(errorPost.data as ErrorDataMessage);
      }

      if (isFetchBaseQueryError(errorUpdate) && errorUpdate?.data) {
        setErrors(errorUpdate?.data as ErrorDataMessage);
      }
    }
  }, [errorPost, errorUpdate]);

  return {
    accountDetails: values,
    onChange,
    saveAccountDetails,
    onDropdownChange,
    isLoading: isPostLoading || isUpdateLoading,
    errors,
  };
};
