import { Button } from "components";
import { CardContainer } from "components/CardContainer";
import { useCreateTagMutation, useListTagsQuery } from "../api/listsApi";
import { Spinner } from "components/Spinner";
import { EmptyState } from "components/EmptyState";
import styled from "styled-components";
import { useModalContext } from "context/modalContext";
import { Input } from "components/Forms";
import { P1Bold } from "theme/typography";
import { type SyntheticEvent, useState } from "react";
import { type ShowToasterType, useToastContext } from "context/toastContext";
import { TagCard } from "../components/TagCard";

export const Tags = () => {
  const { data: tags, isLoading: loadingTags } = useListTagsQuery();
  const { showModal } = useModalContext();
  const { showToaster } = useToastContext();

  if (loadingTags)
    return (
      <CardContainer center>
        <Spinner color="main" />
      </CardContainer>
    );

  const onCreateTagPress = () => {
    showModal(<CreateTag showToaster={showToaster} />);
  };

  return (
    <Wrapper>
      <StyledButton text="Create new tag" onClick={onCreateTagPress} />
      {tags?.length === 0 ? (
        <CardContainer>
          <EmptyState text={"You don't have tags created."} />
        </CardContainer>
      ) : (
        <TagsWrapper>
          {tags?.map((tag) => (
            <TagCard key={tag.id} tag={tag} />
          ))}
        </TagsWrapper>
      )}
    </Wrapper>
  );
};

const CreateTag = ({ showToaster }: { showToaster: ShowToasterType }) => {
  const [tag, setTag] = useState("");
  const [create, { isLoading }] = useCreateTagMutation();
  const { onModalClose } = useModalContext();

  const onCreatePress = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      await create({ name: tag }).unwrap();
      showToaster({ text: "New tag created." });
    } catch (err) {
      showToaster({ text: "Something went wrong", mode: "danger" });
    }
    onModalClose();
  };
  return (
    <CreateContainer>
      <P1Bold>Create new tag</P1Bold>
      <Input value={tag} onChange={(e) => { setTag(e.target.value); }} />
      <Button onClick={onCreatePress} text="Add tag" loading={isLoading} />
    </CreateContainer>
  );
};

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  padding-top: 1rem;
  overflow-y: scroll;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.8rem;
`;

const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 30rem;
`;
