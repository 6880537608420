export type Theme = typeof theme;
export type ColorKey = keyof typeof theme.colors;

export const theme = {
  colors: {
    main: "#FF4C29",
    main2: "#f8de22",
    primary: "#424347",
    secondary: "#2C394B",
    background: "#17181C",
    grey10: "#F7F7F8",
    error: "#E06469",
    warning: "#FFE17B",
    success: "#03C4A1",
    white: "#fff",
    black: "#000",
    inherit: "inherit",
  },
  dimensions: {
    radius: "0.6rem",
    maxWidth: "80rem",
  },
  text: {
    small: "0.8rem",
  },
  breakpoints: {
    L: "1200px",
    M: "1000px",
    S: "700px",
    XS: "500px",
  },
};
