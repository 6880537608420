import { Outlet, useParams } from "react-router-dom";
import { type SortFields, type SortType, useListDetails } from "../hooks/useListDetails";
import { type Contact, type ListEmailDetails } from "../api/types";
import { Header } from "components/Header";
import styled from "styled-components";
import { PressLink } from "components/Button";
import { FullScreenSpinner } from "components/Spinner";
import { TabHeaderNav } from "components/TabHeaderNav";

export interface ListContextType {
  detail: ListEmailDetails;
  listId: string;
  refetchList: () => Promise<void>;
  setSearch: (e: string) => void;
  search: string;
  subscribers: Contact[];
  onSortPress: (field: SortFields) => void;
  sort: SortType;
  changeFilter: (field: "status", bool?: boolean) => void;
  isSubscribed?: boolean;
  setTagsFilter: (value: string[]) => void;
  tagsFilter: string[];
}

export const ListDetailsLayout = () => {
  const { listId } = useParams();
  const {
    detail,
    subscribers,
    isLoading,
    error,
    search,
    setSearch,
    onSortPress,
    sort,
    changeFilter,
    isSubscribed,
    tagsFilter,
    setTagsFilter,
    refetch: refetchList,
  } = useListDetails(listId ?? "");

  if (!listId) return null;
  if (isLoading) return <FullScreenSpinner />;
  if (error) return <p>Something went wrong</p>;
  if (!detail) return <p>No data found</p>;
  if (!subscribers) return <p>No subscribers</p>;

  const tabOptions = [
    {
      to: "subscribers",
      text: "Subscribers",
    },
    {
      to: "import",
      text: "Import",
    },
    {
      to: "settings",
      text: "Settings",
    },
  ];

  return (
    <>
      <HeaderWrapper>
        <Header title={detail.name} />
        <PressLink
          to="subscribers/create"
          variant="secondary"
          text="Add subscriber"
        />
      </HeaderWrapper>
      <TabHeaderNav options={tabOptions} />
      <Outlet
        context={{
          detail,
          listId,
          refetchList,
          setSearch,
          search,
          subscribers,
          onSortPress,
          sort,
          changeFilter,
          isSubscribed,
          setTagsFilter,
          tagsFilter,
        }}
      />
    </>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
