import {
  useDeleteSenderMutation,
  useGetSendersQuery,
} from "../api/settingsApi";
import { CardContainer } from "components/CardContainer";
import { styled } from "styled-components";
import { PressLink } from "components/Button";
import { useOutlet } from "react-router-dom";
import { EmptyState } from "components/EmptyState";
import { FullScreenSpinner } from "components/Spinner";
import { useErrorMessage } from "hooks/useErrorMessage";
import { Icon } from "assets/icons";
import { type SendersType } from "../api/types";
import { DeleteModal } from "components/DeleteModal";
import { useModalContext } from "context/modalContext";

export const Senders = () => {
  const { data, isLoading, error: errorGetSenders } = useGetSendersQuery();
  const [deleteSender, { error: errorDeleteSender }] =
    useDeleteSenderMutation();
  useErrorMessage(errorGetSenders ?? errorDeleteSender);
  const outlet = useOutlet();
  const { showModal } = useModalContext();

  if (isLoading) return <FullScreenSpinner />;

  const onSenderDelete = async (id: string) => {
    await deleteSender({ id });
  };

  const onDelete = (sender: SendersType) => {
    showModal(
      <DeleteModal
        onDelete={async () => { await onSenderDelete(sender.id); }}
        item={sender.email}
      />
    );
  };

  return (
    <>
      <CardContainer>
        <Top>
          <p>This are the verified senders you can send your campaigns from.</p>
          <PressLink text="Add new sender" to="add" />
        </Top>

        {data?.length ? (
          <Table>
            <thead>
              <tr>
                <th>E-mail</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((sender) => (
                <tr key={sender.id}>
                  <td>{sender.email}</td>
                  <td>
                    <StatusWrapper isVerified={sender.is_verified}>
                      {sender.is_verified ? "Verified" : "Pending"}
                    </StatusWrapper>
                  </td>
                  <td>
                    <DeletePress onClick={() => { onDelete(sender); }}>
                      <Icon name="delete" color="error" />
                    </DeletePress>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyState text="You don't have senders" />
        )}
      </CardContainer>
      {outlet}
    </>
  );
};

const Table = styled.table`
  border-collapse: separate;
  table-layout: auto;
  border-spacing: 2px;
  border-spacing: 0.4em;
  width: 100%;

  & th,
  td {
    padding: 0.5rem 1rem;
    border: 1px solid black;
    border-radius: 10px;
  }

  & td:last-child {
    width: 1px;
  }

  & td {
    text-align: center;
  }
`;

const DeletePress = styled.button`
  background: none;
  cursor: pointer;
`;

const StatusWrapper = styled.div<{ isVerified: boolean }>`
  font-weight: bold;
  border-radius: ${({ theme }) => theme.dimensions.radius};
  text-align: center;
  padding: 0.3rem;
  color: ${({ theme, isVerified }) =>
    theme.colors[isVerified ? "success" : "warning"]};
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;
