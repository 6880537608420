import { Link, NavLink } from "react-router-dom";
import { PressLink } from "./Button";
import styled from "styled-components";
import { MainLogo } from "./Logo";
import { useCheckSessionToken } from "modules/onboarding/hooks/useCheckSessionToken";
import { Drawer } from "./Drawer";

export const NavBar = () => {
  const { isAuthenticated } = useCheckSessionToken();

  return (
    <MainNavWrapper>
      <Wrapper>
        <Container>
          <Logo to={"/"}>
            <MainLogo />
          </Logo>
          <NavWrapper>
            <LeftNav>
              <StyledNavLink to="/pricing">Pricing</StyledNavLink>
              <StyledNavLink to="/contact-us">Contact us</StyledNavLink>
            </LeftNav>
            <RightNav>
              {isAuthenticated ? (
                <PressLink to="/dashboard" text="Go to dashboard" />
              ) : (
                <>
                  <PressLink
                    to="/login"
                    text="Log in"
                    variant="secondary"
                    className="me-3"
                  />
                  <PressLink to="/register" text="Get started" />
                </>
              )}
            </RightNav>
          </NavWrapper>
        </Container>
      </Wrapper>
      <Drawer>
        <DrawerWrapper>
          <DrawerNavLink to="/pricing" className="me-3">
            Pricing
          </DrawerNavLink>
          <DrawerNavLink to="/contact-us" className="me-3">
            Contact us
          </DrawerNavLink>
          <BottomWrapper>
            <PressLink
              to="/login"
              text="Log in"
              variant="tertiary"
              isFullWidth
            />
            <PressLink
              to="/register"
              text="Get started"
              isFullWidth
              variant="tertiary"
            />
          </BottomWrapper>
        </DrawerWrapper>
      </Drawer>
    </MainNavWrapper>
  );
};

const LeftNav = styled.div`
  display: flex;
`;

const RightNav = styled.div`
  display: flex;
`;

const MainNavWrapper = styled.nav`
  padding: 1rem;
  padding-bottom: 0;
`;

const Wrapper = styled.div`
  max-width: ${({ theme }) => theme.dimensions.maxWidth};
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.M}) {
    display: none;
  }
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.main};
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  padding: 1rem 0;
  align-items: center;
`;

const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  padding: 1rem;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const DrawerNavLink = styled(NavLink)`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  &.active {
    color: ${({ theme }) => theme.colors.main};
  }
`;

const StyledNavLink = styled(NavLink)`
  margin-left: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }

  &.active {
    color: ${({ theme }) => theme.colors.main};
  }
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    display: none;
  }
`;
