import { useLogoutUserMutation } from "modules/onboarding/api/auth";
import { useAppDispatch } from "store";
import { setToken } from "modules/onboarding/data/authSlice";
import { api } from "services/api/api";

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const [logout] = useLogoutUserMutation();

  const logoutUser = async () => {
    try {
      await logout();
      dispatch(setToken(""));
      dispatch(api.util.resetApiState());
    } catch (err) {
      dispatch(setToken(""));
    }
  };

  return { logoutUser };
};
