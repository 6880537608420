import { type AnalyticsType } from "../api/types";
import { PressLink } from "components/Button";
import styled from "styled-components";
import { P1Bold, P2Bold, P2Regular, SmallText } from "theme/typography";
import { formattedDate } from "utils/formattedDate";

interface AnalyticsCardProps {
  analytics: AnalyticsType;
}

export const AnalyticsCard = ({ analytics }: AnalyticsCardProps) => {
  return (
    <Container>
      <Image src={analytics.campaign.template.image} />
      <Body>
        <P1Bold>{analytics.campaign.name}</P1Bold>
        <P2Regular>
          {formattedDate(new Date(analytics.campaign.created))}
        </P2Regular>
        <MetricWrapper>
          <Metrics>
            <P2Bold>{analytics.metrics.open_rate}%</P2Bold>
            <SmallText>Opened</SmallText>
          </Metrics>
          <Metrics>
            <P2Bold>{analytics.metrics.click_through_rate}%</P2Bold>
            <SmallText>Clicked</SmallText>
          </Metrics>
        </MetricWrapper>
        <PressLink to={`${analytics.id}`} text=" More details" />
      </Body>
    </Container>
  );
};

const Metrics = styled.div`
  text-align: center;
`;

const MetricWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.dimensions.radius};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 1rem;
  border-radius: ${({ theme }) => theme.dimensions.radius};
`;
