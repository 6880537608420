import { CardContainer } from 'components/CardContainer';
import { useGetUserInformationOptionsQuery } from '../api/settingsApi';
import { useUserAccount } from '../hooks/useUserAccount';
import { Input } from 'components/BaseInput';
import styled from 'styled-components';
import { Button } from 'components';
import { Dropdown } from 'components/Forms';
import { type Option, type SingleType } from 'components/Forms/Dropdown';

export const Profile = () => {
	const { data } = useGetUserInformationOptionsQuery();
	const { userInformation, onChange, saveUserInformation, onDropdownChange } =
		useUserAccount();

	const timezoneOptions: Option[] =
		data?.actions.PUT?.timezone?.choices?.map((item) => ({
			label: item.display_name,
			value: item.value,
		})) || [];

	return (
		<CardContainer>
			<StyledForm onSubmit={saveUserInformation}>
				<Input
					label="Email"
					value={userInformation.email}
					name="email"
					disabled
					onChange={onChange}
				/>
				<NameWrapper>
					<Input
						label="First name"
						value={userInformation.first_name}
						name="first_name"
						onChange={onChange}
					/>
					<Input
						label="Last name"
						value={userInformation.last_name}
						name="last_name"
						onChange={onChange}
					/>
				</NameWrapper>
				<Dropdown
					options={timezoneOptions}
					label="Timezone"
					placeholder="Timezone"
					onValueChange={(newValue) =>
						onDropdownChange('timezone', newValue as SingleType)
					}
					value={timezoneOptions.filter(
						(item) => item.value === userInformation.timezone
					)}
				/>
				<StyledButton text="Save" onClick={saveUserInformation} type="submit" />
			</StyledForm>
		</CardContainer>
	);
};

const StyledForm = styled.div`
	display: flex;
	flex-direction: column;
`;

const NameWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const StyledButton = styled(Button)`
	margin-top: 2rem;
`;
