import styled from "styled-components";
import { type Tag } from "../api/types";
import { Icon } from "assets/icons";
import { useModalContext } from "context/modalContext";
import { DeleteModal } from "components/DeleteModal";
import { useDeleteTagMutation, useUpdateTagMutation } from "../api/listsApi";
import { useToastContext } from "context/toastContext";
import { Input } from "components/Forms";
import { useState } from "react";
import { Button } from "components";
import { SmallText } from "theme/typography";

interface TagCardProps {
  tag: Tag;
}

export const TagCard = ({ tag }: TagCardProps) => {
  const { showModal, onModalClose } = useModalContext();
  const [deleteTag] = useDeleteTagMutation();
  const [updateTag] = useUpdateTagMutation();
  const { showToaster } = useToastContext();

  const deleteCurrentTag = async () => {
    try {
      await deleteTag({ id: tag.id }).unwrap();
      showToaster({ text: "Tag deleted successfully" });
    } catch (err) {
      showToaster({
        text: "Couldn't delete tag. Please try again later.",
        mode: "danger",
      });
    }
  };

  const onUpdateTag = async (name: string) => {
    try {
      await updateTag({ id: tag.id, name }).unwrap();
      showToaster({ text: "Tag updated successfully" });
    } catch (err) {
      showToaster({
        text: "Couldn't update tag. Please try again later.",
        mode: "danger",
      });
    }
    onModalClose();
  };
  const onDelete = () => {
    showModal(<DeleteModal item={tag.name} onDelete={deleteCurrentTag} />);
  };

  const onUpdate = () => {
    showModal(<UpdateModal name={tag.name} onUpdateTag={onUpdateTag} />);
  };

  return (
    <Container>
      <TagWrapper>
        <p>{tag.name}</p>
      </TagWrapper>
      <IconsWrapper>
        <Small>{tag.number_of_subscribers} subscriber</Small>
        <IconPress onClick={onUpdate}>
          <Icon name="edit" size={20} />
        </IconPress>
        <IconPress onClick={onDelete}>
          <Icon name="delete" color="error" size={20} />
        </IconPress>
      </IconsWrapper>
    </Container>
  );
};

const UpdateModal = ({
  name,
  onUpdateTag,
}: {
  name: string;
  onUpdateTag: (name: string) => Promise<void>;
}) => {
  const [tagName, setTagName] = useState(name);
  return (
    <UpdateContainer>
      <Input value={tagName} onChange={(e) => { setTagName(e.target.value); }} />
      <Button
        text="Update"
        disabled={tagName === name}
        onClick={async () => { await onUpdateTag(tagName); }}
      />
    </UpdateContainer>
  );
};

const Small = styled(SmallText)`
  margin-right: 2rem;
`;

const UpdateContainer = styled.div`
  min-width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const IconPress = styled.button`
  cursor: pointer;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Container = styled.div`
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
`;

const TagWrapper = styled.div`
  padding: 0.2rem 0.5rem;
  background: ${({ theme }) => theme.colors.secondary};
  color: white;
  border-radius: 0.5rem;
  width: auto;
`;
