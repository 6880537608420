import Select, { type ActionMeta, type MultiValue } from "react-select";
import styled, { css, useTheme } from "styled-components";

interface FilterOption {
  key: string;
  text: string;
}

interface FilterProps {
  options: FilterOption[];
  selected: string;
  onClick: (field: string) => void;
}

export const Filter = ({ options, selected, onClick }: FilterProps) => {
  return (
    <Container>
      {options.map((option, index) => (
        <Option
          key={option.key}
          onClick={() => { onClick(option.key); }}
          isFirst={index === 0}
          isLast={index + 1 === options.length}
          isSelected={selected === option.key}
        >
          {option.text}
        </Option>
      ))}
    </Container>
  );
};

interface FilterMultipleOptions {
  label: string;
  value: string;
}

export type OnFilterMultipleChange = (
  newValue: MultiValue<FilterMultipleOptions>,
  actionMeta: ActionMeta<FilterMultipleOptions>
) => void;

interface FilterMultipleProps {
  options: FilterMultipleOptions[];
  onChange: OnFilterMultipleChange;
}

export const FilterMultiple = ({ options, onChange }: FilterMultipleProps) => {
  const theme = useTheme();
  return (
    <Container>
      <Select
        isMulti
        options={options}
        onChange={onChange}
        styles={{
          control: (baseStyles, { isFocused, menuIsOpen }) => ({
            ...baseStyles,
            borderRadius: theme?.dimensions.radius,
            borderColor: isFocused ? theme?.colors.main : theme?.colors.primary,
            boxShadow: "none",
            fontSize: theme?.text.small,
            fontFamily: "Poppins",
            "&:hover": {
              borderColor: isFocused
                ? theme?.colors.main
                : theme?.colors.primary,
            },
          }),
          option: (baseStyles, { isSelected, isFocused }) => ({
            ...baseStyles,
            fontFamily: "inherit",
            fontSize: theme?.text.small,
            background:
              isSelected || isFocused ? theme?.colors.grey10 : "transparent",
            color: theme?.colors.primary,
            "&:hover": {
              background: theme?.colors.grey10,
            },
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
  border-radius: ${({ theme }) => theme.dimensions.radius};
`;

const Option = styled.button<{
  isSelected: boolean;
  isFirst: boolean;
  isLast: boolean;
}>`
  cursor: pointer;
  transition: 0.3s;
  padding: 0.5rem 1rem;
  background: ${({ theme, isSelected }) =>
    theme.colors[isSelected ? "main" : "white"]};
  color: ${({ theme, isSelected }) =>
    theme.colors[isSelected ? "white" : "primary"]};
  ${({ isFirst, theme }) =>
    isFirst &&
    css`
      border-top-left-radius: ${theme.dimensions.radius};
      border-bottom-left-radius: ${theme.dimensions.radius};
    `}
  ${({ isLast, theme }) =>
    isLast &&
    css`
      border-top-right-radius: ${theme.dimensions.radius};
      border-bottom-right-radius: ${theme.dimensions.radius};
    `};
  transition: 0.3s;
  &:hover {
    background: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.background};
  z-index: 1;
`;
