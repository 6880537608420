import { HeaderLayout } from "components/HeaderLayout";
import { useGetFormsQuery } from "../api/formsApi";
import { CardContainer } from "components/CardContainer";
import { FullScreenSpinner } from "components/Spinner";
import { FormRow } from "../components/formRow";
import styled from "styled-components";
import { EmptyState } from "components/EmptyState";

export const Forms = () => {
  const { data, isLoading } = useGetFormsQuery();

  if (isLoading) return <FullScreenSpinner />;
  return (
    <HeaderLayout
      title="Forms"
      rightButton={{ text: "Create new form", icon: "add", to: "create" }}
    >
      <Wrapper>
        {data?.length ? (
          data?.map((item) => <FormRow key={item.id} form={item} />)
        ) : (
          <CardContainer>
            <EmptyState
              text="You don't have forms"
              link={{ to: "create", text: "Create new form" }}
            />
          </CardContainer>
        )}
      </Wrapper>
    </HeaderLayout>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
