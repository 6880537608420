import { api } from "services/api/api";
import {
  type AddContact,
  type Contact,
  type CreateContact,
  type ListCreationArgs,
  type ListDetails,
  type ListEmailDetails,
  type Tag,
  type UpdateList,
} from "./types";
import { type FormOptions } from "types/FormOptions";
import { USER_INFO_TAG } from "modules/settings/api/settingsApi";

export const LIST_TAG = "Lists";
export const LIST_DETAIL_TAG = "ListDetail";
export const SUBSCRIBERS_TAG = "Subscribers";
export const CONTACT_LIST_TAG = "ContactsList";
export const CONTACT_DETAIL_TAG = "ContactDetail";
export const LIST_TAGS = "ListTags";

export const listsApi = api
  .enhanceEndpoints({
    addTagTypes: [
      LIST_TAG,
      LIST_DETAIL_TAG,
      CONTACT_DETAIL_TAG,
      CONTACT_LIST_TAG,
      LIST_TAGS,
      USER_INFO_TAG,
      SUBSCRIBERS_TAG,
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getLists: build.query<ListDetails[], { search?: string }>({
        query: ({ search }) => ({
          method: "GET",
          url: "/lists/v1/",
          params: { search },
        }),
        providesTags: [LIST_TAG],
      }),
      getSubscribers: build.query<
        Contact[],
        {
          id: string;
          search?: string;
          ordering?: string;
          is_subscribed?: boolean;
          tags?: string[];
        }
      >({
        query: ({ id, search, ordering, is_subscribed, tags }) => ({
          method: "GET",
          url: "/lists/v1/contacts",
          params: { list: id, search, ordering, is_subscribed, tags },
        }),
        providesTags: [SUBSCRIBERS_TAG],
      }),
      updateContact: build.mutation<
        Contact,
        { contactId: string; contact: CreateContact }
      >({
        query: (args) => ({
          body: args.contact,
          method: "PATCH",
          url: `lists/v1/contacts/${args.contactId}`,
        }),
        invalidatesTags: [
          LIST_DETAIL_TAG,
          CONTACT_DETAIL_TAG,
          SUBSCRIBERS_TAG,
          LIST_TAGS,
        ],
      }),
      getListDetail: build.query<ListEmailDetails, { id: string }>({
        query: ({ id }) => ({
          method: "GET",
          url: "/lists/v1/" + id,
        }),
        providesTags: [LIST_DETAIL_TAG],
      }),
      createList: build.mutation<ListDetails, ListCreationArgs>({
        query: (args) => ({
          body: args,
          method: "POST",
          url: "/lists/v1/",
        }),
        invalidatesTags: [LIST_TAG],
      }),
      createListOptions: build.query<FormOptions, void>({
        query: () => ({
          method: "OPTIONS",
          url: "/lists/v1/",
        }),
      }),
      deleteList: build.mutation<void, { id: string }>({
        query: ({ id }) => ({
          method: "DELETE",
          url: "/lists/v1/" + id,
        }),
        invalidatesTags: [LIST_TAG],
      }),
      updateList: build.mutation<ListDetails, UpdateList>({
        query: ({ id, ...args }) => ({
          body: { ...args },
          method: "PATCH",
          url: "/lists/v1/" + id,
        }),
        invalidatesTags: [LIST_TAG, LIST_DETAIL_TAG],
      }),
      addContact: build.mutation<Contact, AddContact>({
        query: ({ id, ...args }) => ({
          body: args,
          method: "POST",
          url: `lists/v1/${id}/add_email`,
        }),
        invalidatesTags: [
          LIST_TAG,
          LIST_DETAIL_TAG,
          USER_INFO_TAG,
          SUBSCRIBERS_TAG,
        ],
      }),
      addContactOptions: build.query<FormOptions, { id: string }>({
        query: ({ id }) => ({
          method: "OPTIONS",
          url: `lists/v1/${id}/add_email`,
        }),
      }),
      getAllContacts: build.query<Contact[], void>({
        query: () => ({
          method: "GET",
          url: `lists/v1/contacts/`,
        }),
        providesTags: [CONTACT_LIST_TAG],
      }),
      getContact: build.query<Contact, { contactId: string }>({
        query: ({ contactId }) => ({
          method: "GET",
          url: `lists/v1/contacts/${contactId}`,
        }),
        providesTags: [CONTACT_DETAIL_TAG],
      }),
      deleteContactById: build.mutation<Contact, { contactId: string }>({
        query: ({ contactId }) => ({
          method: "DELETE",
          url: `lists/v1/contacts/${contactId}`,
        }),
        invalidatesTags: [
          LIST_TAG,
          CONTACT_LIST_TAG,
          LIST_DETAIL_TAG,
          USER_INFO_TAG,
          SUBSCRIBERS_TAG,
        ],
      }),
      listTags: build.query<Tag[], void>({
        query: () => ({
          method: "GET",
          url: `lists/v1/tags`,
        }),
        providesTags: [LIST_TAGS],
      }),
      createTag: build.mutation<Tag, { name: string }>({
        query: ({ name }) => ({
          body: { name },
          method: "POST",
          url: `lists/v1/tags`,
        }),
        invalidatesTags: [LIST_TAGS],
      }),
      deleteTag: build.mutation<void, { id: string }>({
        query: ({ id }) => ({
          method: "DELETE",
          url: `lists/v1/tags/${id}`,
        }),
        invalidatesTags: [LIST_TAGS],
      }),
      updateTag: build.mutation<void, { id: string; name: string }>({
        query: ({ id, name }) => ({
          body: { name },
          method: "PATCH",
          url: `lists/v1/tags/${id}`,
        }),
        invalidatesTags: [LIST_TAGS],
      }),
    }),
  });

export const {
  useCreateListMutation,
  useCreateListOptionsQuery,
  useGetListsQuery,
  useGetSubscribersQuery,
  useGetListDetailQuery,
  useDeleteListMutation,
  useUpdateListMutation,
  useAddContactMutation,
  useGetContactQuery,
  useUpdateContactMutation,
  useAddContactOptionsQuery,
  useListTagsQuery,
  useDeleteTagMutation,
  useUpdateTagMutation,
  useCreateTagMutation,
  useGetAllContactsQuery,
  useDeleteContactByIdMutation,
} = listsApi;
