import { type SyntheticEvent, useEffect, useState } from 'react';
import { useAddContactMutation } from '../api/listsApi';
import { useLocation, useNavigate } from 'react-router-dom';

import { type OnDropDownChange } from 'components/Form';
import { useToastContext } from 'context/toastContext';
import { isFetchBaseQueryError } from 'services/api/rtkQueryError';

export const useCreateContact = (listId: string) => {
	const { showToaster } = useToastContext();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [values, setValues] = useState({
		email: '',
		first_name: '',
		last_name: '',
		tags: [],
		is_subscribed: true,
	});
	const [addContact, { isLoading, error, isSuccess }] = useAddContactMutation();

	const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValues({
			...values,
			[e.target.name]: e.target.value,
		});
	};

	const onIsSubscribedPress = () => {
		setValues({
			...values,
			is_subscribed: !values.is_subscribed,
		});
	};

	const onDropdownChange: OnDropDownChange = (key, newValue) => {
		// type check for isMulti
		if (Array.isArray(newValue)) {
			setValues({
				...values,
				[key]: newValue.map((item) => item.value),
			});
		}
	};

	const addNewContact = async (e: SyntheticEvent) => {
		e.preventDefault();
		await addContact({ id: listId, ...values });
	};

	useEffect(() => {
		if (isSuccess) {
			showToaster({ text: 'New contact added.' });
			navigate('/lists/' + listId + '/subscribers');
		}
	}, [isSuccess, navigate, pathname]);

	useEffect(() => {
		if (error) {
			if (isFetchBaseQueryError(error) && error?.data) {
				const errors = Object.values(error.data) as string[];
				for (const err of errors) {
					showToaster({ text: err, mode: 'danger' });
				}
			} else {
				showToaster({ text: 'Something went wrong', mode: 'danger' });
			}
		}
	}, [error]);

	return {
		addNewContact,
		isLoading,
		onValueChange,
		values,
		onDropdownChange,
		onIsSubscribedPress,
	};
};
