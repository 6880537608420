import { Provider } from "react-redux";
import store from "store";
import App from "App";
import { ThemeProvider } from "styled-components";
import { theme } from "theme";

function Root() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  );
}

export default Root;
