import dashboard from "assets/images/dashboard.png";
import templates from "assets/images/templates.png";
import lists from "assets/images/lists.png";
import schedule from "assets/images/schedule-campaign.png";
import responsive from "assets/images/responsive.png";
import personalizedMessages from "assets/images/personalized-messages.png";
import campaigns from "assets/images/campaigns.png";
import metrics from "assets/images/metrics.png";

export const landingSections = [
  {
    image: templates,
    title: "Effortless email campaign creation",
    description:
      "Create stunning and personalized email marketing campaigns effortlessly with our intuitive drag-and-drop email builder and a library of customizable email templates. No design or coding skills required.",
  },
  {
    image: lists,
    title: "Precise audience targeting",
    description:
      "Reach the right customers at the right time. Utilize our advanced list-building tools to segment your audience based on demographics, behavior, and preferences. Send emails that resonate with each recipient for higher engagement",
  },
  {
    image: schedule,
    title: "Automated email scheduling",
    description:
      "Take the stress out of managing campaign timing. Schedule your emails in advance, ensuring they land in your audience's inbox at the perfect moment for maximum impact. Never miss an opportunity again.",
  },
  {
    image: metrics,
    title: "Data-Driven insights",
    description:
      "Gain deep insights into your email campaign performance with our comprehensive analytics. Track open rates, click-through rates, conversion rates, and more. Use data to refine your strategies and drive better results.",
  },
  {
    image: dashboard,
    title: "Actionable reporting",
    description:
      "Our reporting dashboard provides clear, actionable insights into your email marketing ROI. Monitor your campaign's success in real-time and make data-driven decisions to optimize your marketing efforts.",
  },
  {
    image: personalizedMessages,
    title: "Personalization at scale with AI",
    description:
      "Delight your customers with personalized content and product recommendations. Our software enables you to craft tailored messages for each subscriber, enhancing engagement and conversion rates.",
  },
  {
    image: responsive,
    title: "Responsive email templates",
    description:
      "Ensure your emails look great on any device. Our responsive email templates automatically adapt to desktop, mobile, and tablet screens, providing a seamless experience for your audience.",
  },
  {
    image: campaigns,
    title: "User-friendly interface",
    description:
      "Our software is designed with simplicity in mind. Whether you're a seasoned marketer or a beginner, you'll find our user-friendly interface easy to navigate, making email marketing a breeze.",
  },
  // {
  //   image: dashboard,
  //   title: "Scalable solutions",
  //   description:
  //     "Grow your business without worry. Our software scales with you, accommodating your expanding email marketing needs as your business flourishes.",
  // },
  // {
  //   image: dashboard,
  //   title: "Dedicated support",
  //   description:
  //     "We're here to support your success. Our dedicated customer support team is ready to assist you with any questions or issues you may encounter along your email marketing journey.",
  // },
  // {
  //   image: dashboard,
  //   title: "Join the ranks of successful marketers",
  //   description:
  //     "Join a community of thriving businesses that have achieved remarkable results with our email marketing automation software. Discover how our platform can help you reach new heights in your marketing campaigns.",
  // },
];
