import { useEffect, useState } from "react";

export const useSearchValue = ({ search }: { search: string }) => {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchValue(search);
    }, 500);
    return () => { clearTimeout(delayDebounceFn); };
  }, [search]);

  return { searchValue };
};
