import { useGetTemplatesQuery } from '../api/templatesApi';

import { styled } from 'styled-components';
import { HeaderLayout } from 'components/HeaderLayout';
import { TemplateCard } from '../components/TemplateCard';
import { Spinner } from 'components/Spinner';
import { EmptyState } from 'components/EmptyState';
import { CardContainer } from 'components/CardContainer';

export const Templates = () => {
	const { data, isLoading } = useGetTemplatesQuery();

	if (isLoading) return <Spinner />;
	if (!data) return <p>No data found</p>;
	return (
		<HeaderLayout
			title="Templates"
			rightButton={{ to: 'create', text: 'Create new template', icon: 'add' }}
		>
			{data.length > 0 ? (
				<CardsContainer>
					{data.map((template) => (
						<TemplateCard key={template.id} template={template} />
					))}
				</CardsContainer>
			) : (
				<CardContainer center>
					<EmptyState
						text={'No templates yet'}
						link={{ to: 'create', text: 'Create new template' }}
					/>
				</CardContainer>
			)}
		</HeaderLayout>
	);
};

const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1rem;

	@media (max-width: ${({ theme }) => theme.breakpoints.S}) {
		grid-template-columns: 1fr 1fr;
	}
	@media (max-width: ${({ theme }) => theme.breakpoints.XS}) {
		grid-template-columns: 1fr;
	}
`;
