import { useGetAnalyticsQuery } from "../api/analyticsApi";
import { AnalyticsCard } from "../components/analyticsCard";
import { HeaderLayout } from "components/HeaderLayout";
import { CardContainer } from "components/CardContainer";
import { EmptyState } from "components/EmptyState";
import styled from "styled-components";

export const Analytics = () => {
  const { data } = useGetAnalyticsQuery();

  if (!data) return <p>No data found</p>;

  if (data.length === 0) {
    return (
      <HeaderLayout title="Campaigns">
        <CardContainer center>
          <EmptyState
            text="No analytics yet"
            link={{ to: "/campaigns/create", text: "Create new campaign" }}
          />
        </CardContainer>
      </HeaderLayout>
    );
  }
  return (
    <HeaderLayout title="Analytics">
      <CardsContainer>
        {data.map((analytics) => (
          <AnalyticsCard key={analytics.id} analytics={analytics} />
        ))}
      </CardsContainer>
    </HeaderLayout>
  );
};

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    grid-template-columns: 1fr;
  }
`;
