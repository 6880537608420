const {
  REACT_APP_API_ENDPOINT_DEV,
  REACT_APP_API_ENDPOINT,
  REACT_APP_PRODUCTION,
} = process.env;

const isDevEnv = !Number(REACT_APP_PRODUCTION);

export const config = {
  appName: "TargetEmailPro",
  isDevEnv,
  apiEndpoint: isDevEnv ? REACT_APP_API_ENDPOINT_DEV : REACT_APP_API_ENDPOINT,
  supportEmail: "support@targetemailpro.com",
  awsUrl: "https://mail-app-images.s3.eu-west-2.amazonaws.com/js/form.js",
};
