import { Link } from "react-router-dom";
import { useGetAccountSettingsQuery } from "../api/settingsApi";
import { styled } from "styled-components";

export const MissingInfoAlert = () => {
  const { data, isLoading } = useGetAccountSettingsQuery();
  if (isLoading || (data?.details && data?.address)) return null;


  return (
    <Container>
      <p>
        Add your{" "}
        {!data?.details && (
          <StyledLink to="/account/details">details</StyledLink>
        )}{" "}
        {!data?.details && !data?.address && "and"}{" "}
        {!data?.address && (
          <StyledLink to="/account/address">address</StyledLink>
        )}{" "}
        info to start your first campaign!
      </p>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.warning};
  border-radius: ${({ theme }) => theme.dimensions.radius};
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
`;

const StyledLink = styled(Link)`
  color: black;
  font-weight: bold;
`;
