import { useState } from "react";
import { useCreateList } from "../hooks/useCreateList";
import { useCreateListOptionsQuery } from "../api/listsApi";
import { BaseForm } from "components";
import { CardContainer } from "components/CardContainer";
import { HeaderLayout } from "components/HeaderLayout";
import { useNavigate } from "react-router-dom";

export const CreateList = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    description: "",
  });
  const { onCreateList, isLoading } = useCreateList(values);
  const { data } = useCreateListOptionsQuery();

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onGoBack = () => {
    navigate("/lists");
  };

  return (
    <HeaderLayout title="New list" onGoBack={onGoBack}>
      <CardContainer>
        <BaseForm
          data={data}
          isLoading={isLoading}
          onSubmit={onCreateList}
          values={values}
          onChange={onValueChange}
          submitText="Create"
        />
      </CardContainer>
    </HeaderLayout>
  );
};
