import { combineReducers } from "@reduxjs/toolkit";
import { api } from "services/api/api";
import authReducer from "modules/onboarding/data/authSlice";
import listsReducer from "modules/lists/data/listsSlice";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  lists: listsReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
