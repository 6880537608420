import React, { useEffect , useState } from "react";
import styled from "styled-components";
import { MainLogo } from "./Logo";
import { Icon } from "assets/icons";
import { Link, useLocation } from "react-router-dom";

interface DrawerProps {
  children: React.ReactNode;
}

export const Drawer = ({ children }: DrawerProps) => {
  const location = useLocation();
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setIsOpened(false);
  }, [location]);

  return (
    <>
      <ButtonWrapper>
        <MainLink to="/">
          <MainLogo />
        </MainLink>
        <Button onClick={() => { setIsOpened(true); }}>
          <Icon name="menu" color="white" size={30} />
        </Button>
      </ButtonWrapper>
      <DrawerContainer isOpened={isOpened}>
        <CloseWrapper onClick={() => { setIsOpened(false); }}>
          <Icon name="cross" color="white" size={30} />
        </CloseWrapper>
        {children}
      </DrawerContainer>
    </>
  );
};

const MainLink = styled(Link)``;

const Button = styled.button`
  border: none;
  padding: 0.5rem;
`;

const CloseWrapper = styled.button`
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  padding: 0.5rem;
  border: none;
  background: none;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.background};
  justify-content: space-between;
  margin-bottom: 1rem;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.M}) {
    display: flex;
    align-items: center;
  }
`;

const DrawerContainer = styled.div<{ isOpened: boolean }>`
  padding-top: 2rem;
  display: none;
  transform: translateX(${({ isOpened }) => (isOpened ? "0" : "-100%")});

  @media (max-width: ${({ theme }) => theme.breakpoints.M}) {
    background-color: ${({ theme }) => theme.colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.2s;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
  }
`;
