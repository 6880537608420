import { api } from 'services/api/api';
import {
	type SegmentType,
	type CampaignArgs,
	type CampaignType,
} from './types';
import { type FormOptions } from 'types/FormOptions';

export const CAMPAIGN_TAG = 'Campaign';
export const CAMPAIGN_DETAIL_TAG = 'CampaignDetails';

export const campaignsApi = api
	.enhanceEndpoints({ addTagTypes: [CAMPAIGN_TAG, CAMPAIGN_DETAIL_TAG] })
	.injectEndpoints({
		endpoints: (build) => ({
			getCampaigns: build.query<
				CampaignType[],
				{
					search?: string;
					draft?: boolean;
					scheduled?: boolean;
					sent?: boolean;
				}
			>({
				query: ({ search, draft, scheduled, sent }) => ({
					method: 'GET',
					url: '/campaigns/v1/',
					params: { search, draft, scheduled, sent },
				}),
				providesTags: [CAMPAIGN_TAG],
			}),
			getCampaignDetails: build.query<CampaignType, { campaignId: string }>({
				query: ({ campaignId }) => ({
					method: 'GET',
					url: '/campaigns/v1/' + campaignId,
				}),
				providesTags: [CAMPAIGN_DETAIL_TAG],
			}),
			deleteCampaign: build.mutation<CampaignType, { campaignId: string }>({
				query: ({ campaignId }) => ({
					method: 'DELETE',
					url: '/campaigns/v1/' + campaignId,
				}),
				invalidatesTags: [CAMPAIGN_TAG],
			}),
			createCampaign: build.mutation<CampaignType, CampaignArgs>({
				query: (args) => ({
					body: args,
					method: 'POST',
					url: '/campaigns/v1/',
				}),
				invalidatesTags: [CAMPAIGN_TAG],
			}),
			getCampaignOptions: build.query<FormOptions, void>({
				query: () => ({
					method: 'OPTIONS',
					url: '/campaigns/v1/',
				}),
			}),
			updateCampaign: build.mutation<
				CampaignType,
				{ id: string & Partial<CampaignArgs> }
			>({
				query: ({ id, ...args }) => ({
					body: args,
					method: 'PATCH',
					url: '/campaigns/v1/' + id,
				}),
				invalidatesTags: [CAMPAIGN_TAG, CAMPAIGN_DETAIL_TAG],
			}),
			createSegment: build.mutation<
				SegmentType,
				Array<Omit<SegmentType, 'id'>>
			>({
				query: (args) => ({
					body: args,
					method: 'POST',
					url: '/campaigns/v1/segments',
				}),
			}),
			updateSegment: build.mutation<SegmentType, SegmentType>({
				query: (args) => ({
					body: args,
					method: 'PATCH',
					url: '/campaigns/v1/segments/' + args.id,
				}),
			}),
			deleteSegment: build.mutation<SegmentType, { id: number }>({
				query: (args) => ({
					method: 'DELETE',
					url: '/campaigns/v1/segments/' + args.id,
				}),
			}),
			createSegmentOptions: build.query<FormOptions, void>({
				query: () => ({
					method: 'OPTIONS',
					url: '/campaigns/v1/segments',
				}),
			}),
		}),
	});

export const {
	useGetCampaignsQuery,
	useCreateCampaignMutation,
	useCreateSegmentOptionsQuery,
	useCreateSegmentMutation,
	useUpdateSegmentMutation,
	useDeleteSegmentMutation,
	useGetCampaignOptionsQuery,
	useGetCampaignDetailsQuery,
	useLazyGetCampaignDetailsQuery,
	useDeleteCampaignMutation,
	useUpdateCampaignMutation,
} = campaignsApi;
