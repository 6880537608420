import { Input } from "components/Forms";
import { AuthLayout } from "../components/AuthLayout";
import { useResetPasswordConfirmMutation } from "../api/auth";
import { type SyntheticEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "components/Button";
import { useToastContext } from "context/toastContext";
import { useNavigate, useParams } from "react-router-dom";
import { isFetchBaseQueryError } from "services/api/rtkQueryError";

export const PasswordReset = () => {
  const { uid, token } = useParams();

  const [setNewPassword, { isLoading, isSuccess, error }] =
    useResetPasswordConfirmMutation();

  const navigate = useNavigate();
  const [password, setPassword] = useState({
    new_password1: "",
    new_password2: "",
  });
  const { showToaster } = useToastContext();

  const onResetPassword = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!uid || !token) return;
    await setNewPassword({ ...password, uid, token });
  };

  useEffect(() => {
    if (isSuccess) {
      showToaster({ text: "Your password was successfully changed" });
      navigate("/login");
    }
  }, [isSuccess]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (isFetchBaseQueryError(error) && error?.data) {
      const messages: string[][] = Object.values(error.data);

      const arrMessage = [];

      for (const message of messages) {
        arrMessage.push(message.join(", "));
      }
      showToaster({ text: arrMessage.join(", "), mode: "danger" });
    }
  }, [error]);

  return (
    <AuthLayout title="Reset password" onSubmit={onResetPassword}>
      <Input
        type="password"
        label="New password"
        name="new_password1"
        placeholder="New password"
        value={password.new_password1}
        onChange={onChange}
      />
      <Input
        type="password"
        label="Repeat new password"
        name="new_password2"
        placeholder="New password"
        value={password.new_password2}
        onChange={onChange}
      />
      <StyledButton
        variant="primary"
        disabled={isLoading}
        text="Reset"
        isFullWidth
        loading={isLoading}
      />
    </AuthLayout>
  );
};

const StyledButton = styled(Button)`
  margin-top: 2rem;
`;
