import { api } from "services/api/api";

interface UserLoginData {
  email: string;
  password: string;
}

export interface UserRegisterData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

interface LoginResult {
  access: string;
  refresh: string;
  user: {
    pk: string;
    email: string;
    first_name: string;
    last_name: string;
  };
  access_expiration: Date;
  refresh_expiration: Date;
}

interface TokenVerify {
  token: string;
}
interface TokenRefresh {
  refresh: string;
}

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    registerUser: build.mutation<undefined, UserRegisterData>({
      query: (args) => ({
        body: args,
        method: "POST",
        url: "/auth/register/",
      }),
    }),
    loginUser: build.mutation<LoginResult, UserLoginData>({
      query: (args) => ({
        body: args,
        method: "POST",
        url: "/auth/login/",
      }),
    }),
    logoutUser: build.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: "/auth/logout/",
      }),
    }),
    resetPassword: build.mutation<void, { email: string }>({
      query: ({ email }) => ({
        body: { email },
        method: "POST",
        url: "/auth/password/reset/",
      }),
    }),
    changePassword: build.mutation<
      void,
      { new_password1: string; new_password2: string; old_password: string }
    >({
      query: (args) => ({
        body: args,
        method: "POST",
        url: "/auth/password/change/",
      }),
    }),
    resetPasswordConfirm: build.mutation<
      void,
      {
        new_password1: string;
        new_password2: string;
        uid: string;
        token: string;
      }
    >({
      query: (args) => ({
        body: args,
        method: "POST",
        url: "/auth/password/reset/confirm/",
      }),
    }),
    verifyToken: build.mutation<{ status: number }, TokenVerify>({
      query: (args) => ({
        body: args,
        method: "POST",
        url: "/auth/token/verify/",
      }),
    }),
    refreshToken: build.mutation<boolean, TokenRefresh>({
      query: (args) => ({
        body: args,
        method: "POST",
        url: "/auth/token/refresh/",
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useVerifyTokenMutation,
  useRefreshTokenMutation,
  useLogoutUserMutation,
  useResetPasswordMutation,
  useResetPasswordConfirmMutation,
  useChangePasswordMutation,
} = authApi;
