import { Chart as ChartJS, ArcElement, } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";
import { P1Bold, SmallText } from "theme/typography";

ChartJS.register(ArcElement);

interface DoughnutChartProps {
  value: number;
  title: string;
  count?: number;
}

export const DoughnutChart = ({ value, title, count }: DoughnutChartProps) => {
  const data = {
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: ["rgba(255, 76, 41,0.2)", "rgba(208, 212, 202,0.2)"],
        borderColor: ["rgba(255, 76, 41,1)", "rgba(208, 212, 202,1)"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Container>
      <Title color="white">{title}</Title>
      <Wrapper>
        <Doughnut data={data} />

        <StyledTextWrapper>
          {typeof count !== "undefined" && (
            <P1Bold color="white">{count}</P1Bold>
          )}
          <SmallText color="white">{value}%</SmallText>
        </StyledTextWrapper>
      </Wrapper>
    </Container>
  );
};

const Title = styled(P1Bold)`
  margin-bottom: 1rem;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTextWrapper = styled(SmallText)`
  position: absolute;
  z-index: 1;
  text-align: center;
`;
