import { type SyntheticEvent, useCallback, useEffect } from "react";
import { useCreateListMutation, useGetListsQuery } from "../api/listsApi";
import { useNavigate } from "react-router-dom";
import { type ListCreationArgs } from "../api/types";
import { useToastContext } from "context/toastContext";

export const useCreateList = (values: ListCreationArgs) => {
  const { showToaster } = useToastContext();
  const navigate = useNavigate();
  const [createNewList, { isLoading, data, error }] = useCreateListMutation();
  const { refetch: refetchLists } = useGetListsQuery({});

  const onCreateList = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      await createNewList(values);
      await refetchLists();
    },
    [createNewList, values, refetchLists]
  );

  useEffect(() => {
    if (data) {
      showToaster({ text: "New list created" });
      navigate("/lists/" + data.id + "/subscribers");
    }
  }, [navigate, data]);

  useEffect(() => {
    if (error) {
      showToaster({ text: "Something went wrong", mode: "danger" });
      
    }
  }, [error]);

  return { onCreateList, isLoading };
};
