import styled from "styled-components";

interface TableProps {
  children: React.ReactNode;
}

export const Table = ({ children }: TableProps) => {
  return <StyledTable>{children}</StyledTable>;
};

const StyledTable = styled.table`
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  border-collapse: separate;
  border-spacing: 0 0.5em;
  table-layout: auto;

  & .fixed-column {
  }

  & td {
    text-align: start;
    padding: 0.5rem 0.3rem;
    white-space: normal;
  }

  & tbody tr {
    background: ${({ theme }) => theme.colors.white};
  }

  & tr {
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.8rem;
    color: black;
    border-radius: 1rem;
  }

  & th {
    text-align: start;
    color: ${({ theme }) => theme.colors.white};
    padding: 0.3rem;
    padding-bottom: 0;
  }

  & tr td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  & tr td:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    padding-left: 0.5rem;
  }
`;
