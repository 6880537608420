const monthsName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formattedDate = (date: Date) => {
  const year = date.getFullYear();
  const monthText = monthsName[date.getMonth()];
  const day = date.getDate();

  // Extract the time components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${monthText} ${day} ${year}, ${hours}:${minutes}`;
};
