import { useNavigate } from "react-router-dom";
import { useCheckSessionToken } from "./useCheckSessionToken";
import { type PlansType } from "../components/PlanCard";
import { useUserInfo } from "hooks/useUserInfo";
import {
  useLazyGetPaymentUrlQuery,
  useModifySubscriptionMutation,
} from "../api/pricing";
import { useToastContext } from "context/toastContext";
import { useEffect } from "react";

export const PURCHASE_KEY = "purchase-plan";

export const usePlanPurchase = (plan: PlansType) => {
  const { isAuthenticated } = useCheckSessionToken();
  const { plan: userPlan } = useUserInfo();
  const isCustomer = Boolean(userPlan);
  const navigate = useNavigate();
  const [modifyPlan, { isLoading: loadingModify }] =
    useModifySubscriptionMutation();
  const [getPlanUrl, { data, isLoading: loadingUrl }] =
    useLazyGetPaymentUrlQuery();
  const { showToaster } = useToastContext();

  const onPlanPurchase = async () => {
    if (!isAuthenticated) {
      navigate("/register");
      localStorage.setItem(PURCHASE_KEY, plan);
      return;
    }
    if (isCustomer) {
      try {
        await modifyPlan({ newPlan: plan }).unwrap();
        showToaster({ text: "You plan was updated successfully" });
      } catch (err) {
        showToaster({
          text: "Something went wrong. Try again later.",
          mode: "danger",
        });
      }
      return;
    }
    await getPlanUrl({ plan });
  };

  const planInfo: Record<
    PlansType,
    { header: string; price: string; details: string[] }
  > = {
    basic: {
      header: "Basic",
      price: "29€",
      details: ["2.500 subscribers", "5 email campaigns/month"],
    },
    standard: {
      header: "Standard",
      price: "49€",
      details: ["5.000 subscribers", "10 email campaigns/month"],
    },
    premium: {
      header: "Premium",
      price: "99€",
      details: ["10.000 subscribers", "Unlimited email campaigns"],
    },
  };

  useEffect(() => {
    if (data) {
      window.location.replace(data.url);
      localStorage.removeItem(PURCHASE_KEY);
    }
  }, [data]);

  return {
    isLoading: loadingModify || loadingUrl,
    onPlanPurchase,
    userPlan,
    planInfo,
  };
};
