import { useNavigate } from "react-router-dom";
import {
  useDeleteListMutation,
  useGetListDetailQuery,
  useGetSubscribersQuery,
  useUpdateListMutation,
} from "../api/listsApi";
import { useToastContext } from "context/toastContext";
import { useState } from "react";
import { useSearchValue } from "hooks/useSearchValue";

export type SortFields = "email" | "first_name" | "last_name";

export interface SortType {
  field?: SortFields;
  ordering?: "ascending" | "descending";
}

export const useListDetails = (id: string) => {
  const navigate = useNavigate();
  const [deleteList] = useDeleteListMutation();
  const [updateList] = useUpdateListMutation();
  const { data, isLoading, error, refetch } = useGetListDetailQuery({
    id,
  });
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState<SortType>({
    field: undefined,
    ordering: undefined,
  });
  const [isSubscribed, setIsSubscribed] = useState<boolean>();
  const [tagsFilter, setTagsFilter] = useState<string[]>([]);
  const { searchValue } = useSearchValue({ search });
  const { data: subscribers } = useGetSubscribersQuery({
    id,
    search: searchValue || undefined,
    ordering:
      sort.field && sort.ordering
        ? `${sort.ordering === "ascending" ? "" : "-"}${sort.field}`
        : undefined,
    is_subscribed: isSubscribed,
    tags: (tagsFilter.length > 0) ? tagsFilter : undefined,
  });
  const { showToaster } = useToastContext();

  const onSortPress = (field: SortFields) => {
    if (field === sort.field) {
      setSort({
        ...sort,
        ordering:
          sort.ordering === "ascending"
            ? "descending"
            : sort.ordering === "descending"
              ? undefined
              : "ascending",
      });
      return;
    }
    setSort({
      field,
      ordering: "ascending",
    });
  };

  const changeFilter = (field: "status", bool?: boolean) => {
    if (field === "status" && typeof bool !== "undefined") {
      setIsSubscribed(bool);
    } else {
      setIsSubscribed(undefined);
    }
  };

  const onListDelete = async () => {
    try {
      await deleteList({ id }).unwrap();
      showToaster({
        text: "List successfully deleted.",
      });
      navigate("/lists");
    } catch (err) {
      showToaster({
        text: "Error: list not deleted. Try again later.",
        mode: "danger",
      });
    }
  };

  const onUpdateList = async ({
    name,
    description,
  }: {
    name: string;
    description?: string;
  }) => {
    try {
      await updateList({ id, name, description });
    } catch (err) {
      return null;
    }
  };

  return {
    onListDelete,
    onUpdateList,
    detail: data,
    subscribers,
    isLoading,
    error,
    refetch,
    search,
    setSearch,
    onSortPress,
    sort,
    changeFilter,
    isSubscribed,
    setTagsFilter,
    tagsFilter,
  };
};
