import { DateTimePicker } from 'components/DateTimePicker';
import { BaseToggle } from 'components/Toggle';
import styled from 'styled-components';
import { useGetCampaignDetailsQuery } from '../api/campaignsApi';
import { useCampaignContext } from '../context/campaignContext';
import { Spinner } from 'components/Spinner';
import { usePreviewTemplate } from 'modules/templates/hooks/usePreviewTemplate';
import { Icon } from 'assets/icons';

export const SendDetails = () => {
	const {
		details,
		campaignId = '',
		isCampaignScheduled,
		setIsCampaignScheduled,
		onDateChange,
		setCurrentStep,
		listsData,
	} = useCampaignContext();
	const { data, isError, isLoading } = useGetCampaignDetailsQuery({
		campaignId,
	});
	const {
		release_date,
		from_email,
		from_name,
		name,
		lists,
		template,
		subject,
	} = details;

	const { onShowTemplate } = usePreviewTemplate({
		template: template ?? undefined,
	});

	if (isLoading) return <Spinner />;
	if (isError) return <p>Something went wrong!</p>;
	if (!data) return <p>No data found</p>;

	console.log({ lists, listsData });

	return (
		<>
			<Row>
				<HeaderTitle
					name={name}
					onEditClick={() => {
						setCurrentStep(0);
					}}
				/>
			</Row>
			<Row>
				<HeaderTitle name="Send" />
				<BaseToggle
					isChecked={isCampaignScheduled}
					onChange={() => {
						setIsCampaignScheduled(!isCampaignScheduled);
					}}
					label="Schedule campaign"
					leftLabel="Immediately"
				/>
				{isCampaignScheduled && (
					<DateTimePicker
						value={release_date ?? new Date()}
						onDateChange={onDateChange}
					/>
				)}
			</Row>
			<Row>
				<HeaderTitle
					name={'From'}
					onEditClick={() => {
						setCurrentStep(0);
					}}
				/>
				<p>{`${from_name} <${from_email}>`}</p>
			</Row>
			<Row>
				<HeaderTitle
					name={'To'}
					onEditClick={() => {
						setCurrentStep(0);
					}}
				/>
				{listsData
					?.filter((list) => lists.includes(list.id))
					.map((list) => list.name)
					.join(', ')}
			</Row>
			<Row>
				<HeaderTitle
					name={'Subject'}
					onEditClick={() => {
						setCurrentStep(0);
					}}
				/>
				{subject}
			</Row>
			<Row>
				<HeaderTitle
					name={'Template'}
					onEditClick={() => {
						setCurrentStep(1);
					}}
				/>
				<TemplateWrapper>
					<ImageTemplate src={template?.image ?? undefined} />
					<PreviewWrapper onClick={onShowTemplate}>
						<Icon name="eye" />
						<p>Preview</p>
					</PreviewWrapper>
				</TemplateWrapper>
			</Row>
		</>
	);
};

interface HeaderTitleProps {
	name: string;
	onEditClick?: () => void;
}

const HeaderTitle = ({ name, onEditClick }: HeaderTitleProps) => {
	return (
		<Wrapper>
			<Title>{name}</Title>
			{onEditClick && (
				<button onClick={onEditClick}>
					<Icon name="edit" size={15} />
				</button>
			)}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;

const Title = styled.h6`
	margin: 0;
`;

const Row = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
	padding-bottom: 1rem;
	margin-bottom: 2rem;
`;

const ImageTemplate = styled.img`
	width: 10rem;
`;

const TemplateWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const PreviewWrapper = styled.button`
	background: none;
	border: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 2rem;
	cursor: pointer;
`;
