import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "components/Spinner";
import { useTemplateDetails } from "../hooks/useTemplateDetails";
import { HeaderLayout } from "components/HeaderLayout";
import { CardContainer } from "components/CardContainer";
import { type EditorRefType, TemplateEditor } from "components/TemplateEditor";
import { styled } from "styled-components";

export const TemplateDetails = () => {
  const { templateId = "" } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, onUpdateTemplate } = useTemplateDetails(templateId);

  if (!data) return <p>No data found</p>;

  const onGoBack = () => {
    navigate("/templates");
  };

  const onReady = (emailEditorRef: EditorRefType) => {
    const templateJson = JSON.parse(data.design);
    emailEditorRef.current?.editor?.loadDesign(templateJson);
  };

  const saveTemplate = (emailEditorRef: EditorRefType) => {
    emailEditorRef.current?.editor?.exportHtml(async (data) => {
      const { design, html } = data;
      await onUpdateTemplate({
        design: JSON.stringify(design),
        html,
      });
    });
  };

  return (
    <HeaderLayout title="Edit template" onGoBack={onGoBack}>
      <StyledCardContainer center={isLoading}>
        {isLoading ? (
          <Spinner color="main" />
        ) : (
          <TemplateEditor
            onReady={onReady}
            onSubmit={saveTemplate}
            buttonText="Save"
            isLoading={isLoading}
          />
        )}
      </StyledCardContainer>
    </HeaderLayout>
  );
};

const StyledCardContainer = styled(CardContainer)`
  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    padding-left: 0;
    padding-right: 0;
  }
`;
