import { Button } from "components";
import { CardContainer } from "components/CardContainer";
import { Input } from "components/Forms";
import { HeaderLayout } from "components/HeaderLayout";
import { type SyntheticEvent, useEffect, useState } from "react";
import { useCreateSenderMutation } from "../api/settingsApi";
import { useToastContext } from "context/toastContext";
import { useNavigate } from "react-router-dom";
import { useErrorMessage } from "hooks/useErrorMessage";
import styled from "styled-components";

export const AddSender = () => {
  const [create, { isLoading, isSuccess, error }] = useCreateSenderMutation();
  const [email, setEmail] = useState("");
  const { showToaster } = useToastContext();
  const navigate = useNavigate();
  useErrorMessage(error);

  const onCreateSender = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!email) {
      showToaster({ text: "Please provide an e-mail address", mode: "danger" });
      return;
    }
    await create({ email });
  };

  useEffect(() => {
    if (isSuccess) {
      showToaster({ text: "Verification email sent to Sender." });
      navigate("/account/senders");
    }
  }, [isSuccess]);

  const onGoBack = () => {
    navigate("/account/senders");
  };

  return (
    <HeaderLayout title="Add verified sender" onGoBack={onGoBack}>
      <CardContainer>
        <StyledForm action="POST" onSubmit={onCreateSender}>
          <Input
            value={email}
            onChange={(e) => { setEmail(e.target.value); }}
            label="Sender e-mail"
          />
          <Button onClick={onCreateSender} text="Create" loading={isLoading} />
        </StyledForm>
      </CardContainer>
    </HeaderLayout>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
