import { Layout } from "components";
import { PressLink } from "components/Button";
import { MainLogo } from "components/Logo";
import styled from "styled-components";
import { Huge, P1Bold } from "theme/typography";

export const PageNotFound = () => {
  return (
    <Layout>
      <Container>
        <MainLogo />
        <Huge>404</Huge>
        <P1Bold>Page not found</P1Bold>
        <StyledLink text="Go to homepage" to="/" variant="tertiary" />
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
`;

const StyledLink = styled(PressLink)`
  margin-top: 2rem;
`;
