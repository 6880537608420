import { useState } from "react";
import { type UserRegisterData } from "modules/onboarding/api/auth";
import { useRegister } from "../hooks/useRegister";
import { styled } from "styled-components";
import { Button } from "components/Button";
import { AuthLayout } from "../components/AuthLayout";
import { AuthRedirection } from "../components/AuthRedirection";
import { Input } from "components/Forms";

export const Register = () => {
  const [values, setValues] = useState<UserRegisterData>({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const { registerUser, isLoading, errorMessage } = useRegister(values);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <AuthLayout title="Create new account" onSubmit={registerUser}>
      <NameContainer>
        <Input
          value={values.first_name}
          name="first_name"
          placeholder="First name"
          onChange={onChange}
          label="First name"
          error={errorMessage?.first_name}
        />
        <Input
          value={values.last_name}
          name="last_name"
          placeholder="Last name"
          onChange={onChange}
          label="Last name"
          error={errorMessage?.last_name}
        />
      </NameContainer>
      <Input
        value={values.email}
        type="email"
        name="email"
        placeholder="Enter email"
        onChange={onChange}
        label="Email address"
        error={errorMessage?.email}
      />
      <Input
        value={values.password}
        type="password"
        name="password"
        placeholder="Password"
        onChange={onChange}
        label="Password"
        error={errorMessage?.password1}
      />
      <StyledButton
        type="submit"
        disabled={isLoading}
        text="Join now!"
        isFullWidth
        loading={isLoading}
      />
      <AuthRedirection screen="register" />
    </AuthLayout>
  );
};

const NameContainer = styled.div`
  display: flex;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 2rem;
`;
