import { type SyntheticEvent, useEffect, useState } from "react";
import {
  useGetContactQuery,
  useListTagsQuery,
  useUpdateContactMutation,
} from "../api/listsApi";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "context/toastContext";
import { type CreateContact } from "../api/types";
import { type MultiType } from "components/Forms/Dropdown";

interface UseContactDetailsType {
  listId: string;
  contactId: string;
}


export const useContactDetails = ({
  listId,
  contactId,
}: UseContactDetailsType) => {
  const navigate = useNavigate();
  const { showToaster } = useToastContext();
  const { data: contactData, isLoading } = useGetContactQuery({ contactId });
  const [
    updateContact,
    { isLoading: updateLoading, isSuccess: isUpdateSuccess },
  ] = useUpdateContactMutation();
  const { data: tags, isLoading: loadingTags } = useListTagsQuery();

  const [values, setValues] = useState<CreateContact>({
    email: "",
    first_name: "",
    last_name: "",
    tags: [],
    is_subscribed: false,
  });

  const onDropdownChange = (key: string, newValue: MultiType) => {
    // type check for isMulti
    if (Array.isArray(newValue)) {
      setValues({
        ...values,
        [key]: newValue.map((item) => item.value),
      });
    }
  };

  const onToggleSubscribe = () => {
    setValues({
      ...values,
      is_subscribed: !values.is_subscribed,
    });
  };

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onUpdateContact = async (e: SyntheticEvent) => {
    e.preventDefault();
    await updateContact({ contactId, contact: values });
  };

  useEffect(() => {
    if (contactData && !loadingTags) {
      setValues({
        email: contactData.email,
        first_name: contactData.first_name,
        last_name: contactData.last_name,
        tags: contactData.tags,
        is_subscribed: contactData.is_subscribed,
      });
    }
  }, [contactData, tags]);

  useEffect(() => {
    if (isUpdateSuccess) {
      showToaster({ text: "Contact updated successfully!" });
      navigate("/lists/" + listId + "/subscribers");
    }
  }, [isUpdateSuccess]);

  return {
    tags,
    isLoading: isLoading || updateLoading,
    values,
    onDropdownChange,
    contactData,
    onValueChange,
    onUpdateContact,
    onToggleSubscribe,
  };
};
