import { api } from "services/api/api";
import { type FormsType } from "./type";

export const FORMS_TAG = "Forms";

export const formsApi = api
  .enhanceEndpoints({
    addTagTypes: [FORMS_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getForms: build.query<FormsType[], void>({
        query: () => ({
          method: "GET",
          url: "/forms/v1/",
        }),
        providesTags: [FORMS_TAG],
      }),
      createForm: build.mutation<FormsType, { name: string; list: string }>({
        query: (args) => ({
          body: args,
          method: "POST",
          url: "/forms/v1/",
        }),
        invalidatesTags: [FORMS_TAG],
      }),
      deleteForm: build.mutation<void, { id: string }>({
        query: ({ id }) => ({
          method: "DELETE",
          url: "/forms/v1/" + id,
        }),
        invalidatesTags: [FORMS_TAG],
      }),
    }),
  });

export const {
  useGetFormsQuery,
  useCreateFormMutation,
  useDeleteFormMutation,
} = formsApi;
