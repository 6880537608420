import { css, styled } from "styled-components";
import { type ColorKey } from "theme";

interface TextStyleProps {
  color?: ColorKey;
  center?: boolean;
}

const sharedStyles = css<TextStyleProps>`
  color: ${({ theme, color }) => theme.colors[color ?? "inherit"]};
  text-align: ${({ center }) => (center ? "center" : "inherit")};
`;

export const Header = styled.h1<TextStyleProps>`
  ${sharedStyles};
`;
export const Huge = styled.h1<TextStyleProps>`
  font-size: 5rem;
  ${sharedStyles};

  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    font-size: 2rem;
  }
`;

export const P1Regular = styled.p<TextStyleProps>`
  font-size: 1.1rem;
  ${sharedStyles};
`;
export const P1Bold = styled(P1Regular) <TextStyleProps>`
  font-weight: 600;
  ${sharedStyles};
`;
export const P2Regular = styled.p<TextStyleProps>`
  font-size: 0.9rem;
  ${sharedStyles};
`;
export const P2Bold = styled(P2Regular) <TextStyleProps>`
  font-weight: 600;
  ${sharedStyles};
`;

export const SmallText = styled.p<TextStyleProps>`
  font-size: ${({ theme }) => theme.text.small};
  ${sharedStyles};
`;
