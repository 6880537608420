import styled from "styled-components";
import { type FormsType } from "../api/type";
import { P1Regular, SmallText } from "theme/typography";
import { formattedDate } from "utils/formattedDate";
import { useDeleteFormMutation } from "../api/formsApi";
import { Button } from "components";
import { useToastContext } from "context/toastContext";
import { Link } from "react-router-dom";
import { useModalContext } from "context/modalContext";
import { Icon } from "assets/icons";
import { config } from "config/config";
import { useState } from "react";

interface FormRowProps {
  form: FormsType;
}

export const FormRow = ({ form }: FormRowProps) => {
  const [deleteForm, { isLoading }] = useDeleteFormMutation();
  const { showToaster } = useToastContext();
  const { showModal } = useModalContext();

  const onDelete = async () => {
    try {
      await deleteForm({ id: form.id }).unwrap();
      showToaster({ text: "Form deleted!" });
    } catch (err) {
      showToaster({ text: "Something went wrong!" });
    }
  };

  const onScriptClick = () => {
    showModal(<ScriptModal id={form.list.id} />);
  };

  return (
    <Container>
      <div>
        <P1Regular>{form.name}</P1Regular>
        <SmallText>
          Created at {formattedDate(new Date(form.created))}
        </SmallText>
      </div>
      <RightWrapper>
        <StyledButton onClick={onScriptClick}>
          <SmallText>Add to your website</SmallText>
          <Icon name="link" size={15} />
        </StyledButton>
        <ListWrapper>
          <SmallText>Linked to</SmallText>
          <Link to={"/lists/" + form.list.id + "/subscribers"}>
            <SmallText color="main">{form.list.name}</SmallText>
          </Link>
        </ListWrapper>
        <Button
          text="Delete"
          variant="danger"
          icon="delete"
          onClick={onDelete}
          loading={isLoading}
        />
      </RightWrapper>
    </Container>
  );
};

interface ScriptModalProps {
  id: string;
}

const ScriptModal = ({ id }: ScriptModalProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const scriptText = `<script async src="${config.awsUrl}" data-uuid="${id}"></script>`;

  return (
    <ModalWrapper>
      <SmallText>
        Position this HTML code line right before the closing body tag in your
        document.
      </SmallText>
      <Snippet readOnly rows={1}>
        {scriptText}
      </Snippet>
      <Button
        text={isCopied ? "Script was copied" : "Copy"}
        onClick={async () => {
          await navigator.clipboard.writeText(scriptText);
          setIsCopied(true);
          setTimeout(() => { setIsCopied(false); }, 2000);
        }}
      />
    </ModalWrapper>
  );
};

const Container = styled.div`
  background-color: white;
  border-radius: ${({ theme }) => theme.dimensions.radius};
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ListWrapper = styled.div`
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled.button`
  color: black;
  margin-right: 3rem;
  cursor: pointer;
`;

const Snippet = styled.textarea`
  background: ${({ theme }) => theme.colors.grey10};
  border: 1px solid ${({ theme }) => theme.colors.background};
  padding: 1.2rem 1rem;
  resize: none;
  width: 100%;
  border-radius: 5px;
  line-height: 1.2rem;
  white-space: nowrap;
`;
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
