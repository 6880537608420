import { type Action, configureStore, type ThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import rootReducer, { type RootState } from "./state";
import { api } from "../services/api/api";
import { config } from "../config/config";

const store = configureStore({
  reducer: rootReducer,
  devTools: config.isDevEnv,
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      serializableCheck: false,
    }).concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch<any>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
