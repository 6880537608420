import styled from 'styled-components';
import { type CampaignType } from '../api/types';
import { Link } from 'react-router-dom';
import { Button } from 'components';
import { useDeleteCampaignMutation } from '../api/campaignsApi';
import { formattedDate } from 'utils/formattedDate';
import { type CampaignFilter } from '../pages/campaigns';
import { type ColorKey } from 'theme';
import { usePreviewTemplate } from 'modules/templates/hooks/usePreviewTemplate';
import { Icon } from 'assets/icons';
import { DeleteModal } from 'components/DeleteModal';
import { useModalContext } from 'context/modalContext';
import { P1Bold, P1Regular, SmallText } from 'theme/typography';
import calendar from 'assets/images/calendar.png';

interface CampaignRowProps {
	campaign: CampaignType;
}

type CampaignLabel = Exclude<CampaignFilter, 'all'> | 'sending';

export const CampaignRow = ({ campaign }: CampaignRowProps) => {
	const { showModal } = useModalContext();

	const [deleteCampaign, { isLoading: loadingDelete }] =
		useDeleteCampaignMutation();

	const onCampaignDelete = async () => {
		await deleteCampaign({ campaignId: campaign.id });
	};

	const onDelete = () => {
		showModal(
			<DeleteModal
				onDelete={async () => {
					await onCampaignDelete();
				}}
				item={campaign.name}
			/>
		);
	};

	const { sent, scheduled, release_date, created, template, draft } = campaign;
	const { onShowTemplate, templateUrl } = usePreviewTemplate({ template });

	const labelOptions: Record<
		CampaignLabel,
		{ text: string; color: ColorKey; bg: ColorKey }
	> = {
		draft: {
			text: 'Draft',
			color: 'grey10',
			bg: 'black',
		},
		sent: {
			text: 'Sent',
			color: 'grey10',
			bg: 'success',
		},
		scheduled: {
			text: 'Scheduled',
			color: 'primary',
			bg: 'warning',
		},
		sending: {
			text: 'Sending...',
			color: 'primary',
			bg: 'warning',
		},
	};

	const getOptions = () => {
		if (sent) return labelOptions.sent;
		if (scheduled) return labelOptions.scheduled;
		if (draft) return labelOptions.draft;
		return labelOptions.sending;
	};

	const getEditText = () => {
		if (sent) return `Sent ${formattedDate(new Date(created))}`;
		if (scheduled)
			return `Scheduled to ${formattedDate(new Date(release_date))}`;
		return `Created at ${formattedDate(new Date(created))}`;
	};

	const { bg, color, text } = getOptions();

	const hasTemplate = campaign?.template;

	const isCancelDisabled = sent || (!sent && !scheduled && !draft);

	const campaignTo = () => {
		if (draft) {
			return `create/${campaign.id}`;
		}
		if (campaign.analytics_id) {
			return `/analytics/${campaign.analytics_id}`;
		}
		return '#';
	};

	const onCampaignSchedulePress = () => {
		if (scheduled) {
			showModal(<ScheduledModal date={release_date} url={templateUrl} />);
		}
	};

	return (
		<Container>
			<LeftWrapper>
				<Preview onClick={onShowTemplate} disabled={!hasTemplate}>
					{hasTemplate && (
						<Layer>
							<Icon name="eye" color="white" />
						</Layer>
					)}
					<Image src={campaign?.template?.image ?? undefined} />
				</Preview>
				<InfoWrapper>
					<StyledLink to={campaignTo()} onClick={onCampaignSchedulePress}>
						{campaign.name}
					</StyledLink>
					<EditDate>{getEditText()}</EditDate>
				</InfoWrapper>
			</LeftWrapper>
			<LeftWrapper>
				<Label bg={bg} color={color}>
					{text}
				</Label>
				<Button
					variant="danger"
					text="Cancel"
					onClick={onDelete}
					loading={loadingDelete}
					disabled={isCancelDisabled}
				/>
			</LeftWrapper>
		</Container>
	);
};

interface ScheduledModalProps {
	date: string;
	url: string;
}

const ScheduledModal = ({ date, url }: ScheduledModalProps) => {
	return (
		<ModalWrapper>
			<StyledImage src={calendar} />
			<TextWrapper>
				<P1Regular>Your campaign is scheduled to send on:</P1Regular>
				<P1Bold>{formattedDate(new Date(date))}</P1Bold>
				<BottomShare>
					<SmallText>You can view/share your campaign</SmallText>
					<a href={url} target="_blank" rel="noreferrer">
						<SmallText color="main">here</SmallText>
					</a>
				</BottomShare>
			</TextWrapper>
		</ModalWrapper>
	);
};

const StyledLink = styled(Link)`
	color: black;
	font-weight: bold;
`;

const TextWrapper = styled.div`
	text-align: center;
`;

const BottomShare = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.2rem;
	text-decoration: none;
	margin-top: 1rem;
`;

const ModalWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	gap: 2rem;
`;

const StyledImage = styled.img`
	width: 20rem;
`;

const Layer = styled.div`
	cursor: pointer;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: 0.5s;
	&:hover {
		opacity: 1;
	}
`;

const Preview = styled.button`
	margin-right: 2rem;
	position: relative;
`;

const EditDate = styled.p`
	font-size: 0.7rem;
	margin-top: 0.4rem;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Label = styled.div<{ bg: ColorKey; color: ColorKey }>`
	padding: 0.3rem 0.5rem;
	border-radius: ${({ theme }) => theme.dimensions.radius};
	background-color: ${({ bg, theme }) => theme.colors[bg]};
	color: ${({ theme, color }) => theme.colors[color]};
	font-size: 0.8rem;
	margin-right: 8rem;
`;

const LeftWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const Image = styled.img`
	width: 6rem;
`;

const Container = styled.div`
	padding: 1rem;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: ${({ theme }) => theme.dimensions.radius};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
