import { api } from "services/api/api";
import { type AnalyticsType, type ChartData, type HistoryType } from "./types";

export const ANALYTICS_TAG = "Analytics";
export const ANALYTICS_DETAIL_TAG = "AnalyticsDetail";
export const ANALYTICS_CHART_DATA_TAG = "ChartData";

export const analyticsApi = api
  .enhanceEndpoints({
    addTagTypes: [
      ANALYTICS_TAG,
      ANALYTICS_DETAIL_TAG,
      ANALYTICS_CHART_DATA_TAG,
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getAnalytics: build.query<AnalyticsType[], void>({
        query: () => ({
          method: "GET",
          url: "/analytics/v1/",
        }),
        providesTags: [ANALYTICS_TAG],
      }),
      getAnalyticsDetail: build.query<AnalyticsType, { id: string }>({
        query: ({ id }) => ({
          method: "GET",
          url: "/analytics/v1/" + id,
        }),
        providesTags: [ANALYTICS_DETAIL_TAG],
      }),
      getHistory: build.query<
        HistoryType[],
        {
          search?: string;
          analyticsId: string;
          event?: string;
          ordering?: string;
        }
      >({
        query: ({ search, analyticsId, event, ordering }) => ({
          method: "GET",
          url: "/analytics/v1/history",
          params: { search, analytics: analyticsId, event, ordering },
        }),
        providesTags: [ANALYTICS_DETAIL_TAG],
      }),
      getChartData: build.query<ChartData, { days: number }>({
        query: ({ days }) => ({
          method: "GET",
          url: "/analytics/v1/chart_data" + `?days=${days}`,
        }),
        providesTags: [ANALYTICS_CHART_DATA_TAG],
      }),
    }),
  });

export const {
  useGetAnalyticsDetailQuery,
  useGetAnalyticsQuery,
  useGetChartDataQuery,
  useGetHistoryQuery,
} = analyticsApi;
