import { useToastContext } from 'context/toastContext';
import { type TemplateDetail } from 'modules/templates/api/types';
import { createContext, useContext, useEffect, useState } from 'react';
import { useGetTemplatesQuery } from 'modules/templates/api/templatesApi';
import { useGetListsQuery } from 'modules/lists/api/listsApi';
import {
	useCreateCampaignMutation,
	useCreateSegmentMutation,
	useDeleteSegmentMutation,
	useLazyGetCampaignDetailsQuery,
	useUpdateCampaignMutation,
	useUpdateSegmentMutation,
} from '../api/campaignsApi';
import { useErrorMessage } from 'hooks/useErrorMessage';
import { formatDate } from '../utils';
import {
	type SegmentType,
	type CampaignArgs,
	type CreateSegment,
} from '../api/types';
import { isEmailValid } from 'utils/regex';
import { ConfigCampaign } from '../components/Config';
import { SelectTemplate } from '../components/SelectTemplate';
import { SendDetails } from '../components/SendDetails';
import { type ListDetails } from 'modules/lists/api/types';
import { useNavigate, useParams } from 'react-router-dom';
import {
	type SingleType,
	type DropdownNewValue,
} from 'components/Forms/Dropdown';
import { useUserInfo } from 'hooks/useUserInfo';
import { useModalContext } from 'context/modalContext';
import { AddDetailsModal } from '../components/AddDetailsModal';
import { FreeDomainsWarning } from '../components/FreeDomainsModal';

export interface CreateCampaignValues {
	name: string;
	subject: string;
	from_name: string;
	from_email: string;
	template?: TemplateDetail | null;
	lists: string[];
	release_date?: Date;
	draft: boolean;
}

interface OptionsComponent {
	name: string;
	isDone: boolean;
	Component: JSX.Element;
	buttonText: string;
	onNextPress?: () => void;
}

interface CampaignContextProviderProps {
	children: React.ReactNode;
}

interface CampaignContextState {
	details: CreateCampaignValues;
	setDetails: React.Dispatch<React.SetStateAction<CreateCampaignValues>>;
	templates?: TemplateDetail[];
	listsData?: ListDetails[];
	campaignId?: string;
	onDateChange: (date: Date) => void;
	onListChange: (value: DropdownNewValue) => void;
	onFromEmailChange: (newValue: SingleType) => void;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	setIsCampaignScheduled: (bool: boolean) => void;
	onTemplateCreate: (template: TemplateDetail) => void;
	isLoading: boolean;
	currentStep: number;
	options: OptionsComponent[];
	setCurrentStep: (num: number) => void;
	isCampaignScheduled: boolean;
	hasSegment: boolean;
	onSegmentToggle: () => void;
	onSegmentChange: (key: string, value: string, index: number) => void;
	segments: Omit<CreateSegment, 'campaign'>[];
	onAddSegment: () => void;
	onRemoveSegment: (index: number) => void;
}

const initialDetails = {
	name: '',
	subject: '',
	from_name: '',
	from_email: '',
	template: null,
	lists: [],
	release_date: undefined,
	draft: true,
	segments: [],
};

const initialState = {
	details: { ...initialDetails },
	setDetails: () => null,
	onNextStepPress: () => null,
	onDateChange: () => null,
	onListChange: () => null,
	onChange: () => null,
	setIsCampaignScheduled: () => null,
	onTemplateCreate: () => null,
	isLoading: false,
	currentStep: 0,
	options: [],
	setCurrentStep: () => null,
	isCampaignScheduled: false,
	onFromEmailChange: () => null,
	onSegmentToggle: () => null,
	hasSegment: false,
	onSegmentChange: () => null,
	segments: [],
	onAddSegment: () => null,
	onRemoveSegment: () => null,
};

export const CampaignContext = createContext<CampaignContextState>({
	...initialState,
});

export const useCampaignContext = () => {
	const context = useContext(CampaignContext);

	if (context === undefined) {
		throw new Error('ModalContext was used outside of its Provider');
	}

	return context;
};

export const CampaignContextProvider = ({
	children,
}: CampaignContextProviderProps) => {
	const { showToaster } = useToastContext();
	const navigate = useNavigate();
	const { userAddress, userDetails } = useUserInfo();
	const { data: templates, isLoading: loadingTemplates } =
		useGetTemplatesQuery();
	const { data: listsData, isLoading: loadingLists } = useGetListsQuery({});
	const [create, { error }] = useCreateCampaignMutation();
	const [update, { isSuccess: updateSuccess }] = useUpdateCampaignMutation();
	const [getCampaignDetails, { data: detailsData }] =
		useLazyGetCampaignDetailsQuery();
	const [createSegment] = useCreateSegmentMutation();
	const [deleteSegment] = useDeleteSegmentMutation();
	const [updateSegment] = useUpdateSegmentMutation();
	const [isCampaignScheduled, setIsCampaignScheduled] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [completedSteps, setCompletedSteps] = useState<number[]>([]);
	const { id } = useParams();
	const [hasSegment, setHasSegment] = useState(false);
	const [segments, setSegments] = useState<SegmentType[]>([]);
	const [details, setDetails] = useState<CreateCampaignValues>({
		...initialDetails,
	});
	const [isFreeDomain, setIsFreeDomain] = useState(false);
	const { showModal, onModalClose } = useModalContext();
	const {
		lists,
		template,
		name,
		subject,
		from_email,
		from_name,
		release_date,
	} = details;
	const isCampaignCreated = Boolean(id);
	const warningDomains = ['hotmail.com', 'gmail.com', 'outlook.com'];
	const domain = from_email.split('@')[1];

	const createDraftCampaign = async () => {
		if (!lists) return;

		if (!userDetails || !userAddress) {
			showModal(<AddDetailsModal />);
			return;
		}

		if (isFreeDomain) {
			showModal(
				<FreeDomainsWarning
					domain={domain}
					onCancel={onModalClose}
					onIgnore={() => {
						setIsFreeDomain(false);
						onModalClose();
					}}
				/>
			);
			return;
		}

		const obj: CampaignArgs = {
			name,
			subject,
			lists,
			from_email,
			from_name,
			draft: true,
		};
		if (isCampaignScheduled && release_date) {
			obj.release_date = formatDate(release_date);
		}
		try {
			const campaign = await create(obj).unwrap();

			if (hasSegment && segments) {
				const segmentsData = segments
					.filter((item) => item.field && item.logic && item.value)
					.map((item) => ({
						...item,
						campaign: campaign.id,
					}));
				await createSegment(segmentsData);
			}
			setCurrentStep(currentStep + 1);
			const completed = [...completedSteps];
			if (!completed.includes(currentStep)) {
				completed.push(currentStep);
				setCompletedSteps(completed);
			}
			navigate(campaign.id);
		} catch (err) {}
	};

	const onSegmentToggle = () => {
		setHasSegment(!hasSegment);
	};
	const onListChange = (newValue: DropdownNewValue) => {
		if (Array.isArray(newValue)) {
			setDetails({
				...details,
				lists: newValue.map((item) => item.value),
			});
		}
	};

	const onFromEmailChange = (newValue: SingleType) => {
		if (!newValue) return;
		setDetails({
			...details,
			from_email: newValue.value,
		});
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDetails({
			...details,
			[e.target.name]: e.target.value,
		});
	};

	const onDateChange = (date: Date) => {
		if (!date) return;
		setDetails({
			...details,
			release_date: new Date(date),
		});
	};

	const onTemplateCreate = (template: TemplateDetail) => {
		setDetails({
			...details,
			template,
		});
	};

	const isConfigValid = () => {
		if (!name || !from_email || !from_name || !subject || !lists) {
			showToaster({ text: 'Please fill in all fields', mode: 'danger' });
			return false;
		}
		if (!isEmailValid(from_email)) {
			showToaster({ text: 'Please add a valid email', mode: 'danger' });
			return false;
		}
		return true;
	};

	const onConfigFinish = async () => {
		if (!isConfigValid()) return;
		if (id) {
			const campaignObj = {
				id,
				...details,
				lists,
				draft: true,
			};
			delete campaignObj.template;
			await update(campaignObj);

			// update or create segments
			const segmentUpdates = [];
			const segmentCreate = [];

			const filteredSegments = segments.filter(
				(item) => item.field && item.logic && item.value
			);

			for (const segment of filteredSegments) {
				if (segment.id) {
					segmentUpdates.push(await updateSegment(segment));
				} else {
					const { field, logic, value } = segment;
					const newSegment = { campaign: id, field, logic, value };
					segmentCreate.push(newSegment);
				}
			}
			const allPromises = Promise.all([
				...segmentUpdates,
				createSegment(segmentCreate),
			]);

			try {
				await allPromises;
				setCurrentStep(currentStep + 1);
			} catch (err) {}
		} else {
			await createDraftCampaign();
		}
	};

	const onSendCampaign = async () => {
		if (!id) {
			showToaster({ text: 'Campaign was not created!', mode: 'danger' });
			return;
		}
		setDetails({ ...details, draft: false });
		const campaignObj = {
			id,
			...details,
			lists,
			template: template?.id,
			draft: false,
		};
		await update(campaignObj);
	};

	const onSegmentChange = (
		key: keyof Omit<CreateSegment, 'campaign'>,
		value: string,
		index: number
	) => {
		const allSegments = segments.map((item) => ({ ...item }));
		allSegments[index][key] = value;
		// reset value if user change field
		if (key === 'field') {
			allSegments[index].value = '';
		}
		setSegments(allSegments);
	};

	const onAddSegment = () => {
		setSegments([
			...segments,
			{
				id: 0,
				field: '',
				logic: '',
				value: '',
				campaign: '',
			},
		]);
	};

	const onRemoveSegment = async (index: number) => {
		const segmentsCopy = [...segments];
		const deletedSegment = segmentsCopy.splice(index, 1);
		setSegments(segmentsCopy);
		if (segmentsCopy.length === 0) {
			setHasSegment(false);
		}

		if (deletedSegment[0].id) {
			await deleteSegment({ id: deletedSegment[0].id });
		}
	};

	const options = [
		{
			name: 'Config',
			isDone: completedSteps.includes(0),
			Component: <ConfigCampaign />,
			buttonText: 'Next',
			onNextPress: onConfigFinish,
		},
		{
			name: 'Template',
			isDone: completedSteps.includes(1),
			Component: <SelectTemplate />,
			buttonText: 'Save & next',
		},
		{
			name: 'Send',
			isDone: completedSteps.includes(2),
			Component: <SendDetails />,
			buttonText: 'Send',
			onNextPress: onSendCampaign,
		},
	];

	useErrorMessage(error);

	useEffect(() => {
		if (!isCampaignCreated) return;
		if (isCampaignCreated && template) {
			setCompletedSteps([0, 1]);
			setCurrentStep(2);
			return;
		}
		setCompletedSteps([0]);
		setCurrentStep(1);
	}, [isCampaignCreated, template]);

	useEffect(() => {
		if (updateSuccess) {
			const isDraft = details.draft;
			const updateText = 'Your campaign was updated successfully';
			const sendText = `Your campaign was ${
				release_date ? 'scheduled' : 'sent'
			} successfully`;
			showToaster({
				text: isDraft ? updateText : sendText,
			});
			if (!isDraft) {
				navigate('/campaigns');
			}
		}
	}, [updateSuccess]);

	useEffect(() => {
		if (id) {
			getCampaignDetails({ campaignId: id });
		}
	}, [id]);

	useEffect(() => {
		if (detailsData) {
			const {
				name,
				from_email,
				from_name,
				lists,
				subject,
				template,
				segments,
			} = detailsData;

			const campaign: CreateCampaignValues = {
				name,
				from_email,
				from_name,
				lists,
				subject,
				draft: true,
			};

			if (template) {
				campaign.template = template;
			}

			if (segments) {
				setSegments(segments);
				setHasSegment(true);
			}
			setDetails(campaign);
		}
	}, [detailsData]);

	useEffect(() => {
		if (hasSegment && segments.length === 0) {
			setSegments([
				{
					field: '',
					logic: '',
					value: '',
					campaign: '',
					id: 0,
				},
			]);
		}
	}, [hasSegment, segments]);

	useEffect(() => {
		if (warningDomains.includes(domain)) {
			setIsFreeDomain(true);
		}
	}, [from_email]);

	return (
		<CampaignContext.Provider
			value={{
				details,
				templates,
				listsData,
				campaignId: id,
				onDateChange,
				onListChange,
				onChange,
				setIsCampaignScheduled,
				onTemplateCreate,
				isLoading: loadingTemplates || loadingLists,
				currentStep,
				options,
				setCurrentStep,
				isCampaignScheduled,
				setDetails,
				onFromEmailChange,
				hasSegment,
				onSegmentToggle,
				onSegmentChange,
				segments,
				onAddSegment,
				onRemoveSegment,
			}}
		>
			{children}
		</CampaignContext.Provider>
	);
};
