import { GradientText } from "components/GradientText";
import { config } from "config/config";
import styled from "styled-components";
import { Huge, P1Bold, P1Regular } from "theme/typography";

export const ContactUs = () => {
  const email = config.supportEmail;
  return (
    <Container>
      <Title center>Join us in shaping your email marketing success</Title>
      <P1Regular>
        At TargetEmailPro, were on the brink of something big in the world of
        email marketing. This is just the start, and we invite you to be a part
        of it.
      </P1Regular>
      <P1Regular>
        Our mission is to empower businesses with personalized and targeted
        email marketing automation. We know every business is unique, and we&apos;re
        here to customize our software to meet your needs.
      </P1Regular>
      <P1Regular>
        Have ideas on how email marketing can boost your business? Want to share
        your insights? We&apos;re all ears. Your input is crucial as we evolve our
        software.
      </P1Regular>
      <P1Regular>
        Let&apos;s shape your email marketing success together. Contact us today, and
        let&apos;s explore how we can help your business thrive.
      </P1Regular>
      <P1Regular center>Contact us to:</P1Regular>
      <a href={`mailto:${email}`}>
        <Email center>{email}</Email>
      </a>
    </Container>
  );
};

const Email = styled(P1Bold)`
  color: ${({ theme }) => theme.colors.main};
`;

const Title = styled(Huge)`
  margin: 0 4rem;
  ${GradientText}

  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    margin: 0;
  }
`;

const Container = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
  gap: 1.5rem;
  padding: 2rem;
  color: white;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 76, 41, 0.376) 0%,
    rgba(37, 38, 44, 0) 100%
  );

  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    padding: 2rem 0;
  }
`;


