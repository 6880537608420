import { PressLink } from "components/Button";
import { CardContainer } from "components/CardContainer";
import { useUserInfo } from "hooks/useUserInfo";
import styled from "styled-components";
import { Header } from "theme/typography";

export const Plan = () => {
  const { plan } = useUserInfo();
  return (
    <CardContainer>
      <Wrapper>
        <Header>Your current plan:</Header>
        <p>{plan ?? "Free trial"}</p>
      </Wrapper>

      {plan !== "premium" && (
        <PressLink text="Upgrade now" to="/pricing" variant="cta" />
      )}
    </CardContainer>
  );
};

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;
