import { config } from "../../config/config";
import { type RootState } from "store/state";
import { setToken } from "modules/onboarding/data/authSlice";
import {
  type BaseQueryFn,
  type FetchArgs,
  type FetchBaseQueryError,
  type FetchBaseQueryMeta,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { type QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const baseQuery = fetchBaseQuery({
  baseUrl: config.apiEndpoint,
  prepareHeaders: (headers, api) => {
    const { authToken } = (api.getState() as RootState).auth;
    if (authToken) {
      headers.set("authorization", `Bearer ${authToken}`);
    }
    return headers;
  },
  paramsSerializer: (params) => {
    const entries = Object.entries(params);
    const finalString = [];
    for (const item of entries) {
      const key = item[0];
      const values = item[1];
      if (typeof values !== "undefined") {
        if (Array.isArray(values)) {
          const text = `${key}=${values.join(`&${key}=`)}`;
          finalString.push(text);
        } else {
          const text = `${key}=${values}`;
          finalString.push(text);
        }
      }
    }
    return finalString.join("&");
  },
});

export const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) {
      api.dispatch(setToken(""));
      return result;
    }

    type QueryResult = QueryReturnValue<
      { access: string },
      FetchBaseQueryError,
      FetchBaseQueryMeta
    >;

    const refreshResult = (await baseQuery(
      {
        url: "/auth/token/refresh/",
        method: "POST",
        body: { refresh: refreshToken },
      },
      api,
      extraOptions
    )) as QueryResult;

    if (refreshResult.data) {
      api.dispatch(setToken(refreshResult.data.access));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      console.log("refresh token has expired");
      api.dispatch(setToken(""));
    }
  }
  return result;
};
