import { type ErrorData, type FormOptions, type HttpMethodType } from "types/FormOptions";
import { type SyntheticEvent } from "react";
import { Dropdown, Input } from "./Forms";
import { type DropdownNewValue } from "./Forms/Dropdown";
import { Button } from "./Button";
import { styled } from "styled-components";
import { DateTimePicker, type OnDateChange } from "./DateTimePicker";

export type OnDropDownChange = (
  key: string,
  newValue: DropdownNewValue
) => void;

export type FieldsOptions = Record<
  string,
  { options: any[]; key?: string; text?: string; isMulti?: boolean }
>;

interface FormProps {
  data?: FormOptions;
  values: Record<string, any>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDateChange?: OnDateChange;
  onDropdownChange?: OnDropDownChange;
  onSubmit: (e: SyntheticEvent) => Promise<void>;
  submitText?: string;
  fields?: FieldsOptions;
  hiddenFields?: string[];
  isLoading?: boolean;
  errors?: ErrorData;
}

export const BaseForm = ({
  data,
  onSubmit,
  values,
  onChange,
  onDateChange,
  onDropdownChange,
  submitText = "Submit",
  fields,
  hiddenFields = [],
  isLoading,
  errors,
}: FormProps) => {
  if (!data) return null;
  const { actions } = data;
  const method = Object.keys(actions)[0] as HttpMethodType;
  const inputData = actions[method];
  if (!inputData) return null;
  return (
    <StyledForm onSubmit={onSubmit}>
      {Object.entries(inputData).map((input) => {
        const value = input[0];
        const { label, read_only, choices = [], type, required } = input[1];

        const inputLabel = `${label}${!required ? " (optional)" : ""}`;

        if (read_only) return null;
        if (hiddenFields?.includes(value)) return null;

        if (type === "choice") {
          const options = choices.map((item) => ({
            value: item.value,
            label: item.display_name,
          }));

          return (
            <Dropdown
              key={value}
              options={options}
              value={options.filter((item) => {
                return item.value === values[value];
              })}
              label={inputLabel}
              placeholder={`Select ${value.split("_").join(" ")}`}
              onValueChange={(newValue) => onDropdownChange?.(value, newValue)}
              error={errors?.[value]}
            />
          );
        }

        if (type === "field") {
          if (fields?.[value]) {
            const {
              options,
              key = "id",
              text = "name",
              isMulti,
            } = fields[value];

            const dropDownOptions = options.map((item) => ({
              value: item[key],
              label: item[text],
            }));

            return (
              <Dropdown
                placeholder={`Select ${value.split("_").join(" ")}`}
                key={value}
                label={inputLabel}
                onValueChange={(newValue) =>
                  onDropdownChange?.(value, newValue)
                }
                options={dropDownOptions}
                value={dropDownOptions.filter((item) => {
                  if (isMulti) {
                    return values[value].includes(item.value);
                  } else {
                    return item.value === values[value];
                  }
                })}
                error={errors?.[value]}
                isMulti={isMulti}
              />
            );
          }
        }

        if (type === "datetime" && onDateChange) {
          return (
            <DateTimePicker
              key={value}
              label={inputLabel}
              onDateChange={onDateChange}
              value={values[value]}
            />
          );
        }
        return (
          <Input
            key={value}
            value={values[value]}
            type={type === "string" ? undefined : type}
            name={value}
            placeholder={label}
            onChange={onChange}
            label={inputLabel}
            error={errors?.[value]}
          />
        );
      })}
      <ButtonWrapper>
        <Button
          variant="primary"
          type="submit"
          disabled={isLoading}
          text={submitText}
          isFullWidth
          onClick={onSubmit}
          loading={Boolean(isLoading)}
        />
      </ButtonWrapper>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
`;
