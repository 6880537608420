import { useLocation, useNavigate, useOutlet } from "react-router-dom";
import { TabHeaderNav } from "components/TabHeaderNav";
import { useEffect, useState } from "react";
import { HeaderLayout } from "components/HeaderLayout";

export const AccountLayout = () => {
  const [name, setName] = useState("");
  const outlet = useOutlet();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const linkOptions = [
    {
      text: "Profile",
      to: "profile",
    },
    {
      text: "Details",
      to: "details",
    },
    {
      text: "Address",
      to: "address",
    },
    {
      text: "Senders",
      to: "senders",
    },
    {
      text: "My plan",
      to: "plan",
    },
    {
      text: "Settings",
      to: "settings",
    },

    // {
    //   text: "Members",
    //   to: "members",
    // },
  ];

  useEffect(() => {
    if (pathname === "/account") {
      navigate("profile");
      setName("Profile");
    }
  }, [pathname]);

  return (
    <>
      <TabHeaderNav
        options={linkOptions}
        onLinkClick={({ text }) => { setName(text); }}
      />
      <HeaderLayout title={name}>{outlet}</HeaderLayout>
    </>
  );
};
