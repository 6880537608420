import { Icon } from "assets/icons";
import { styled } from "styled-components";
interface StepsType {
  options: Array<{ isDone: boolean; name: string }>;
  onStepPress: (index: number) => void;
}

export const Steps = ({ options, onStepPress }: StepsType) => {
  return (
    <Container>
      <Line />
      {options.map(({ name, isDone }, index) => (
        <StepWrapper key={name}>
          <Circle
            isDone={isDone}
            onClick={() => {
              if (isDone) {
                onStepPress(index)
              }
            }}
          >
            {isDone && <Icon name="check" size={12} color="white" />}
          </Circle>
          <Name>{name}</Name>
        </StepWrapper>
      ))
      }
    </Container >
  );
};

const Line = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.main};
  position: absolute;
  margin-bottom: 1.1rem;
  left: 1.1rem;
  right: 1.1rem;
`;

const StepWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  position: relative;
`;

const Name = styled.p`
  font-size: 0.7rem;
`;

const Circle = styled.div<{ isDone: boolean }>`
  border: 2px solid ${({ theme }) => theme.colors.main};
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 1rem;
  position: relative;
  background-color: ${({ theme, isDone }) =>
    theme.colors[isDone ? "main" : "white"]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isDone }) => (isDone ? "pointer" : "auto")};
`;
