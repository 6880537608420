import { type SyntheticEvent, useEffect, useState } from 'react';
import {
	useGetUserInformationQuery,
	usePostUserInformationMutation,
} from '../api/settingsApi';
import { useToastContext } from 'context/toastContext';
import { useErrorMessage } from 'hooks/useErrorMessage';
import { type SingleType } from 'components/Forms/Dropdown';

export const useUserAccount = () => {
	const { data } = useGetUserInformationQuery();
	const [postUserInfo, { isSuccess, error: postError }] =
		usePostUserInformationMutation();
	const { showToaster } = useToastContext();

	useErrorMessage(postError);

	const [values, setValues] = useState({
		first_name: '',
		last_name: '',
		email: '',
		timezone: '',
	});

	const saveUserInformation = async (e: SyntheticEvent) => {
		e.preventDefault();
		const { email, last_name, first_name, timezone } = values;
		const postObj = {
			email,
			first_name,
			last_name,
			timezone,
		};
		await postUserInfo(postObj);
	};

	const onDropdownChange = (key: string, newValue: SingleType) => {
		if (newValue) {
      console.log('TEST', newValue.value)
			setValues({
				...values,
				[key]: newValue.value,
			});
		}
	};

	const onChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		setValues({
			...values,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		if (data) {
			const { email, first_name, last_name, timezone } = data;
			console.log({ data });
			const dataObj = {
				first_name,
				last_name,
				email,
				timezone,
			};
			setValues(dataObj);
		}
	}, [data]);

	useEffect(() => {
		if (isSuccess) {
			showToaster({
				text: 'Your profile was successfully saved!',
			});
		}
	}, [isSuccess, showToaster]);

	return {
		userInformation: values,
		onChange,
		onDropdownChange,
		saveUserInformation,
	};
};
