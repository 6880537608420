import { TabHeaderNav } from "components/TabHeaderNav";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export const ListsLayout = () => {
  const tabOptions = [
    {
      to: "/lists",
      text: "My lists",
    },
    {
      to: "tags",
      text: "Tags",
    },
  ];
  return (
    <Wrapper>
      <TabHeaderNav options={tabOptions} />
      <Outlet />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
`;
