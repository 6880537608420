import { CardContainer } from "components/CardContainer";
import {
  useGetAccountAddressOptionsQuery,
  useGetAccountSettingsQuery,
  usePostAccountAddressMutation,
  useUpdateAccountAddressMutation,
} from "../api/settingsApi";
import { FullScreenSpinner } from "components/Spinner";
import { BaseForm } from "components";
import { type SyntheticEvent, useEffect, useState } from "react";
import { type AccountAddress } from "../api/types";
import { useToastContext } from "context/toastContext";
import { type SingleType } from "components/Forms/Dropdown";
import { type OnDropDownChange } from "components/Form";

export const Address = () => {
  const { data } = useGetAccountSettingsQuery();
  const { data: options, isLoading } = useGetAccountAddressOptionsQuery();
  const [createAddress] = usePostAccountAddressMutation();
  const [updateAddress] = useUpdateAccountAddressMutation();
  const { showToaster } = useToastContext();



  const [address, setAddress] = useState<AccountAddress>({
    address: "",
    address2: "",
    city: "",
    country: "",
    region: "",
    zip_code: "",
  });

  const updateUserAddress = async () => {
    try {
      await updateAddress(address).unwrap();
      showToaster({
        text: "Address updated successfully!",
      });
    } catch (err) {
      showToaster({
        text: "Couldn't update address. Try again later.",
        mode: "danger",
      });
    }
  };

  const createUserAddress = async () => {
    try {
      await createAddress(address).unwrap();
      showToaster({
        text: "Address saved successfully!",
      });
    } catch (err) {
      showToaster({
        text: "Couldn't saved address. Try again later.",
        mode: "danger",
      });
    }
  };

  const saveAccountAddress = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (data?.address) {
      await updateUserAddress();
    } else {
      await createUserAddress();
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  const onDropdownChange = (key: string, newValue: SingleType) => {
    if (!newValue) return;
    setAddress({
      ...address,
      [key]: newValue.value,
    });
  };

  useEffect(() => {
    if (data?.address) {
      const { address, address2, city, country, region, zip_code } =
        data.address;

      setAddress({
        address,
        address2,
        city,
        country,
        region,
        zip_code,
      });
    }
  }, [data, options]);

  const loading = isLoading;
  if (loading) return <FullScreenSpinner />;

  return (
    <CardContainer>
      <BaseForm
        data={options}
        onSubmit={saveAccountAddress}
        values={address}
        onChange={onChange}
        onDropdownChange={onDropdownChange as OnDropDownChange}
        isLoading={isLoading}
      />
    </CardContainer>
  );
};
