import { Button } from "components/Button";
import { useRef } from "react";
import EmailEditor, { type EmailEditorProps, type EditorRef } from "react-email-editor";
import { styled } from "styled-components";
import "./style.css";

export type EditorRefType = React.RefObject<EditorRef>;

interface TemplateEditorProps {
  onReady: (ref: EditorRefType) => void;
  isLoading?: boolean;
  onSubmit: (ref: EditorRefType) => void;
  buttonText?: string;
  isButtonDisabled?: boolean;
}

export const TemplateEditor = ({
  onReady,
  isLoading,
  onSubmit,
  buttonText,
  isButtonDisabled,
}: TemplateEditorProps) => {
  const emailEditorRef = useRef<EditorRef>(null);

  const onEditorSubmit = () => {
    if (emailEditorRef.current) {
      onSubmit(emailEditorRef);
    }
  };

  const options: EmailEditorProps["options"] = {
    appearance: {
      theme: "dark",
      panels: {
        tools: {
          dock: "left",
          collapsible: true,
          tabs: {
            body: {
              visible: true
            }
          }
        }
      },
      features: {
        preview: true
      }
    }

  }

  return (
    <Container>
      <Editor
        ref={emailEditorRef}
        onReady={() => { onReady(emailEditorRef); }}
        options={options}
        minHeight={"700px"}
        style={{ borderWidth: 2 }}
      />
      {!isButtonDisabled && (
        <ButtonWrapper>
          <Button
            onClick={onEditorSubmit}
            text={buttonText ?? "Create"}
            loading={isLoading}
          />
        </ButtonWrapper>
      )}
    </Container>
  );
};

const Editor = styled(EmailEditor)``;

const Container = styled.div``;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;
