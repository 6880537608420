import ClipLoader from "react-spinners/ClipLoader";
import { styled, useTheme } from "styled-components";
import { type ColorKey } from "theme";

interface SpinnerProps {
  color?: ColorKey;
  size?: number;
}

export const Spinner = ({
  color = "white",
  size = 30,
  ...props
}: SpinnerProps) => {
  const theme = useTheme();
  return (
    <ClipLoader
      color={theme?.colors[color]}
      loading={true}
      aria-label="Loading Spinner"
      data-testid="loader"
      size={size}
      {...props}
    />
  );
};

export const FullScreenSpinner = () => {
  return (
    <Container>
      <Spinner size={50} color="main" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background};
`;
