import styled from "styled-components";
import { PressLink } from "./Button";
import { Header } from "./Header";
import { Icon, type IconNames } from "assets/icons";

interface RightButton {
  to: string;
  text: string;
  icon?: IconNames;
}

interface HeaderLayoutProps {
  children: React.ReactNode;
  title: string;
  rightButton?: RightButton;
  onGoBack?: () => void;
}

export const HeaderLayout = ({
  title,
  children,
  rightButton,
  onGoBack,
}: HeaderLayoutProps) => {
  return (
    <>
      <HeaderWrapper>
        <TitleWrapper>
          {onGoBack && (
            <Button onClick={onGoBack}>
              <Icon name="back" color="white" />
            </Button>
          )}
          <Header title={title} />
        </TitleWrapper>
        {rightButton && (
          <PressLink
            to={rightButton.to}
            variant="secondary"
            text={rightButton.text}
            icon={rightButton.icon}
          />
        )}
      </HeaderWrapper>
      <ScrollableArea>{children}</ScrollableArea>
    </>
  );
};

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ScrollableArea = styled.div`
  overflow-y: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
