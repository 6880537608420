import {
  FiPlus,
  FiChevronLeft,
  FiLogOut,
  FiSettings,
  FiBookOpen,
  FiLayers,
  FiHome,
  FiTarget,
  FiTrendingUp,
  FiLayout,
  FiList,
  FiGrid,
  FiUser,
  FiMoreHorizontal,
  FiCheck,
  FiTrash,
  FiEdit2,
  FiX,
  FiMenu,
  FiEye,
  FiSearch,
  FiTriangle,
  FiClipboard,
  FiLink2,
} from "react-icons/fi";
import { useTheme } from "styled-components";
import { type ColorKey } from "theme";

interface IconComponentProps {
  name: IconNames;
  color?: ColorKey;
  size?: number;
  fill?: string;
}

const iconsKeys = [
  "add",
  "back",
  "exit",
  "settings",
  "book",
  "layers",
  "home",
  "target",
  "analytics",
  "template",
  "list",
  "grid",
  "profile",
  "more",
  "check",
  "delete",
  "edit",
  "cross",
  "menu",
  "eye",
  "search",
  "triangle",
  "clipboard",
  "link",
] as const;
export type IconNames = (typeof iconsKeys)[number];

const icons = ({
  color,
  size,
  fill,
}: {
  color: string;
  size: number;
  fill: string;
}): Record<IconNames, JSX.Element> => ({
  add: <FiPlus color={color} size={size} />,
  back: <FiChevronLeft color={color} size={size} />,
  exit: <FiLogOut color={color} size={size} />,
  settings: <FiSettings color={color} size={size} />,
  book: <FiBookOpen color={color} size={size} />,
  layers: <FiLayers color={color} size={size} />,
  home: <FiHome color={color} size={size} />,
  target: <FiTarget color={color} size={size} />,
  analytics: <FiTrendingUp color={color} size={size} />,
  template: <FiLayout color={color} size={size} />,
  list: <FiList color={color} size={size} />,
  grid: <FiGrid color={color} size={size} />,
  profile: <FiUser color={color} size={size} />,
  more: <FiMoreHorizontal color={color} size={size} />,
  check: <FiCheck color={color} size={size} />,
  delete: <FiTrash color={color} size={size} />,
  edit: <FiEdit2 color={color} size={size} />,
  cross: <FiX color={color} size={size} />,
  menu: <FiMenu color={color} size={size} />,
  eye: <FiEye color={color} size={size} />,
  search: <FiSearch color={color} size={size} />,
  triangle: <FiTriangle color={color} size={size} fill={fill} />,
  clipboard: <FiClipboard color={color} size={size} fill={fill} />,
  link: <FiLink2 color={color} size={size} fill={fill} />,
});

const defaultColor = "inherit";
const defaultSize = 24;

export const Icon = ({
  name,
  color = defaultColor,
  size = defaultSize,
  fill = "transparent",
}: IconComponentProps) => {
  const theme = useTheme();
  const iconColor = theme?.colors[color] ?? defaultColor;
  return icons({ color: iconColor, size, fill })[name];
};
