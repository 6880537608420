import { type SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "modules/onboarding/api/auth";
import {
  isFetchBaseQueryError,
  type ErrorDataMessage,
} from "services/api/rtkQueryError";
import { useAppDispatch } from "store";
import { setToken } from "modules/onboarding/data/authSlice";
import { useToastContext } from "context/toastContext";

interface useLoginProps {
  email: string;
  password: string;
}

export const useLogin = ({ email, password }: useLoginProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showToaster } = useToastContext();

  const [login, { error, isError, isLoading, data }] = useLoginUserMutation();

  const [errorMessage, setErrorMessage] = useState<ErrorDataMessage>();

  const loginUser = async (e: SyntheticEvent) => {
    e.preventDefault();
    await login({ email, password });
  };

  useEffect(() => {
    if (isError) {
      if (isFetchBaseQueryError(error) && error?.data) {
        const messages = Object.values(error.data);
        const err = error.data as ErrorDataMessage;
        setErrorMessage(err);
        showToaster({ text: messages[0], mode: "danger" });
      }
    }
  }, [isError, error]);

  useEffect(() => {
    if (data) {
      const token = data.access;
      const refresh = data.refresh;
      dispatch(setToken(token));
      localStorage.setItem("refresh_token", refresh);
      navigate("/dashboard");
    }
  }, [data, dispatch, navigate]);

  return { loginUser, isLoading, errorMessage };
};
