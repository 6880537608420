import { api } from "services/api/api";
import { type TemplateArgs, type TemplateDetail } from "./types";

export const TEMPLATES_TAG = "Templates";
export const TEMPLATE_DETAIL_TAG = "Templates";

export const templatesApi = api
  .enhanceEndpoints({ addTagTypes: [TEMPLATES_TAG, TEMPLATE_DETAIL_TAG] })
  .injectEndpoints({
    endpoints: (build) => ({
      getTemplates: build.query<TemplateDetail[], void>({
        query: () => ({
          method: "GET",
          url: "/templates/v1/",
        }),
        providesTags: [TEMPLATES_TAG],
      }),
      getTemplateDetail: build.query<TemplateDetail, { id: string }>({
        query: ({ id }) => ({
          method: "GET",
          url: "/templates/v1/" + id,
        }),
        providesTags: [TEMPLATE_DETAIL_TAG],
      }),
      createTemplate: build.mutation<TemplateDetail, TemplateArgs>({
        query: (args) => ({
          body: args,
          method: "POST",
          url: "/templates/v1/",
        }),
        invalidatesTags: [TEMPLATES_TAG],
      }),
      deleteTemplate: build.mutation<TemplateDetail, { id: string }>({
        query: ({ id }) => ({
          method: "DELETE",
          url: "/templates/v1/" + id,
        }),
        invalidatesTags: [TEMPLATES_TAG],
      }),
      updateTemplate: build.mutation<
        TemplateDetail,
        { id: string } & Partial<TemplateArgs>
      >({
        query: ({ id, ...args }) => ({
          body: args,
          method: "PATCH",
          url: "/templates/v1/" + id,
        }),
        invalidatesTags: [TEMPLATES_TAG, TEMPLATE_DETAIL_TAG],
      }),
    }),
  });

export const {
  useCreateTemplateMutation,
  useGetTemplateDetailQuery,
  useDeleteTemplateMutation,
  useGetTemplatesQuery,
  useUpdateTemplateMutation,
} = templatesApi;
