import { useOutletContext } from 'react-router-dom';
import { type ListContextType } from '../components/ListDetailsLayout';

import { Button } from 'components';

import { useCreateContact } from '../hooks/useCreateContact';
import { useListTagsQuery } from '../api/listsApi';
import { CardContainer } from 'components/CardContainer';
import { Dropdown, Input } from 'components/Forms';
import { BaseToggle } from 'components/Toggle';
import styled from 'styled-components';

export const CreateContact = () => {
	const { listId } = useOutletContext<ListContextType>();

	const {
		addNewContact,
		isLoading,
		onValueChange,
		values,
		onDropdownChange,
		onIsSubscribedPress,
	} = useCreateContact(listId);

	const { data: tags } = useListTagsQuery();

	return (
		<CardContainer>
			<StyledForm>
				<Input
					label="Email"
					type="email"
					value={values.email}
					placeholder="Email"
					name="email"
					onChange={onValueChange}
				/>
				<Input
					value={values.first_name}
					placeholder="First name"
					label="First name"
					name="first_name"
					onChange={onValueChange}
				/>
				<Input
					value={values.last_name}
					placeholder="Last name"
					label="Last name"
					name="last_name"
					onChange={onValueChange}
				/>

				<Dropdown
					label="Tags"
					options={
						tags?.map((item) => ({ label: item.name, value: item.id })) || []
					}
					onValueChange={(newValue) => onDropdownChange('tags', newValue)}
					isMulti
				/>
				<BaseToggle
					label="Is subscribed"
					onChange={onIsSubscribedPress}
					isChecked={values.is_subscribed}
				/>
				<Button
					type="submit"
					onClick={addNewContact}
					text="Add new subscriber"
					loading={isLoading}
				/>
			</StyledForm>
		</CardContainer>
	);
};

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;
