import { useNavigate } from "react-router-dom";
import {
  useDeleteTemplateMutation,
  useGetTemplateDetailQuery,
  useUpdateTemplateMutation,
} from "../api/templatesApi";
import { type TemplateArgs } from "../api/types";
import { useEffect } from "react";
import { useToastContext } from "context/toastContext";

export const useTemplateDetails = (templateId: string) => {
  const navigate = useNavigate();
  const { showToaster } = useToastContext();
  const { data, isLoading: isFetchingData } = useGetTemplateDetailQuery({
    id: templateId,
  });
  const [
    deleteTemplate,
    { isLoading: loadingDelete, isSuccess: deleteSuccess },
  ] = useDeleteTemplateMutation();
  const [
    updateTemplate,
    { isLoading: loadingUpdate, isSuccess: updateSuccess },
  ] = useUpdateTemplateMutation();

  const onDeleteTemplate = async () => {
    await deleteTemplate({ id: templateId });
    navigate("/templates");
  };

  const onUpdateTemplate = async (args: Partial<TemplateArgs>) => {
    await updateTemplate({ id: templateId, ...args });
    navigate("/templates");
  };

  useEffect(() => {
    if (updateSuccess) {
      showToaster({ text: "Template was updated!" });
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      showToaster({ text: "Template was deleted." });
    }
  }, [deleteSuccess]);

  const isLoading = isFetchingData || loadingDelete || loadingUpdate;

  return {
    data,
    isLoading,
    onDeleteTemplate,
    onUpdateTemplate,
  };
};
