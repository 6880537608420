import { type SyntheticEvent } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { Label } from "./Forms/sharedStyled";

export type OnDateChange = (date: Date, e: SyntheticEvent) => void;

interface DateTimePickerProps {
  onDateChange: OnDateChange;
  value: Date;
  label?: string;
}

export const DateTimePicker = ({
  onDateChange,
  value,
  label,
}: DateTimePickerProps) => {
  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <StyledDatePicker
        selected={value}
        onChange={onDateChange}
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mm aa"
        minDate={new Date()}
        filterTime={filterPassedTime}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 1rem;
  & .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.main};
  }

  &
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${({ theme }) => theme.colors.main};
  }

  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: ${({ theme }) => theme.colors.main};
    opacity: 0.8;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 0.8rem 1rem;
  border-radius: ${({ theme }) => theme.dimensions.radius};
  cursor: pointer;
  width: 20rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;

  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.colors.main};
    outline: none;
  }
`;
