import { Icon } from "assets/icons";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

interface BackLinkProps { text: string; to: string }

export const BackLink = ({ text, to }: BackLinkProps) => {
  return (
    <Container>
      <StyledLink to={to}>
        <Icon name="back" size={18} />
        {text}
      </StyledLink>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 1rem;
`;
const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
`;
