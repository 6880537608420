import { type ButtonHTMLAttributes } from "react";
import { type LinkProps, Link as RouterLink } from "react-router-dom";
import { css, styled } from "styled-components";
import { Spinner } from "./Spinner";
import { Icon, type IconNames } from "assets/icons";

type ButtonVariant = "cta" | "primary" | "secondary" | "tertiary" | "danger";
type Mode = "dark" | "light";

interface CommonProps {
  variant?: ButtonVariant;
  mode?: Mode;
  text: string;
  isFullWidth?: boolean;
  loading?: boolean;
  icon?: IconNames;
}

export const Button = ({
  onClick,
  text,
  variant = "primary",
  mode = "dark",
  loading,
  isFullWidth,
  icon,
  ...props
}: CommonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <ContainerButton
      disabled={loading}
      onClick={onClick}
      variant={variant}
      mode={mode}
      {...props}
      isFullWidth={isFullWidth}
    >
      {(icon && !loading) ? <Icon name={icon} size={18} /> : null}
      <TextWrapper loading={loading}>{text}</TextWrapper>
      {loading && <StyledSpinner />}
    </ContainerButton>
  );
};

export const PressLink = ({
  loading,
  text,
  variant = "primary",
  mode = "dark",
  isFullWidth,
  to,
  icon,
  ...props
}: CommonProps & LinkProps) => {
  return (
    <ContainerLink
      to={to}
      variant={variant}
      mode={mode}
      isFullWidth={isFullWidth}
      {...props}
    >
      {icon && !loading && <Icon name={icon} size={18} />}
      <TextWrapper loading={loading}>{text}</TextWrapper>
    </ContainerLink>
  );
};

const dangerStyle = css<{ mode?: Mode }>`
  background: ${({ theme }) => theme.colors.error};
  &:hover:not([disabled]) {
    opacity: 0.8;
  }
`;

const primaryStyle = css<{ mode?: Mode }>`
  background: ${({ theme }) => theme.colors.primary};
  &:hover:not([disabled]) {
    background: ${({ theme }) => theme.colors.main};
    opacity: 0.8;
  }
`;
const secondaryStyle = css<{ mode?: Mode }>`
  background: none;
  color: ${({ theme }) => theme.colors.white};
  &:hover:not([disabled]) {
    color: ${({ theme }) => theme.colors.main};
  }
`;
const tertiaryStyle = css<{ mode?: Mode }>`
  background: none;
  border: 1px solid
    ${({ theme, mode }) => theme.colors[mode === "dark" ? "white" : "main"]};
  color: ${({ theme, mode }) =>
    theme.colors[mode === "dark" ? "white" : "main"]};
  &:hover:not([disabled]) {
    color: ${({ theme, mode }) =>
    theme.colors[mode === "dark" ? "main" : "secondary"]};
    border: 1px solid
      ${({ theme, mode }) =>
    theme.colors[mode === "dark" ? "main" : "secondary"]};
  }
`;

const ctaStyle = css`
  background: ${({ theme }) => theme.colors.main};
  border: 2px solid ${({ theme }) => theme.colors.main};
  font-size: 1.2rem;
  &:hover:not([disabled]) {
    background: transparent;
    color: ${({ theme }) => theme.colors.main};
  }
`;

const ContainerStyle = css<{
  disabled?: boolean;
  variant?: ButtonVariant;
  mode?: Mode;
  isFullWidth?: boolean;
}>`
  padding: 0.7rem 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.dimensions.radius};
  border: none;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "auto")};
  cursor: pointer;
  font-size: 0.9rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  ${({ variant }) => {
    switch (variant) {
      case "primary":
        return primaryStyle;
      case "secondary":
        return secondaryStyle;
      case "tertiary":
        return tertiaryStyle;
      case "cta":
        return ctaStyle;
      case "danger":
        return dangerStyle;
      default:
        return null;
    }
  }};
`;

const ContainerButton = styled.button<{
  variant?: ButtonVariant;
  mode?: Mode;
  isFullWidth?: boolean;
}>`
  ${ContainerStyle}
`;

const ContainerLink = styled(RouterLink) <{
  variant?: ButtonVariant;
  mode?: Mode;
  isFullWidth?: boolean;
}>`
  ${ContainerStyle}
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
`;

const TextWrapper = styled.div<{ loading?: boolean }>`
  opacity: ${({ loading }) => (loading ? 0 : 1)};
`;
