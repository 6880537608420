import { type EditorRefType, TemplateEditor } from "components/TemplateEditor";
import {
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
} from "modules/templates/api/templatesApi";
import { type TemplateDetail } from "modules/templates/api/types";
import { TemplateCard } from "modules/templates/components/TemplateCard";
import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useCampaignContext } from "../context/campaignContext";
import { EmptyState } from "components/EmptyState";

export const SelectTemplate = () => {
  const { templates, details, onTemplateCreate, setCurrentStep, campaignId } =
    useCampaignContext();
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateDetail>();
  const [createTemplate, { isLoading: loadingCreate, isSuccess, data }] =
    useCreateTemplateMutation();
  const [updateTemplate, { isLoading: loadingUpdate }] =
    useUpdateTemplateMutation();

  useEffect(() => {
    if (isSuccess && data) {
      onTemplateCreate(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (details.template) {
      setSelectedTemplate(details.template);
    }
  }, [details.template]);

  if (!templates) return null;

  const onReady = (emailEditorRef: EditorRefType) => {
    if (!selectedTemplate) return;
    const templateJson = JSON.parse(selectedTemplate.design);
    emailEditorRef.current?.editor?.loadDesign(templateJson);
  };

  const saveTemplate = (emailEditorRef: EditorRefType) => {
    emailEditorRef.current?.editor?.exportHtml(async (data) => {
      const { design, html } = data;
      if (details.template) {
        await updateTemplate({
          id: details.template.id,
          design: JSON.stringify(design),
          html,
        });
        setCurrentStep(2);
      } else {
        if (!campaignId) {
          return;
        }
        await createTemplate({
          design: JSON.stringify(design),
          html,
          name: details.name,
          campaign: campaignId,
        });
      }
    });
  };

  return (
    <Container>
      {!selectedTemplate ? (
        <TemplatesOptions
          templates={templates}
          setSelectedTemplate={setSelectedTemplate}
        />
      ) : (
        <>
          <TemplateEditor
            onReady={onReady}
            onSubmit={saveTemplate}
            buttonText="Save & next"
            isLoading={loadingCreate || loadingUpdate}
          />
        </>
      )}
    </Container>
  );
};

interface TemplatesOptionsProps {
  templates: TemplateDetail[];
  setSelectedTemplate: (template: TemplateDetail) => void;
}

const TemplatesOptions = ({
  templates,
  setSelectedTemplate,
}: TemplatesOptionsProps) => {
  return (
    <>
      <h6>Select a template</h6>

      {(templates.length > 0) ? (
        <CardsContainer>
          {templates.map((template) => (
            <TemplateCard
              key={template.id}
              template={template}
              editDisabled
              mode="dark"
              onSelect={() => { setSelectedTemplate(template); }}
            />
          ))}
        </CardsContainer>
      ) : (
        <EmptyState
          text="You don't have templates created"
          link={{ to: "/templates/create", text: "Create new template" }}
        />
      )}
    </>
  );
};

const Container = styled.div`
  overflow-y: scroll;
`;

const CardsContainer = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    grid-template-columns: 1fr;
  }
`;
