import styled from "styled-components";
import { SmallText } from "theme/typography";

export const Footer = () => {
  return (
    <Container>
      <SmallText color="white" center>
        © TargetEmailPro {new Date().getFullYear()}
      </SmallText>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem;
  margin-top: 5rem;
`;
