import { Input } from "components/Forms";
import { AuthLayout } from "../components/AuthLayout";
import { useResetPasswordMutation } from "../api/auth";
import { type SyntheticEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "components/Button";
import { useToastContext } from "context/toastContext";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [reset, { isLoading, isSuccess }] = useResetPasswordMutation();
  const [email, setEmail] = useState("");
  const { showToaster } = useToastContext();

  const onResetPassword = async (e: SyntheticEvent) => {
    e.preventDefault();
    await reset({ email });
  };

  useEffect(() => {
    if (isSuccess) {
      showToaster({ text: "An email was sent to your inbox" });
      navigate("/");
    }
  }, [isSuccess]);

  return (
    <AuthLayout title="Forgot password" onSubmit={onResetPassword} to="/login">
      <Input
        type="email"
        label="Email address"
        placeholder="Enter email"
        value={email}
        onChange={(e) => { setEmail(e.target.value); }}
        // error={errorMessage?.email}
      />
      <StyledButton
        variant="primary"
        disabled={isLoading}
        text="Reset password"
        isFullWidth
        loading={isLoading}
      />
    </AuthLayout>
  );
};

const StyledButton = styled(Button)`
  margin-top: 2rem;
`;
