import { styled } from "styled-components";
import { type TemplateDetail } from "../api/types";
import { Button, PressLink } from "components/Button";
import { useModalContext } from "context/modalContext";
import { useTemplateDetails } from "../hooks/useTemplateDetails";
import { formattedDate } from "utils/formattedDate";
import { usePreviewTemplate } from "../hooks/usePreviewTemplate";
import { DeleteModal } from "components/DeleteModal";

type CardMode = "dark" | "light";

interface TemplateCardProps {
  template: TemplateDetail;
  editDisabled?: boolean;
  onSelect?: () => void;
  mode?: CardMode;
}

export const TemplateCard = ({
  template,
  editDisabled,
  onSelect,
  mode = "light",
}: TemplateCardProps) => {
  const { showModal, onModalClose } = useModalContext();
  const { onDeleteTemplate, isLoading } = useTemplateDetails(template.id);
  const { onShowTemplate } = usePreviewTemplate({ template });

  const onDelete = async () => {
    await onDeleteTemplate();
    onModalClose();
  };

  const onDeletePress = () => {
    showModal(
      <DeleteModal
        onDelete={onDelete}
        onCancel={onModalClose}
        isLoading={isLoading}
        item={template.name}
      />
    );
  };

  return (
    <Container mode={mode}>
      <Image src={template.image ?? undefined} />
      <Name mode={mode}>{template.name}</Name>
      <Edited mode={mode}>
        {"Edit " + formattedDate(new Date(template.modified))}
      </Edited>
      <ButtonWrapper>
        <Button
          icon="eye"
          onClick={onShowTemplate}
          text="View"
          isFullWidth
          variant={mode === "dark" ? "tertiary" : "primary"}
        />
        {onSelect && (
          <Button
            icon="check"
            onClick={onSelect}
            text="Select"
            isFullWidth
            variant={mode === "dark" ? "tertiary" : "primary"}
          />
        )}
        {!editDisabled && (
          <ButtonsBottom>
            <PressLink icon="edit" to={template.id} text="Edit" isFullWidth />
            <Button
              icon="delete"
              text="Delete"
              onClick={onDeletePress}
              isFullWidth
              variant="danger"
            />
          </ButtonsBottom>
        )}
      </ButtonWrapper>
    </Container>
  );
};

const ButtonsBottom = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Container = styled.div<{ mode: CardMode }>`
  background: ${({ theme, mode }) =>
    theme.colors[mode === "light" ? "white" : "primary"]};
  border-radius: ${({ theme }) => theme.dimensions.radius};
  padding: 1rem;
`;
const Image = styled.img`
  width: 100%;
  margin-bottom: 1rem;
  border-radius: ${({ theme }) => theme.dimensions.radius};
`;

const Name = styled.p<{ mode: CardMode }>`
  text-align: center;
  margin-bottom: 1rem;
  color: ${({ theme, mode }) =>
    theme.colors[mode === "light" ? "primary" : "white"]};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Edited = styled.p<{ mode: CardMode }>`
  font-size: 0.7rem;
  text-align: center;
  margin-bottom: 0.5rem;
  color: ${({ theme, mode }) =>
    theme.colors[mode === "light" ? "primary" : "white"]};
`;
