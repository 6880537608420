import { Icon } from 'assets/icons';
import { useModalContext } from 'context/modalContext';
import { styled } from 'styled-components';

export const Modal = () => {
	const { isVisible, onModalClose, content } = useModalContext();

	return (
		<BackgroundLayer isVisible={isVisible}>
			<Container isVisible={isVisible}>
				<ButtonPress onClick={onModalClose}>
					<Icon name="cross" />
				</ButtonPress>
				{content}
			</Container>
		</BackgroundLayer>
	);
};

const ButtonPress = styled.button`
	padding: 0.2rem;
	align-self: flex-end;
	cursor: pointer;
`;

const Container = styled.div<{ isVisible: boolean }>`
	position: fixed;
	padding: 1rem;
	background: ${({ theme }) => theme.colors.white};
	border-radius: ${({ theme }) => theme.dimensions.radius};
	transform: translateY(${({ isVisible }) => (isVisible ? 0 : '20rem')});
	transition: 0.5s;
	display: flex;
	flex-direction: column;
	margin: 1rem;
`;

const BackgroundLayer = styled.div<{ isVisible: boolean }>`
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.5s;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	z-index: ${({ isVisible }) => (isVisible ? 3 : -1)};
`;
