import { Button } from 'components';
import { useGetAccountDetailsOptionsQuery } from '../api/settingsApi';

import { useDetailsAccount } from '../hooks/useDetailsAccount';
import { CardContainer } from 'components/CardContainer';
import { FullScreenSpinner } from 'components/Spinner';
import { Input } from 'components/Forms';
import {
	type SingleType,
	type Option,
	Dropdown,
} from 'components/Forms/Dropdown';
import styled from 'styled-components';

export const AccountDetails = () => {
	const {
		accountDetails,
		onChange,
		saveAccountDetails,
		onDropdownChange,
		isLoading,
	} = useDetailsAccount();
	const { data, isLoading: isLoadingOptions } =
		useGetAccountDetailsOptionsQuery();

	const loading = isLoading || isLoadingOptions;
	if (loading) return <FullScreenSpinner />;

	const { company_name, industry, website, referrer } = accountDetails;

	const industryOptions: Option[] =
		data?.actions.POST?.industry?.choices?.map((item) => ({
			label: item.display_name,
			value: item.value,
		})) || [];

	return (
		<CardContainer>
			<StyledForm>
				<Input
					label="Company name"
					value={company_name}
					name="company_name"
					onChange={onChange}
				/>
				<Input
					label="Website"
					value={website}
					name="website"
					onChange={onChange}
				/>
				<Dropdown
					options={industryOptions}
					label="Industry"
					placeholder="Industry"
					onValueChange={(newValue) =>
						onDropdownChange('industry', newValue as SingleType)
					}
					value={industryOptions.filter((item) => item.value === industry)}
				/>
				<Input
					label="How did you hear about us?"
					value={referrer || ''}
					name="referrer"
					onChange={onChange}
				/>
				<Button type="submit" onClick={saveAccountDetails} text="Save" />
			</StyledForm>
		</CardContainer>
	);
};

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;
