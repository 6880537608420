export interface MetricsType {
  click_through_rate: number;
  open_rate: number;
  bounce_rate: number;
  unsubscribe_rate: number;
  spam_complaint_rate: number;
  unique_open_rate: number;
  click_to_open_rate: number;
}

export type MetricsKeys = keyof MetricsType;

export type AnalyticsEventType =
  | "opened"
  | "clicked"
  | "complained"
  | "delivered"
  | "permanent_fail"
  | "temporary_fail"
  | "unsubscribed";

export interface HistoryType {
  id: string;
  recipient: string;
  recipient_domain: string;
  timestamp: string;
  event: AnalyticsEventType;
  analytics: string;
}

interface Campaign {
  created: Date;
  release_date: string;
  name: string;
  template: {
    name: string;
    image: string;
  };
}

export interface AnalyticsType {
  id: string;
  campaign: Campaign;
  metrics: MetricsType;
  clicked: number;
  complained: number;
  delivered: number;
  opened: number;
  permanent_fail: number;
  temporary_fail: number;
  unsubscribed: number;
  accepted: number;
}

export const chartEvents = [
  "accepted",
  "clicked",
  "delivered",
  "complained",
  "opened",
  "permanent",
  "temporary",
  "unsubscribed",
] as const;

export type ChartEventsType = (typeof chartEvents)[number];

export type ChartData = {
  [key in ChartEventsType]: number[];
} & { range: string[] };
