import { useGetListsQuery } from '../api/listsApi';
import { ListRow } from '../components/ListRow';
import { Spinner } from 'components/Spinner';

import { HeaderLayout } from 'components/HeaderLayout';
import { CardContainer } from 'components/CardContainer';
import { EmptyState } from 'components/EmptyState';
import { useState } from 'react';
import { SearchBar } from 'components/SearchBar';
import { useSearchValue } from 'hooks/useSearchValue';
import { FilterWrapper } from 'modules/campaigns/components/Filter';

export const Lists = () => {
	const [search, setSearch] = useState('');
	const { searchValue } = useSearchValue({ search });
	const { data, isLoading } = useGetListsQuery({ search: searchValue });

	if (isLoading) return <Spinner />;

	return (
		<HeaderLayout
			title="My lists"
			rightButton={{
				to: 'create',
				text: 'Add new list',
				icon: 'add',
			}}
		>
			<FilterWrapper>
				<SearchBar
					searchValue={search}
					onSearchChange={(e) => {
						setSearch(e.target.value);
					}}
					placeholder="Search a list"
				/>
			</FilterWrapper>
			{data?.length ? (
				data?.map((item) => <ListRow key={item.id} list={item} />)
			) : (
				<CardContainer center>
					<EmptyState
						text="No lists yet"
						link={{ to: 'create', text: 'Create new list' }}
						type="lists"
					/>
				</CardContainer>
			)}
		</HeaderLayout>
	);
};
