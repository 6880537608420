import styled, { css } from "styled-components";

export const CardContainer = styled.div<{ center?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.dimensions.radius};
  ${({ center }) =>
    center &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `};
`;
