import { BlurBall } from 'components/BlurBall';
import { PressLink } from 'components/Button';
import styled from 'styled-components';
import { Header, P1Regular } from 'theme/typography';

export const CTA = () => {
	return (
		<Container>
			<BlurBall
				width="40rem"
				height="50rem"
				bottom="0"
				left="-5rem"
				animation
			/>
			<BlurBall
				width="30rem"
				height="30rem"
				bottom="-14rem"
				right="-4rem"
				animation
			/>
			<InfoWrapper>
				<Header center color="white">
					Start your 30 days free trial
				</Header>
				<P1Regular center color="white">
					Experience the power of our email marketing automation software
					firsthand. Sign up for a free trial today and see how we can help you
					supercharge your marketing efforts.
				</P1Regular>
				<StyledPressLink text="Start for free" to="/register" variant="cta" />
			</InfoWrapper>
		</Container>
	);
};

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	z-index: 1;
	position: relative;
`;

const Container = styled.div`
	padding: 5rem 10rem;
	border-radius: ${({ theme }) => theme.dimensions.radius};
	position: relative;
	overflow: hidden;
	border: 1px solid ${({ theme }) => theme.colors.grey10};
	box-shadow: 0 0 1rem ${({ theme }) => theme.colors.main};
	border: 0.5px solid ${({ theme }) => theme.colors.main};
	@media (max-width: ${({ theme }) => theme.breakpoints.M}) {
		padding: 3rem;
	}
	@media (max-width: ${({ theme }) => theme.breakpoints.S}) {
		padding: 2rem;
	}
`;

const StyledPressLink = styled(PressLink)`
	margin-top: 1rem;
	align-self: center;
`;
