import { styled } from "styled-components";
import { Icon } from "assets/icons";
import { useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import {
  useDeleteContactByIdMutation,
  useListTagsQuery,
} from "../api/listsApi";
import { useToastContext } from "context/toastContext";
import { type ListContextType } from "./ListDetailsLayout";
import { CardContainer } from "components/CardContainer";
import { EmptyState } from "components/EmptyState";
import { PressLink } from "components/Button";
import { useModalContext } from "context/modalContext";
import { DeleteModal } from "components/DeleteModal";
import { Table } from "components/Table";
import { SearchBar } from "components/SearchBar";
import { SortButton } from "components/SortButton";
import { type SortFields } from "../hooks/useListDetails";
import { Filter, FilterMultiple } from "modules/campaigns/components/Filter";

const visibleOptions: Record<string, { text: string; hasSort?: boolean }> = {
  email: { text: "E-mail", hasSort: true },
  first_name: { text: "First name", hasSort: true },
  last_name: { text: "Last name", hasSort: true },
  tags: { text: "Tags" },
  is_subscribed: { text: "Status" },
};

const visibleOptionsArr = Object.keys(visibleOptions);

export const Subscribers = () => {
  const { showToaster } = useToastContext();
  const [deleteContact] = useDeleteContactByIdMutation();
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const {
    refetchList,
    onSortPress,
    setSearch,
    search,
    subscribers,
    sort,
    changeFilter,
    isSubscribed,
    setTagsFilter,
  } = useOutletContext<ListContextType>();
  const { showModal } = useModalContext();
  const { data } = useListTagsQuery();

  if (!subscribers) return <p>No contacts</p>;

  const onCheckboxPress = (id: string) => {
    const emailsId = [...selectedEmails];
    if (selectedEmails.includes(id)) {
      const index = emailsId.indexOf(id);
      emailsId.splice(index, 1);
      setSelectedEmails(emailsId);
      return;
    }
    emailsId.push(id);
    setSelectedEmails(emailsId);
  };

  const onSelectAll = () => {
    if (selectedEmails.length > 0) {
      setSelectedEmails([]);
      return;
    }
    const arr = subscribers.map(({ id }) => id);
    setSelectedEmails(arr);
  };

  const getPopupText = (num: number) => {
    if (num === 1) return `${num} contact was deleted`;
    return `${num} contacts were deleted`;
  };

  const onDelete = () => {
    showModal(
      <DeleteModal
        onDelete={onDeletePress}
        item={`Delete ${selectedEmails.length} subscribers.`}
      />
    );
  };

  const onDeletePress = async () => {
    const promises = [];

    for (const id of selectedEmails) {
      promises.push(await deleteContact({ contactId: id }));
    }

    try {
      await Promise.all(promises);
      await refetchList();
      showToaster({ text: getPopupText(promises.length) });
    } catch (err) {
      showToaster({ text: "Something went wrong", mode: "danger" });
    }
    setSelectedEmails([]);
  };

  return (
    <>
      <FilterWrapper>
        <SearchBar
          searchValue={search}
          onSearchChange={(e) => { setSearch(e.target.value); }}
          placeholder="Search a subscriber"
        />
        <Filter
          options={[
            { key: "all", text: "All" },
            { key: "subscribed", text: "Subscribed" },
            { key: "unsubscribed", text: "Unsubscribed" },
          ]}
          selected={
            isSubscribed
              ? "subscribed"
              : typeof isSubscribed === "undefined"
                ? "all"
                : "unsubscribed"
          }
          onClick={(key) => {
            changeFilter(
              "status",
              key === "all" ? undefined : key === "subscribed"
            );
          }
          }
        />
        <FilterMultiple
          options={
            data?.map((item) => ({ value: item.id, label: item.name })) ?? []
          }
          onChange={(value) => { setTagsFilter(value.map((item) => item.value)); }}
        />
      </FilterWrapper>
      <Container>
        {subscribers.length !== 0 ? (
          <Table>
            <thead>
              <tr>
                <th className="fixed-column">
                  <CheckContainer onClick={onSelectAll}>
                    {selectedEmails.length === subscribers.length && (
                      <Icon name="check" color="white" size={15} />
                    )}
                  </CheckContainer>
                </th>
                {visibleOptionsArr.map((option, index) => (
                  <th key={option} className={index === 0 ? "fixed-column" : undefined}>
                    <HeaderWrapper>
                      {visibleOptions[option].text}
                      {visibleOptions[option].hasSort && (
                        <SortButton
                          onClick={() => { onSortPress(option as SortFields); }}
                          sort={
                            sort.field === option ? sort.ordering : undefined
                          }
                        />
                      )}
                    </HeaderWrapper>
                  </th>
                ))}
                <th>
                  <DeleteContainer
                    disabled={selectedEmails.length === 0}
                    onClick={onDelete}
                    isActive={Boolean(selectedEmails.length)}
                  >
                    <Icon name="delete" color="error" size={20} />
                  </DeleteContainer>
                </th>
              </tr>
            </thead>
            <tbody>
              {subscribers.map((contact) => (
                <tr key={contact.id}>
                  <td className="fixed-column">
                    <CheckContainer onClick={() => { onCheckboxPress(contact.id); }}>
                      {selectedEmails.includes(contact.id) && (
                        <Icon name="check" color="black" size={15} />
                      )}
                    </CheckContainer>
                  </td>
                  <td className="fixed-column">{contact.email}</td>
                  <td>{contact.first_name}</td>
                  <td>{contact.last_name}</td>
                  <td>
                    <TagsWrapper>
                      {contact.tags.map((tag) => (
                        <StyledTag key={tag}>{tag}</StyledTag>
                      ))}
                    </TagsWrapper>
                  </td>
                  <td>
                    <StatusContainer isSubscribed={contact.is_subscribed}>
                      {contact.is_subscribed ? "Subscribed" : "Unsubscribed"}
                    </StatusContainer>
                  </td>
                  <td>
                    <EditContainer to={contact.id}>
                      <Icon name="edit" size={18} color="primary" />
                    </EditContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <NoContacts />
        )}
      </Container>
    </>
  );
};

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const NoContacts = () => {
  return (
    <CardContainer center>
      <EmptyState text="No contacts yet" type="contacts" />
      <PressLink text="Add new contact" to="create" />
    </CardContainer>
  );
};

const EditContainer = styled(Link)`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

const DeleteContainer = styled.button<{ isActive: boolean }>`
  cursor: ${({ isActive }) => (isActive ? "pointer" : "not-allowed")};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  transition: 0.5s;
  background: none;
  border: none;
  width: 100%;
`;

const CheckContainer = styled.div<{ isHeader?: boolean }>`
  border: 1px solid
    ${({ isHeader, theme }) => theme.colors[isHeader ? "white" : "primary"]};
  width: 1.1rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.1rem;
  cursor: pointer;
`;

const TagsWrapper = styled.div`
  gap: 0.2rem;
  display: flex;
`;
const StyledTag = styled.div`
  border-radius: ${({ theme }) => theme.dimensions.radius};
  background: ${({ theme }) => theme.colors.secondary};
  color: white;
  padding: 0.3rem 0.4rem;
  font-size: 0.7rem;
`;

const Container = styled.div`
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.dimensions.radius};
  overflow-x: auto;
  max-width: 100%;
`;

const StatusContainer = styled.div<{ isSubscribed?: boolean }>`
  padding: 0.4rem;
  background: ${({ theme, isSubscribed }) =>
    theme.colors[isSubscribed ? "main" : "grey10"]};
  border-radius: ${({ theme }) => theme.dimensions.radius};
  text-align: center;
  color: ${({ theme, isSubscribed }) =>
    theme.colors[isSubscribed ? "white" : "black"]};
  border: 1px solid
    ${({ theme, isSubscribed }) =>
    theme.colors[isSubscribed ? "main" : "black"]};
`;
