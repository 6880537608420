import { Button } from 'components';
import styled from 'styled-components';
import { Header, SmallText } from 'theme/typography';

interface FreeDomainsWarningProps {
	domain: string;
	onCancel: () => void;
	onIgnore: () => void;
}

export const FreeDomainsWarning = ({
	domain,
	onCancel,
	onIgnore,
}: FreeDomainsWarningProps) => {
	return (
		<Container>
			<StyledHeader>Caution: Potential email delivery issues</StyledHeader>
			<Wrapper>
				<SmallText>
					It has come to our attention that emails originating from a free email
					address, such as <b>{domain}</b>, may encounter obstacles, including
					potential blocking or redirection to spam folders.
				</SmallText>
				<SmallText>
					For optimal deliverability, we strongly advise sending emails from a
					domain under your ownership whenever feasible.
				</SmallText>
			</Wrapper>
			<ButtonWrapper>
				<Button
					text="Cancel"
					variant="tertiary"
					mode="light"
					onClick={onCancel}
				/>
				<Button text="Ignore" onClick={onIgnore} />
			</ButtonWrapper>
		</Container>
	);
};

const StyledHeader = styled(Header)`
	margin-bottom: 1rem;
`;

const Container = styled.div`
	text-align: center;
	max-width: 30rem;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: center;
	margin-top: 1rem;
`;
