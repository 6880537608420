import styled from "styled-components";
import { LandingSections } from "../components/LandingSections";
import { RadialLanding } from "../components/RadialLanding";
import { CTA } from "../components/CTA";

export const Landing = () => {
  return (
    <LandingContainer>
      <RadialLanding />
      <LandingSections />
      <CTA />
    </LandingContainer>
  );
};

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8rem;
`;
