import { FullScreenSpinner } from "components/Spinner";
import { useState } from "react";
import { type EditorRef } from "react-email-editor";
import { useCreateTemplateMutation } from "../api/templatesApi";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "context/toastContext";
import { Input } from "components/Forms";
import { CardContainer } from "components/CardContainer";
import { TemplateEditor } from "components/TemplateEditor";
import { HeaderLayout } from "components/HeaderLayout";

export const CreateTemplate = () => {
  const { showToaster } = useToastContext();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [createTemplate, { isLoading: loadingCreate }] =
    useCreateTemplateMutation();
  const [name, setName] = useState("");

  const onSubmit = (emailEditorRef: React.RefObject<EditorRef>) => {
    if (!name) {
      showToaster({
        text: "Please don't forget to name this template 😃",
        mode: "danger",
      }); return;
    }

    emailEditorRef?.current?.editor?.exportHtml(async (data) => {
      const { design, html } = data;

      await createTemplate({
        design: JSON.stringify(design),
        html,
        name,
      });
      showToaster({
        text: "New templated added! 🎉",
      });
      navigate("/templates");
    });
  };

  if (loadingCreate) return <FullScreenSpinner />;

  return (
    <HeaderLayout title="Create template">
      <CardContainer>
        <Input
          value={name}
          placeholder="Enter template name"
          onChange={(e) => { setName(e.target.value); }}
          label="Template name"
        />
        <TemplateEditor
          onReady={() => { setIsLoading(false); }}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      </CardContainer>
    </HeaderLayout>
  );
};
