import { Line } from "react-chartjs-2";

import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Chart,
  type ChartData,
  type ChartDataset,
  Legend,
  Title,
  Tooltip,
  type ChartOptions,
} from "chart.js";
import { useGetChartDataQuery } from "modules/analytics/api/analyticsApi";
import { Spinner } from "components/Spinner";
import { type ChartEventsType, chartEvents } from "modules/analytics/api/types";

export const LineChart = () => {
  // const [beforeDays, setBeforeDays] = useState(7);
  const { data, isLoading } = useGetChartDataQuery({ days: 7 });
  Chart.register([
    CategoryScale,
    PointElement,
    LinearScale,
    LineElement,
    Legend,
    Title,
    Tooltip,
  ]);

  if (isLoading) return <Spinner color="main" />;
  if (!data) return <p>No data</p>;

  const getLineStyle = (color?: string) => {
    const style: Partial<ChartDataset<"line", number[]>> = {
      fill: false,
      lineTension: 0.1,
      backgroundColor: color,
      borderColor: color,
      borderCapStyle: "round",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: color,
      pointBackgroundColor: color,
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 3,
      pointHitRadius: 10,
    };
    return style
  };

  const lineColors: Record<ChartEventsType, { color: string; text: string }> = {
    accepted: { color: "#8DDFCB", text: "Accepted" },
    clicked: { color: "#EFB495", text: "Clicked" },
    complained: { color: "#EF9595", text: "Complained" },
    delivered: { color: "#116A7B", text: "Delivered" },
    opened: { color: "#CBB279", text: "Opened" },
    permanent: { color: "#FD8A8A", text: "Permanent" },
    temporary: { color: "#BA94D1", text: "Temporary" },
    unsubscribed: { color: "#9E7676", text: "Unsubscribed" },
  };

  const datasets = Object.entries(data)
    .filter((item) => chartEvents.includes(item[0] as ChartEventsType))
    .map((item) => {
      const event = item[0] as ChartEventsType;
      const values = item[1];

      return {
        label: lineColors[event].text,
        ...getLineStyle(lineColors[event].color),
        data: values,
      };
    }) as ChartData<"line", number[], string>["datasets"];

  const lineData: ChartData<"line", number[], string> = {
    labels: data.range,
    datasets,
  };

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          labelTextColor: ({ dataset }) => {
            const color = dataset.backgroundColor;
            return color;
          },
        },
      },
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "Activity: Last 7 days",
      },
    },
    scales: {
      y: { suggestedMin: 0, suggestedMax: 5 },
    },
    scale: {
      ticks: {
        precision: 0,
      },
    },
  };

  return <Line data={lineData} options={options} />;
};
