import { Icon } from "assets/icons";
import { type ToasterMode, useToastContext } from "context/toastContext";
import styled from "styled-components";

export const Toast = () => {
  const { text, isVisible, mode } = useToastContext();

  return (
    <Container>
      <ToastContainer isVisible={isVisible} mode={mode}>
        <IconWrapper>
          <Icon name={mode === "success" ? "check" : "cross"} size={10} />
        </IconWrapper>
        <Text>{text}</Text>
      </ToastContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Text = styled.p`
  margin-left: 0.5rem;
`;

const IconWrapper = styled.div`
  border-radius: 100px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ToastContainer = styled.div<{ isVisible?: boolean; mode: ToasterMode }>`
  border-radius: ${({ theme }) => theme.dimensions.radius};
  padding: 0.5rem 1rem;
  background-color: ${({ theme, mode }) =>
    theme.colors[mode === "success" ? "success" : "error"]};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 3;
  transform: translateY(${({ isVisible }) => (isVisible ? "1rem" : "-5rem")});
  transition: 0.5s;
  margin: 1rem;
`;
