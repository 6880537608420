import { Button } from "components";
import { Spinner } from "components/Spinner";
import { useNavigate } from "react-router-dom";
import { CardContainer } from "components/CardContainer";
import styled from "styled-components";
import { type Option } from "components/Forms/Dropdown";
import { HeaderLayout } from "components/HeaderLayout";
import { Steps } from "../components/Steps";
import { useCampaignContext } from "../context/campaignContext";

export interface CreateCampaignValues {
  name: string;
  subject: string;
  from_name: string;
  from_email: string;
  template: Option;
  lists: Option[];
  release_date: Date;
}

export const CreateCampaign = () => {
  const navigate = useNavigate();

  const { isLoading, options, currentStep, setCurrentStep } =
    useCampaignContext();

  if (isLoading) return <Spinner />;

  const onGoBack = () => {
    navigate("/campaigns");
  };

  const { Component, buttonText, onNextPress } = options[currentStep];

  return (
    <HeaderLayout title="Create campaign" onGoBack={onGoBack}>
      <CardContainer>
        <TopWrapper>
          <Steps
            options={options}
            onStepPress={(index) => { setCurrentStep(index); }}
          />
          {onNextPress && <Button text={buttonText} onClick={onNextPress} />}
        </TopWrapper>
        {Component}
      </CardContainer>
    </HeaderLayout>
  );
};

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;
