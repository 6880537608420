import styled from "styled-components";
import { Input } from "./Forms";

interface SearchBarProps {
  searchValue: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

export const SearchBar = ({
  searchValue,
  onSearchChange,
  placeholder,
}: SearchBarProps) => {
  return (
    <Container>
      <Input
        icon="search"
        value={searchValue}
        onChange={onSearchChange}
        placeholder={placeholder}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 20rem;
  margin-bottom: 1rem;
`;
