import { type PlansType } from "modules/onboarding/components/PlanCard";
import {
  PURCHASE_KEY,
  usePlanPurchase,
} from "modules/onboarding/hooks/usePlanPurchase";
import styled from "styled-components";
import { Button, PressLink } from "./Button";
import { Header, P1Bold, P1Regular } from "theme/typography";
import { Icon } from "assets/icons";
import rocket from "assets/images/rocket.png";

interface PurchaseModalProps {
  plan: PlansType;
}

export const PurchaseModal = ({ plan }: PurchaseModalProps) => {
  const { onPlanPurchase, planInfo, isLoading } = usePlanPurchase(plan);
  const { header, details } = planInfo[plan];

  return (
    <Container>
      <StyledImg src={rocket} />
      <Header>Take your email marketing to the next level</Header>
      <P1Regular>
        Are you ready to supercharge your email campaigns and reach new heights
        of success?
      </P1Regular>

      <Board>
        <P1Bold>{header} plan will give you access to:</P1Bold>

        {details.map((item) => (
          <ItemWrapper key={item}>
            <Icon name="check" size={18} />
            <p>{item}</p>
          </ItemWrapper>
        ))}
      </Board>
      <ButtonWrapper>
        <Button
          text={`Start with ${plan} plan`}
          onClick={async () => {
            await onPlanPurchase();
            localStorage.removeItem(PURCHASE_KEY);
          }}
          loading={isLoading}
        />
        <PressLink
          text="Choose a different plan"
          to="/pricing"
          onClick={() => { localStorage.removeItem(PURCHASE_KEY); }}
          variant="tertiary"
          mode="light"
        />
      </ButtonWrapper>
    </Container>
  );
};

const StyledImg = styled.img`
  width: 6rem;
  transform: rotate(45deg);
  filter: ${({ theme }) =>
    `grayscale(100%) drop-shadow(16px 16px 10px ${theme.colors.main})`};
`;

const ItemWrapper = styled.div`
  display: flex;
  align-item: center;
  gap: 0.4rem;
  margin: 0.5rem 0;
`;
const Board = styled.div`
  filter: drop-shadow(0 0 0.75rem ${({ theme }) => theme.colors.main});
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.main};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.dimensions.radius};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  max-width: 45rem;
  margin: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
