import { type InputHTMLAttributes, useCallback } from 'react';
import { styled } from 'styled-components';
import { SmallText } from 'theme/typography';

type InputProps = {
	value: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	label?: string;
	placeholder?: string;
	type?: string;
	error?: string | string[];
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = ({
	value,
	onChange,
	placeholder,
	label,
	type,
	error,
	...props
}: InputProps) => {
	const getErrorMsg = useCallback(() => {
		if (!error) return null;
		if (typeof error === 'string') return <ErrorMessage>{error}</ErrorMessage>;
		return error.map((msg) => <ErrorMessage key={msg}>{msg}</ErrorMessage>);
	}, [error]);
	return (
		<Wrapper>
			{label && <Label>{label}</Label>}
			<Container
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				type={type}
				{...props}
			/>
			{getErrorMsg()}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	margin-bottom: 1rem;
	width: 100%;
`;

const Container = styled.input`
	width: 100%;
	padding: 0.8rem 1rem;
	border-radius: ${({ theme }) => theme.dimensions.radius};
	border: 1px solid ${({ theme }) => theme.colors.primary};
	transition: 0.3s;
	display: block;
	font-size: 0.8rem;
	&:focus {
		outline: none;
		border: 1px solid ${({ theme }) => theme.colors.main};
		box-shadow: 0 0 3px ${({ theme }) => theme.colors.main};
	}
`;

const Label = styled(SmallText)`
	margin-bottom: 4px;
	font-weight: 600;
	color: ${({ theme }) => theme.colors.primary};
`;

const ErrorMessage = styled.p`
	color: ${({ theme }) => theme.colors.error};
	font-size: 0.8rem;
`;
