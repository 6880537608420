import { type PlansType } from 'modules/onboarding/components/PlanCard';
import { useCheckSessionToken } from 'modules/onboarding/hooks/useCheckSessionToken';
import {
	useGetAccountSettingsQuery,
	useGetUserExtraInformationQuery,
	useGetUserInformationQuery,
} from 'modules/settings/api/settingsApi';
import { useEffect, useState } from 'react';

export const useUserInfo = () => {
	const { isAuthenticated } = useCheckSessionToken();
	const [plan, setPlan] = useState<PlansType>();
	const { data: userExtraInfo, isLoading: loadingExtraInfo } =
		useGetUserExtraInformationQuery(undefined, {
			skip: !isAuthenticated,
		});
	const { data: userInfo, isLoading: loadingInfo } = useGetUserInformationQuery(
		undefined,
		{
			skip: !isAuthenticated,
		}
	);
	const { data, isLoading: loadingSettings } = useGetAccountSettingsQuery();

	useEffect(() => {
		if (userExtraInfo) {
			const plan = userExtraInfo.subscription_plan?.plan;
			if (plan) {
				setPlan(plan);
			}
		}
	}, [userExtraInfo]);

	return {
		isLoading: loadingExtraInfo || loadingInfo || loadingSettings,
		userExtraInfo,
		userInfo,
		plan,
		userDetails: data?.details,
		userAddress: data?.address,
	};
};
