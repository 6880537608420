import { HeaderLayout } from "components/HeaderLayout";
import styled from "styled-components";
import { LineChart } from "../components/LineChart";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useModalContext } from "context/modalContext";
import { ErrorPayment, SuccessPayment } from "../components/PaymentStatus";
import { useUserInfo } from "hooks/useUserInfo";
import { PURCHASE_KEY } from "modules/onboarding/hooks/usePlanPurchase";
import { PurchaseModal } from "components/PurchaseModal";
import { type PlansType } from "modules/onboarding/components/PlanCard";
import { InfoCard } from "components/InfoCard";

export const Dashboard = () => {
  const { userExtraInfo } = useUserInfo();
  const [searchParams] = useSearchParams();
  const { showModal } = useModalContext();

  const message = searchParams.get("plan");
  const purchasePlan = localStorage.getItem(PURCHASE_KEY);

  useEffect(() => {
    if (message === "success") {
      showModal(<SuccessPayment />);
    }
    if (message === "cancel") {
      showModal(<ErrorPayment />);
    }

    if (purchasePlan) {
      showModal(<PurchaseModal plan={purchasePlan as PlansType} />);
    }
  }, [message, purchasePlan]);

  if (!userExtraInfo) return <h2>No data</h2>;

  const { total_subscribers, total_lists, campaigns_this_month } =
    userExtraInfo;

  return (
    <HeaderLayout title="Dashboard">
      <Container>
        <TopWrapper>
          <InfoCard
            title="Total subscribers"
            text={String(total_subscribers)}
          />
          <InfoCard title="Number of lists" text={String(total_lists)} />
          <InfoCard
            title="Campaigns sent this month"
            text={String(campaigns_this_month)}
          />
        </TopWrapper>
        <Card>
          <LineChart />
        </Card>
      </Container>
    </HeaderLayout>
  );
};

const TopWrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  height: 100%;
`;

const Card = styled.div`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.dimensions.radius};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
