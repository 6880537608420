import { Button } from "components";
import { CardContainer } from "components/CardContainer";
import { Input } from "components/Forms";
import { useToastContext } from "context/toastContext";
import { useErrorMessage } from "hooks/useErrorMessage";
import { useChangePasswordMutation } from "modules/onboarding/api/auth";
import { type SyntheticEvent, useEffect, useState } from "react";
import { styled } from "styled-components";

export const Settings = () => {
  const [changePassword, { isLoading, error, isSuccess }] =
    useChangePasswordMutation();
  const [password, setPassword] = useState({
    old_password: "",
    new_password1: "",
    new_password2: "",
  });
  const { new_password1, new_password2, old_password } = password;
  const { showToaster } = useToastContext();
  useErrorMessage(error);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  const onSave = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!new_password1 || !new_password2 || !old_password) {
      showToaster({ text: "Please enter all fields.", mode: "danger" });
      return;
    }
    await changePassword({ new_password1, new_password2, old_password });
  };

  useEffect(() => {
    if (isSuccess) {
      showToaster({ text: "Password change successfully!" });
      setPassword({
        old_password: "",
        new_password1: "",
        new_password2: "",
      });
    }
  }, [isSuccess]);
  return (
    <CardContainer>
      <h5>Change password</h5>
      <StyledForm onSubmit={onSave}>
        <Input
          type="password"
          value={old_password}
          name="old_password"
          onChange={onChange}
          label="Current password"
        />
        <Input
          value={new_password1}
          name="new_password1"
          onChange={onChange}
          type="password"
          label="New password"
        />
        <Input
          value={new_password2}
          name="new_password2"
          onChange={onChange}
          type="password"
          label="Confirm new password"
        />
        <Button text="Save" onClick={onSave} loading={isLoading} />
      </StyledForm>
    </CardContainer>
  );
};

const StyledForm = styled.form`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
