import { NavLink } from "react-router-dom";
import { styled } from "styled-components";

interface LinkOption {
  text: string;
  to: string;
}

interface TabHeaderNavProps {
  options: LinkOption[];
  onLinkClick?: (option: LinkOption) => void;
}

export const TabHeaderNav = ({ options, onLinkClick }: TabHeaderNavProps) => {
  return (
    <Container>
      {options.map(({ text, to }) => (
        <StyledNavLink key={text} end to={to} onClick={() => onLinkClick?.({ text, to })}>
          {text}
        </StyledNavLink>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StyledNavLink = styled(NavLink)`
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid transparent;
  transition: 0.5s;
  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  }
  &.active {
    border-bottom: 1px solid ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.colors.main};
  }
` as unknown as typeof NavLink;
