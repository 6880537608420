import { useParams } from "react-router-dom";
import {
  useGetAnalyticsDetailQuery,
  useGetHistoryQuery,
} from "../api/analyticsApi";
import { HeaderLayout } from "components/HeaderLayout";
import { Table } from "components/Table";
import styled from "styled-components";
import { formattedDate } from "utils/formattedDate";
import { SortButton } from "components/SortButton";
import { Spinner } from "components/Spinner";
import { useState } from "react";
import { useSearchValue } from "hooks/useSearchValue";
import { SearchBar } from "components/SearchBar";
import { DoughnutChart } from "../components/DoughnutChart";
import { Huge, P1Bold } from "theme/typography";

type Field = "event" | "recipient" | "recipient_domain" | "timestamp";
interface AnalyticsOrdering {
  field?: Field;
  ordering?: "ascending" | "descending";
}

export const AnalyticsDetail = () => {
  const { analyticsId = "" } = useParams();
  const [search, setSearch] = useState("");
  const { searchValue } = useSearchValue({ search });
  const [orderingFilter, setOrderingFilter] = useState<AnalyticsOrdering>({
    field: undefined,
    ordering: undefined,
  });

  const { field, ordering } = orderingFilter;
  const { data } = useGetAnalyticsDetailQuery({ id: analyticsId });
  const { data: history, isLoading } = useGetHistoryQuery({
    analyticsId,
    search: searchValue,
    ordering:
      field && ordering
        ? `${ordering === "ascending" ? "" : "-"}${field}`
        : undefined,
  });
  if (!data) return <p>no data found!</p>;

  const { metrics } = data;


  if (isLoading) return <Spinner />;

  const info = [
    {
      title: "Open rate",
      text: metrics.open_rate,
      value: data.opened,
    },
    // {
    //   title: "Unique open rate",
    //   text: metrics.unique_open_rate,
    //   value: data.opened,
    // },
    {
      title: "Click through rate",
      text: metrics.click_through_rate,
      value: data.clicked,
    },
    {
      title: "Click to open rate",
      text: metrics.click_to_open_rate,
    },
    {
      title: "Bounce rate",
      text: metrics.bounce_rate,
      value: data.permanent_fail + data.temporary_fail,
    },
    {
      title: "Unsubscribe rate",
      text: metrics.unsubscribe_rate,
      value: data.unsubscribed,
    },
    {
      title: "Spam complaint rate",
      text: metrics.spam_complaint_rate,
      value: data.complained,
    },
  ];

  const headerText: Array<{ text: string; key: Field }> = [
    { text: "Event", key: "event" },
    { text: "Recipient", key: "recipient" },
    { text: "Recipient domain", key: "recipient_domain" },
    { text: "Date & time", key: "timestamp" },
  ];

  const onOrderingPress = (event: Field) => {
    if (field === event) {
      setOrderingFilter({
        field: event,
        ordering:
          ordering === "ascending"
            ? "descending"
            : ordering === "descending"
              ? undefined
              : "ascending",
      });
      return;
    }
    setOrderingFilter({ field: event, ordering: "ascending" });
  };

  return (
    <HeaderLayout title="Analytics details">
      <InfoWrapper>
        <SentWrapper>
          <P1Bold color="white">Emails sent</P1Bold>
          <Huge color="white">{data.accepted}</Huge>
        </SentWrapper>
        {info.map((item) => (
          <DoughnutChart
            key={item.text}
            value={item.text}
            title={item.title}
            count={item.value}
          />
        ))}
      </InfoWrapper>
      <FilterWrapper>
        <SearchBar
          searchValue={search}
          onSearchChange={(e) => { setSearch(e.target.value); }}
          placeholder="Search by event or email"
        />
      </FilterWrapper>
      <Table>
        <thead>
          <tr>
            {headerText.map((item) => (
              <th key={item.key}>
                <DataWrapper>
                  {item.text}
                  <SortButton
                    onClick={() => { onOrderingPress(item.key); }}
                    sort={field === item.key ? ordering : undefined}
                  />
                </DataWrapper>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {history?.map((item) => (
            <tr key={item.id}>
              <td>{item.event}</td>
              <td>{item.recipient}</td>
              <td>{item.recipient_domain}</td>
              <td>{formattedDate(new Date(Number(item.timestamp) * 1000))}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </HeaderLayout>
  );
};

const SentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 2rem;
`;
const FilterWrapper = styled.div`
  margin-top: 1rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const DataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;
