import { type FC } from "react";
import { NavBar } from "./Navbar";
import { useLocation, matchRoutes } from "react-router-dom";
import styled, { css } from "styled-components";
import { useModalContext , ModalContextProvider } from "context/modalContext";
import { Footer } from "./Footer";
import { ToastContextProvider } from "context/toastContext";

interface TemplateProps {
  children: React.ReactNode;
}

const navbarRoutes = [
  { path: "/" },
  { path: "/pricing" },
  { path: "/contact-us" },
];

const withFooterRoutes = [{ path: "/" }, { path: "/contact-us" }];

export const Layout: FC<TemplateProps> = ({ children }) => {
  const { isVisible } = useModalContext();
  const location = useLocation();
  const hasNavbar = matchRoutes(navbarRoutes, location);
  const hasFooter = matchRoutes(withFooterRoutes, location);

  return (
    <ToastContextProvider>
      <ModalContextProvider>
        <Background>
          {hasNavbar && <NavBar />}
          <Container>
            <Body isModalOpened={isVisible}>{children}</Body>
          </Container>
          {hasFooter && <Footer />}
        </Background>
      </ModalContextProvider>
    </ToastContextProvider>
  );
};
const Background = styled.div`
  background: ${({ theme }) => theme.colors.background};
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.dimensions.maxWidth};
  display: flex;
  min-height: 100vh;
`;

const Body = styled.div<{ isModalOpened: boolean }>`
  width: 100%;
  padding: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.M}) {
    min-height: 100vh;
    height: auto;
    ${({ isModalOpened }) =>
      isModalOpened &&
      css`
        height: 100vh;
        overflow: hidden;
      `}
  }
`;
