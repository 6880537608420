import styled from 'styled-components';
import { SmallText } from 'theme/typography';

export const Label = styled(SmallText)`
	margin-bottom: 4px;
	color: ${({ theme }) => theme.colors.primary};
	font-weight: bold;
`;

export const Wrapper = styled.div<{ isDisabled?: boolean }>`
	width: 100%;
	opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;
