import styled from "styled-components";
import logo from "assets/logo/logo.svg";
import { GradientText } from "./GradientText";

export const MainLogo = () => {
  return (
    <Wrapper>
      <StyledImg src={logo} />
      <Logo>
        Target<StyledSpan>EmailPro</StyledSpan>
      </Logo>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSpan = styled.span`
  ${GradientText}
`;

const Logo = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
`;

const StyledImg = styled.img`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.3rem;
`;
