import { type InputHTMLAttributes } from 'react';
import { css, styled } from 'styled-components';
import { Label, Wrapper } from './sharedStyled';
import { type ErrorType } from '.';
import { Icon, type IconNames } from 'assets/icons';

type InputProps = {
	value: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	label?: string;
	placeholder?: string;
	type?: string;
	error?: ErrorType;
	icon?: IconNames;
} & InputHTMLAttributes<HTMLInputElement>;

export const getErrorMsg = (error?: ErrorType) => {
	if (!error) return null;
	if (typeof error === 'string') return <ErrorMessage>{error}</ErrorMessage>;
	return error.map((msg) => <ErrorMessage key={msg}>{msg}</ErrorMessage>);
};

export const Input = ({
	value,
	onChange,
	placeholder,
	label,
	type,
	icon,
	error,
	...props
}: InputProps) => {
	return (
		<Wrapper isDisabled={props.disabled}>
			{label && <Label>{label}</Label>}
			<InnerWrapper>
				{icon && (
					<IconWrapper>
						<Icon name={icon} size={15} color="secondary" />
					</IconWrapper>
				)}
				<Container
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					type={type}
					hasIcon={Boolean(icon)}
					{...props}
				/>
			</InnerWrapper>
			{getErrorMsg(error)}
		</Wrapper>
	);
};

const InnerWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`;

const IconWrapper = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	left: 0.5rem;
`;

const Container = styled.input<{ hasIcon: boolean }>`
	width: 100%;
	padding: 0.8rem 1rem;
	border-radius: ${({ theme }) => theme.dimensions.radius};
	border: 1px solid ${({ theme }) => theme.colors.primary};
	transition: 0.3s;
	display: block;
	font-size: 0.8rem;
	${({ hasIcon }) =>
		hasIcon &&
		css`
			padding-left: 1.5rem;
		`};
	&:focus {
		outline: none;
		border: 1px solid ${({ theme }) => theme.colors.main};
	}
`;

const ErrorMessage = styled.p`
	color: ${({ theme }) => theme.colors.error};
	font-size: 0.8rem;
`;
