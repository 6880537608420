import { type FC } from "react";
import { useOutlet } from "react-router-dom";
import { Layout } from "../../../components/Layout";
import { useCheckSessionToken } from "modules/onboarding/hooks/useCheckSessionToken";

export const OnboardingLayout: FC = () => {
  const outlet = useOutlet();

  useCheckSessionToken();

  return <Layout>{outlet}</Layout>;
};
