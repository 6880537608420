import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../store/state";

interface AuthState {
  authToken: string;
}

export const initialState: AuthState = {
  authToken: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      const token = action.payload;
      state.authToken = token;

      if (token) {
        localStorage.setItem("access_token", token);
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
      }
    },
  },
});

// Actions generated from the slice
export const { setToken } = authSlice.actions;

// export user selector to get the slice in any component
export const authSelector = (state: RootState) => state.auth;
// export The reducer
const authReducer = authSlice.reducer;
export default authReducer;
