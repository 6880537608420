import { type FC, useEffect } from 'react';
import { useNavigate, useOutlet } from 'react-router-dom';
import { Layout } from './Layout';
import { useCheckSessionToken } from 'modules/onboarding/hooks/useCheckSessionToken';
import { MissingInfoAlert } from 'modules/settings/components/missingInfoAlert';
import { Navigator } from './Navigator';
import { styled } from 'styled-components';
import { FullScreenSpinner } from './Spinner';
import { useUserInfo } from 'hooks/useUserInfo';
import { useLogout } from 'modules/onboarding/hooks/useLogout';
import { EndTrial } from './EndTrial';

export const ProtectedLayout: FC = () => {
	const navigate = useNavigate();
	const outlet = useOutlet();
	const { isAuthenticated } = useCheckSessionToken();
	const { logoutUser } = useLogout();

	const { isLoading, userExtraInfo, userInfo } = useUserInfo();

	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/login');
		}
	}, [isAuthenticated, navigate]);

	useEffect(() => {
		if (!isLoading && !userInfo) {
			logoutUser();
		}
	}, [userInfo, isLoading]);

	if (isLoading || !isAuthenticated) return <FullScreenSpinner />;

	if (userExtraInfo && !userExtraInfo.is_user_enabled) {
		return (
			<Layout>
				<EndTrial />
			</Layout>
		);
	}

	return (
		<Layout>
			<Wrapper>
				<Navigator />
				<Body>
					<MissingInfoAlert />
					{outlet}
				</Body>
			</Wrapper>
		</Layout>
	);
};

const Wrapper = styled.div`
	display: flex;
	height: calc(100vh - 2rem);
	overflow: hidden;

	@media (max-width: ${({ theme }) => theme.breakpoints.M}) {
		display: block;
		overflow: auto;
	}
`;

const Body = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100vh - 2rem);
	overflow-y: scroll;
	padding-left: 1rem;
	position: relative;

	@media (max-width: ${({ theme }) => theme.breakpoints.M}) {
		padding-left: 0;
	}
`;
