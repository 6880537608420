import styled from "styled-components";
import { Huge, P1Bold } from "theme/typography";

interface InfoCardProps {
  title: string;
  text: string;
  size?: "L" | "M";
}

export const InfoCard = ({ title, text, size = "L" }: InfoCardProps) => {
  return (
    <Card>
      <p>{title}</p>
      {size === "L" ? <Huge>{text}</Huge> : <P1Bold>{text}</P1Bold>}
    </Card>
  );
};

const Card = styled.div`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: ${({ theme }) => theme.dimensions.radius};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
