import styled from "styled-components";
import { Button } from "./Button";
import { P1Bold, P1Regular } from "theme/typography";
import { useModalContext } from "context/modalContext";
import trashImage from "assets/images/trash.png";

interface DeleteModalProps {
  onDelete: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
  item?: string;
}

export const DeleteModal = ({
  onDelete,
  onCancel,
  isLoading,
  item,
}: DeleteModalProps) => {
  const { onModalClose } = useModalContext();

  const onDeletePress = () => {
    onDelete();
    onModalClose();
  };

  const onCancelPress = () => {
    onCancel?.();
    onModalClose();
  };
  return (
    <Container>
      <IconWrapper>
        <StyledImg src={trashImage} />
      </IconWrapper>
      <P1Bold center>Are you sure you want to delete this?</P1Bold>
      {item && <P1Regular center>{item}</P1Regular>}
      <ButtonWrapper>
        <Button text="Cancel" onClick={onCancelPress} isFullWidth />
        <Button
          text="Delete"
          onClick={onDeletePress}
          loading={isLoading}
          isFullWidth
          variant="danger"
        />
      </ButtonWrapper>
    </Container>
  );
};

const StyledImg = styled.img`
  width: 4rem;
`;

const IconWrapper = styled.div`
  align-self: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
