import styled from 'styled-components';
import doneTrial from 'assets/images/done-trial.svg';
import { PressLink } from './Button';
import { CardContainer } from './CardContainer';
import { Header, P1Regular } from 'theme/typography';

export const EndTrial = () => {
	return (
		<CardContainer>
			<Wrapper>
				<StyledImg src={doneTrial} />
				<Header>Your free trial has concluded!</Header>
				<Description>
					To continue harnessing the power of seamless email targeting and
					maximize your results, upgrade to one of our plans now. Elevate your
					email game and unlock a world of possibilities
				</Description>
				<PressLink variant="cta" text="Upgrade now" to="/pricing" />
			</Wrapper>
		</CardContainer>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	height: 90vh;
	text-align: center;
`;

const StyledImg = styled.img`
	width: 15rem;
`;

const Description = styled(P1Regular)`
	max-width: 35rem;
`;
