import styled, { css, keyframes } from "styled-components";
import { generateRandomNumber } from "utils/randomNumber";

interface BlurBallProps {
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  width: string;
  height: string;
  gradient?: string;
  animation?: boolean;
}

const defaultGradient =
  "linear-gradient(20deg, #000 10%, #ff4c29 50%, #000 100%)";

export const BlurBall = ({
  top,
  left,
  right,
  bottom,
  width,
  height,
  gradient = defaultGradient,
  animation,
}: BlurBallProps) => {
  return (
    <Blur
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      width={width}
      height={height}
      gradient={gradient}
      animation={animation}
    />
  );
};

const animationCircle = keyframes`
  from {
    transform: scale(1);
    }
  to {
    transform: scale(1.2);
  }
`;

const Blur = styled.div<BlurBallProps>`
  position: absolute;
  ${({ top }) =>
    Boolean(top) &&
    css`
      top: ${top};
    `};
  ${({ left }) =>
  Boolean(left) &&
    css`
      left: ${left};
    `};
  ${({ right }) =>
    Boolean(right) &&
    css`
      right: ${right};
    `};
  ${({ bottom }) =>
    Boolean(bottom) &&
    css`
      bottom: ${bottom};
    `};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 70% 30% 30% 70% / 60% 40%;
  background-image: ${({ gradient }) => gradient};
  scale: 0.5;
  filter: blur(30px);
  ${({ animation }) =>
    Boolean(animation) &&
    css`
      animation: ${animationCircle} ${generateRandomNumber(2, 5)}s linear
        infinite alternate;
    `}
`;
