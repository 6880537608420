import { useState } from "react";
import { useListDetails } from "../hooks/useListDetails";
import { CardContainer } from "components/CardContainer";
import { Input } from "components/Forms";
import { Button } from "components/Button";
import { styled } from "styled-components";
import { useToastContext } from "context/toastContext";
import { useOutletContext } from "react-router-dom";
import { type ListContextType } from "./ListDetailsLayout";
import { P1Bold, SmallText } from "theme/typography";
import { useModalContext } from "context/modalContext";
import { DeleteModal } from "components/DeleteModal";

export const Settings = () => {
  const { detail, listId } = useOutletContext<ListContextType>();
  const listName = detail.name;
  const [name, setName] = useState(listName);
  const { showToaster } = useToastContext();
  const { onListDelete, onUpdateList } = useListDetails(listId);
  const { showModal, onModalClose } = useModalContext();

  const update = async () => {
    await onUpdateList({ name });
    showToaster({ text: "List updated" });
  };

  const onDeletePress = () => {
    showModal(
      <DeleteModal
        onDelete={onListDelete}
        onCancel={onModalClose}
        item={listName}
      />
    );
  };
  return (
    <CardContainer>
      <Wrapper>
        <Input
          placeholder="list name"
          label="List name"
          value={name}
          onChange={(e) => { setName(e.target.value); }}
        />
        <StyledButton
          onClick={update}
          text="Save"
          disabled={listName === name}
        />
      </Wrapper>

      <Wrapper>
        <P1Bold color="error">Delete list</P1Bold>
        <SmallText color="error">
          Deleting a list results in an irreversible action. All the contacts
          within the list will be permanently erased, and they will no longer be
          included in any generated reports.
        </SmallText>
        <StyledButton
          onClick={onDeletePress}
          text="Delete list"
          variant="danger"
          icon="delete"
        />
      </Wrapper>
    </CardContainer>
  );
};

const StyledButton = styled(Button)`
  align-self: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;
