import { styled } from "styled-components";
import { PlanCard, plans } from "../components/PlanCard";

export const Pricing = () => {
  return (
    <Container>
      <Title>
        Ready to <StyledSpan>get started?</StyledSpan>
      </Title>
      <PlansWrapper>
        {plans.map((plan) => (
          <PlanCard key={plan} plan={plan} />
        ))}
      </PlansWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    padding-top: 0;
  }
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.main};
`;

const PlansWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    flex-direction: column;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin-bottom: 5rem;
`;
