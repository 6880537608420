import { styled } from 'styled-components';
import { Icon } from 'assets/icons';
import { Button } from 'components';
import { useCheckSessionToken } from '../hooks/useCheckSessionToken';

import { usePlanPurchase } from '../hooks/usePlanPurchase';

export const MOST_POPULAR = 'premium';

export const plans = ['basic', 'premium', 'standard'] as const;

export type PlansType = (typeof plans)[number];

const planPoints: Record<PlansType, number> = {
	basic: 0,
	standard: 1,
	premium: 2,
};

interface PlanCardProps {
	plan: PlansType;
	scaleDisabled?: boolean;
}

export const PlanCard = ({ plan, scaleDisabled }: PlanCardProps) => {
	const { isAuthenticated } = useCheckSessionToken();

	const { onPlanPurchase, isLoading, userPlan, planInfo } =
		usePlanPurchase(plan);

	const { header, price, details } = planInfo[plan];

	const getButtonText = () => {
		if (!isAuthenticated || !userPlan) return 'Start for free';
		const currentPoint = planPoints[userPlan];
		const cardPoint = planPoints[plan];

		if (currentPoint > cardPoint) {
			return 'Downgrade';
		} else {
			return 'Upgrade';
		}
	};

	const commonDetails = [
		'Create forms to incorporate in your website',
		'Add unlimited senders',
		'Analytics available',
		'Emails templates powered by AI',
	];

	return (
		<Container plan={plan} scaleDisabled={scaleDisabled}>
			<TopWrapper>
				<Header>{header}</Header>
				{plan === MOST_POPULAR && <PopularChip />}
			</TopWrapper>
			<Details>
				{details.map((item) => (
					<DetailWrapper key={item}>
						<Icon name="check" size={18} />
						<DetailText bold>{item}</DetailText>
					</DetailWrapper>
				))}
				{commonDetails.map((item) => (
					<DetailWrapper key={item}>
						<Icon name="check" size={18} />
						<DetailText>{item}</DetailText>
					</DetailWrapper>
				))}
			</Details>

			<BottomWrapper>
				<Price>
					<PriceWrapper>{price}</PriceWrapper>/month
				</Price>
				{userPlan !== plan && (
					<Button
						text={getButtonText()}
						onClick={onPlanPurchase}
						loading={isLoading}
					/>
				)}
			</BottomWrapper>
		</Container>
	);
};

const PopularChip = () => {
	return (
		<Chip>
			<p>Most popular</p>
		</Chip>
	);
};

const Chip = styled.div`
	padding: 0.3rem 0.5rem;
	border: 1px solid ${({ theme }) => theme.colors.main};
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.main};
	font-weight: bold;
	font-size: 0.7rem;
	border-radius: 100px;
	text-align: center;
`;

const TopWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 1rem;
`;

const BottomWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1rem;
`;

const DetailText = styled.p<{ bold?: boolean }>`
	margin-left: 0.5rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

const PriceWrapper = styled.span`
	color: ${({ theme }) => theme.colors.main};
	font-weight: bold;
`;

const Details = styled.div`
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0.5rem 0;
`;

const DetailWrapper = styled.div`
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 0.8rem;
	}
`;

const Header = styled.h3`
	margin: 0;
`;
const Price = styled.p`
	font-size: 0.9rem;
`;

const Container = styled.div<{ plan: PlansType; scaleDisabled?: boolean }>`
	text-align: start;
	background: ${({ theme }) => theme.colors.white};
	padding: 1rem;
	border-radius: ${({ theme }) => theme.dimensions.radius};
	width: 100%;
	box-shadow: 0 0 1rem ${({ theme }) => theme.colors.main};
	border: 0.5px solid ${({ theme }) => theme.colors.main};
	scale: ${({ plan, scaleDisabled }) =>
		plan === MOST_POPULAR && !scaleDisabled ? 1.08 : 1};
	@media (max-width: ${({ theme }) => theme.breakpoints.S}) {
		scale: 1;
	}
`;
