import { Icon, type IconNames } from "assets/icons";
import { useLogout } from "modules/onboarding/hooks/useLogout";
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
import { MainLogo } from "./Logo";
import { Drawer } from "./Drawer";
import { SmallText } from "theme/typography";
import { useUserInfo } from "hooks/useUserInfo";
import { Button, PressLink } from "./Button";

interface SidebarOption {
  to: string;
  icon: IconNames;
  label: string;
}

export const linksOptions: SidebarOption[] = [
  {
    to: "/dashboard",
    icon: "grid",
    label: "Dashboard",
  },
  {
    to: "/lists",
    icon: "list",
    label: "Lists",
  },
  {
    to: "/templates",
    icon: "template",
    label: "Templates",
  },
  {
    to: "/campaigns",
    icon: "target",
    label: "Campaigns",
  },
  {
    to: "/forms",
    icon: "clipboard",
    label: "Forms",
  },
  {
    to: "/analytics",
    icon: "analytics",
    label: "Analytics",
  },
  {
    to: "/account",
    icon: "profile",
    label: "Account",
  },
];

export const Navigator = () => {
  return (
    <>
      <ProtectedDrawer />
      <Side />
    </>
  );
};

const Side = () => {
  const { logoutUser } = useLogout();
  const { userInfo, plan } = useUserInfo();
  const isCustomer = Boolean(plan);
  return (
    <Container>
      <OptionsWrapper>
        <LogoWrapper>
          <MainLogo />
        </LogoWrapper>
        {linksOptions.map((option) => (
          <StyledNavLink key={option.to} to={option.to}>
            {({ isActive }) => (
              <LinkContainer isActive={isActive}>
                <Icon name={option.icon} color="white" />
                <LinkLabel>{option.label}</LinkLabel>
              </LinkContainer>
            )}
          </StyledNavLink>
        ))}
      </OptionsWrapper>
      <div>
        {!isCustomer && (
          <ButtonWrapper>
            <PressLink text="Upgrade now" to="/pricing" variant="tertiary" />
          </ButtonWrapper>
        )}

        <ProfileWrapper>
          <LeftWrapper>
            <IconContainer>
              <Icon name="profile" color="background" />
            </IconContainer>
            <TextWrapper>
              <SmallText>
                {userInfo?.first_name} {userInfo?.last_name}
              </SmallText>
              <SmallText>{plan ?? "Free account"}</SmallText>
            </TextWrapper>
          </LeftWrapper>
          <LogoutWrapper onClick={async () => { await logoutUser(); }}>
            <Icon name="exit" color="white" />
          </LogoutWrapper>
        </ProfileWrapper>
      </div>
    </Container>
  );
};

const ProtectedDrawer = () => {
  const { logoutUser } = useLogout();

  return (
    <Drawer>
      <div>
        {linksOptions.map((option) => (
          <StyledNavLink key={option.to} to={option.to}>
            {({ isActive }) => (
              <LinkContainer isActive={isActive}>
                <Icon name={option.icon} color="white" />
                <LinkLabel>{option.label}</LinkLabel>
              </LinkContainer>
            )}
          </StyledNavLink>
        ))}
        <Button
          text="Logout"
          onClick={async () => { await logoutUser(); }}
          variant="secondary"
          icon="exit"
        />
      </div>
    </Drawer>
  );
};

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

const IconContainer = styled.div`
  background-color: white;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
`;
const ButtonWrapper = styled.div`
  padding: 1rem;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileWrapper = styled.div`
  color: white;
  padding: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  margin-bottom: 1.5rem;
  padding: 1rem;
`;

const StyledNavLink = styled(NavLink)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 0;
`;

const LinkLabel = styled(SmallText)`
  color: ${({ theme }) => theme.colors.white};
`;

const LinkContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem;
  transition: 1s;
  gap: 0.5rem;
  background: ${({ isActive, theme }) =>
    isActive
      ? `linear-gradient(90deg, ${theme.colors.main},10%, transparent)`
      : "transparent"};
`;

const Container = styled.div`
  border-radius: ${({ theme }) => theme.dimensions.radius};
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.primary};

  @media (max-width: ${({ theme }) => theme.breakpoints.M}) {
    display: none;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoutWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
