import { type SyntheticEvent, useEffect, useState } from "react";
import {
  type UserRegisterData,
  useRegisterUserMutation,
} from "modules/onboarding/api/auth";
import { isFetchBaseQueryError } from "services/api/rtkQueryError";
import { useToastContext } from "context/toastContext";
import { useNavigate } from "react-router-dom";

type ErrorMessage = Record<string, string[]>;

export const useRegister = (values: UserRegisterData) => {
  const navigate = useNavigate();
  const [register, { isLoading, error, isSuccess }] = useRegisterUserMutation();
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();
  const { showToaster } = useToastContext();

  const registerUser = async (e: SyntheticEvent) => {
    e.preventDefault();
    await register(values);
  };

  useEffect(() => {
    if (error) {
      if (isFetchBaseQueryError(error) && error?.data) {
        const messages = Object.values(error.data);
        const err = error.data as ErrorMessage;
        setErrorMessage(err);
        showToaster({ text: messages[0], mode: "danger" });
      }
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      showToaster({ text: "A verification email was sent to your inbox!" });
      navigate("/");
    }
  }, [isSuccess]);
  return { registerUser, isLoading, errorMessage };
};
