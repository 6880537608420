import { Modal } from "components/Modal";
import { createContext, useContext, useState } from "react";

interface ModalContextProviderProps {
  children: React.ReactNode;
}

interface ModalContextState {
  isVisible: boolean;
  onModalClose: () => void;
  showModal: (content: React.ReactNode) => void;
  content: React.ReactNode | null;
}

const initialState = {
  isVisible: false,
  onModalClose: () => null,
  showModal: () => null,
  content: null,
};

export const ModalContext = createContext<ModalContextState>({
  ...initialState,
});

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error("ModalContext was used outside of its Provider");
  }

  return context;
};

export const ModalContextProvider = ({
  children,
}: ModalContextProviderProps) => {
  const [isVisible, setIsVisible] = useState(initialState.isVisible);
  const [content, setContent] = useState<React.ReactNode | null>(
    initialState.content
  );

  const onModalClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setContent(null);
    }, 500);
  };

  const showModal = (component?: React.ReactNode) => {
    setContent(component);
    setIsVisible(true);
  };

  return (
    <ModalContext.Provider
      value={{
        isVisible,
        onModalClose,
        showModal,
        content,
      }}
    >
      {children}
      <Modal />
    </ModalContext.Provider>
  );
};
