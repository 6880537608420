import { css } from "styled-components";

export const GradientText = css`
  background-clip: text;
  text-fill-color: transparent;
  background-image: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.main2} 5%,
    ${({ theme }) => theme.colors.main} 80%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`;
