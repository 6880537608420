import { styled } from 'styled-components';
import emptyState from 'assets/images/emptyState/empty-state.svg';
import emptyState2 from 'assets/images/emptyState/emptystate2.svg';
import emptyState3 from 'assets/images/emptyState/emptystate3.svg';
import emptyState4 from 'assets/images/emptyState/emptystate4.svg';
import { PressLink } from './Button';

interface EmptyStateProps {
	text: string;
	link?: {
		to: string;
		text: string;
	};
	type?: 'contacts' | 'lists' | 'campaigns';
}
export const EmptyState = ({ text, link, type }: EmptyStateProps) => {
	const getEmptyStateImage = () => {
		switch (type) {
			case 'lists':
				return emptyState2;
			case 'contacts':
				return emptyState3;
			case 'campaigns':
				return emptyState4;
			default:
				return emptyState;
		}
	};

	return (
		<Container>
			<Image src={getEmptyStateImage()} alt="empty state image" />
			<Text>{text}</Text>
			{link && <PressLink text={link.text} to={link.to} />}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	align-items: center;
`;

const Image = styled.img`
	width: 20rem;
	margin-bottom: 2rem;
`;

const Text = styled.p`
	text-align: center;
	margin-bottom: 1rem;
`;
