import { useEffect } from "react";
import { useAppDispatch } from "store";
import { authSelector, setToken } from "modules/onboarding/data/authSlice";
import { useSelector } from "react-redux";

export const useCheckSessionToken = () => {
  const dispatch = useAppDispatch();
  const { authToken } = useSelector(authSelector);

  const token = localStorage.getItem("access_token");
  const isAuthenticated = Boolean(token);

  useEffect(() => {
    if (authToken) return;
    if (token) {
      dispatch(setToken(token));
    } else {
      dispatch(setToken(""));
    }
  }, [dispatch, authToken, token]);

  return { token, isAuthenticated };
};
