import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../../../store/state";
import { type ListDetails } from "../api/types";

interface ListsState {
  lists: ListDetails[];
}

export const initialState: ListsState = {
  lists: [],
};

const listsSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLists: (state, action: PayloadAction<ListDetails[]>) => {
      state.lists = action.payload;
    },
  },
});

// Actions generated from the slice
export const { setLists } = listsSlice.actions;

// export user selector to get the slice in any component
export const listsSelector = (state: RootState) => state.auth;
// export The reducer
const listsReducer = listsSlice.reducer;
export default listsReducer;
