import { useNavigate, useParams } from "react-router-dom";
import { useGetCampaignDetailsQuery } from "../api/campaignsApi";
import { Spinner } from "components/Spinner";
import { CardContainer } from "components/CardContainer";
import { HeaderLayout } from "components/HeaderLayout";

export const CampaignDetail = () => {
  const { campaignId = "" } = useParams();
  const { data, isLoading } = useGetCampaignDetailsQuery({ campaignId });
  const navigate = useNavigate();

  if (isLoading) return <Spinner />;
  if (!data) return <p>No data found</p>;

  const { name, release_date } = data;

  const onGoBack = () => {
    navigate("/campaigns");
  };

  return (
    <HeaderLayout title="Campaign details" onGoBack={onGoBack}>
      <CardContainer>
        <h3>{name}</h3>
        <p>Date: {release_date}</p>
      </CardContainer>
    </HeaderLayout>
  );
};
