import { useState } from "react";
import { useLogin } from "../hooks/useLogin";
import { AuthLayout } from "../components/AuthLayout";
import { Button } from "components";
import styled from "styled-components";
import { AuthRedirection } from "../components/AuthRedirection";
import { Input } from "components/Forms";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { loginUser, isLoading, errorMessage } = useLogin({ email, password });

  return (
    <AuthLayout title="Log in" onSubmit={loginUser}>
      <Input
        type="email"
        label="Email address"
        placeholder="Enter email"
        value={email}
        onChange={(e) => { setEmail(e.target.value); }}
        error={errorMessage?.email}
      />
      <Input
        type="password"
        label="Password"
        placeholder="Password"
        value={password}
        onChange={(e) => { setPassword(e.target.value); }}
        error={errorMessage?.password}
      />
      <StyledButton
        variant="primary"
        disabled={isLoading}
        text="Log in"
        isFullWidth
        loading={isLoading}
        type="submit"
      />

      <AuthRedirection screen="login" />
    </AuthLayout>
  );
};

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;
