import { api } from "services/api/api";
import { type PlansType } from "../components/PlanCard";
import { USER_EXTRA_INFO_TAG } from "modules/settings/api/settingsApi";

const PRICING_TAG = "price_plan";

export const pricingApi = api
  .enhanceEndpoints({ addTagTypes: [PRICING_TAG, USER_EXTRA_INFO_TAG] })
  .injectEndpoints({
    endpoints: (build) => ({
      getPaymentUrl: build.query<
        { sessionId: string; url: string },
        { plan: PlansType }
      >({
        query: ({ plan }) => ({
          method: "GET",
          url: "/subscriptions/v1/create-checkout-session/" + plan,
        }),
        providesTags: [PRICING_TAG],
      }),
      modifySubscription: build.mutation<void, { newPlan: PlansType }>({
        query: ({ newPlan }) => ({
          body: { new_plan: newPlan },
          method: "POST",
          url: "/subscriptions/v1/modify-subscription/",
        }),
        invalidatesTags: [PRICING_TAG, USER_EXTRA_INFO_TAG],
      }),
    }),
  });

export const { useLazyGetPaymentUrlQuery, useModifySubscriptionMutation } =
  pricingApi;
