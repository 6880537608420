import { type SerializedError } from "@reduxjs/toolkit";
import { type FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useToastContext } from "context/toastContext";
import { useEffect, useState } from "react";
import { isFetchBaseQueryError } from "services/api/rtkQueryError";

export const useErrorMessage = (
  error?: FetchBaseQueryError | SerializedError
) => {
  const [message, setMessage] = useState("");
  const { showToaster } = useToastContext();

  useEffect(() => {
    if (isFetchBaseQueryError(error) && error?.data) {
      const messages = Object.values(error.data);
      showToaster({ text: messages[0], mode: "danger" });
      setMessage(messages[0]);
    }
  }, [error]);
  return { message };
};
