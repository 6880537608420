import { useGetCampaignsQuery } from '../api/campaignsApi';
import { Spinner } from 'components/Spinner';
import { useState } from 'react';
import { CardContainer } from 'components/CardContainer';
import { EmptyState } from 'components/EmptyState';
import { CampaignRow } from '../components/CampaignRow';
import { Filter, FilterWrapper } from '../components/Filter';
import { HeaderLayout } from 'components/HeaderLayout';
import styled from 'styled-components';
import { SearchBar } from 'components/SearchBar';
import { useSearchValue } from 'hooks/useSearchValue';

export type CampaignFilter = 'all' | 'sent' | 'scheduled' | 'draft';

export const Campaigns = () => {
	const [search, setSearch] = useState('');
	const { searchValue } = useSearchValue({ search });
	const [filter, setFilter] = useState<CampaignFilter>('all');

	const draftFilter = filter === 'draft' || undefined;
	const sentFilter = filter === 'sent' || undefined;
	const scheduledFilter = filter === 'scheduled' || undefined;

	const { data, isLoading } = useGetCampaignsQuery({
		search: searchValue,
		draft: draftFilter,
		scheduled: scheduledFilter,
		sent: sentFilter,
	});

	if (isLoading) return <Spinner />;
	if (!data) return <p>No data found</p>;

	const options = [
		{
			key: 'all',
			text: 'All',
		},
		{
			key: 'draft',
			text: 'Draft',
		},
		{
			key: 'sent',
			text: 'Sent',
		},
		{
			key: 'scheduled',
			text: 'Scheduled',
		},
	];

	const getEmptyState = () => {
		const isFiltered = filter !== 'all';
		return (
			<EmptyState
				type="campaigns"
				text={`No campaigns ${isFiltered ? filter : 'yet'}`}
				link={
					isFiltered ? undefined : { to: 'create', text: 'Create new campaign' }
				}
			/>
		);
	};

	return (
		<HeaderLayout
			title="Campaigns"
			rightButton={{ text: 'New campaign', icon: 'add', to: 'create' }}
		>
			<FilterWrapper>
				<SearchBar
					searchValue={search}
					onSearchChange={(e) => {
						setSearch(e.target.value);
					}}
					placeholder="Search campaign"
				/>
				<Filter
					options={options}
					selected={filter}
					onClick={(key) => {
						setFilter(key as CampaignFilter);
					}}
				/>
			</FilterWrapper>
			{data.length > 0 ? (
				<ContainerWrapper>
					{data
						.filter((item) => {
							if (filter === 'sent') return item.sent;
							if (filter === 'draft') return item.draft;
							if (filter === 'scheduled') return !item.sent && item.scheduled;
							return item;
						})
						.map((campaign) => (
							<CampaignRow key={campaign.id} campaign={campaign} />
						))}
				</ContainerWrapper>
			) : (
				<CardContainer center>{getEmptyState()}</CardContainer>
			)}
		</HeaderLayout>
	);
};

const ContainerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	height: 100%;
`;
