import { Button } from "components";
import { CardContainer } from "components/CardContainer";
import { Dropdown, Input } from "components/Forms";
import { HeaderLayout } from "components/HeaderLayout";
import { useGetListsQuery } from "modules/lists/api/listsApi";
import { type SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useCreateFormMutation } from "../api/formsApi";
import { useToastContext } from "context/toastContext";
import form from "assets/images/target-form.png";
import { Label } from "components/Forms/sharedStyled";
import { type SingleType } from "components/Forms/Dropdown";

export const CreateForm = () => {
  const navigate = useNavigate();
  const { showToaster } = useToastContext();
  const { data } = useGetListsQuery({});
  const [createForm] = useCreateFormMutation();

  const dropDownOptions = data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const [values, setValues] = useState({
    name: "",
    list: "",
  });

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      name: e.target.value,
    });
  };

  const create = async (e: SyntheticEvent) => {
    e.preventDefault();
    const { list, name } = values;
    if (!list || !name) {
      showToaster({
        text: "Please enter all fields",
        mode: "danger",
      });
      return;
    }
    try {
      await createForm(values).unwrap();
      showToaster({
        text: "Form created successfully",
      });
      navigate("/forms");
    } catch (err) {
      showToaster({
        text: "Unable to create form. Try again later!",
        mode: "danger",
      });
    }
  };

  const onDropDownChange = (value: SingleType) => {
    if (!value) return
    setValues({
      ...values,
      list: value.value
    })
  }

  return (
    <HeaderLayout title="Create form" onGoBack={() => { navigate("/forms"); }}>
      <CardContainer>
        <StyledForm>
          <Input
            name="name"
            label="Name"
            onChange={onNameChange}
            value={values.name}
          />
          <Dropdown
            options={dropDownOptions ?? []}
            onValueChange={(newValue) => { onDropDownChange(newValue as SingleType); }}
            placeholder="Choose a list"
            label="List"
          />
          <TemplateWrapper>
            <Label>Form template</Label>
            <Selected>
              <StyledImg src={form} />
            </Selected>
          </TemplateWrapper>
          <Button onClick={create} text="Create" />
        </StyledForm>
      </CardContainer>
    </HeaderLayout>
  );
};

const TemplateWrapper = styled.div`
  align-self: flex-start;
`;

const Selected = styled.div`
  position: relative;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledImg = styled.img`
  width: 20rem;
  border-radius: 0.5rem;
`;
