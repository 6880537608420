import { useModalContext } from "context/modalContext";
import styled from "styled-components";
import { type TemplateDetail } from "../api/types";

export const usePreviewTemplate = ({
  template,
}: {
  template?: TemplateDetail;
}) => {
  const { showModal } = useModalContext();

  const TemplateView = ({ url }: { url: string }) => {
    return <Frame src={url} />;
  };

  const onShowTemplate = () => {
    if (!template) return null;
    showModal(<TemplateView url={template.url ?? ""} />);
  };
  return { onShowTemplate, templateUrl: template?.url ?? "" };
};

const Frame = styled.iframe`
  width: 50rem;
  height: 50rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.M}) {
    width: 20rem;
    height: auto;
  }
`;
