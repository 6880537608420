import { PressLink } from 'components/Button';
import { useUserInfo } from 'hooks/useUserInfo';
import styled from 'styled-components';
import { P1Regular } from 'theme/typography';

import notFound from 'assets/images/info-not-found.svg';
import { useModalContext } from 'context/modalContext';

export const AddDetailsModal = () => {
	const { userAddress, userDetails } = useUserInfo();
	const { onModalClose } = useModalContext();

	return (
		<Container>
			<InfoWrapper>
				<StyledImg src={notFound} />
				{!userAddress && (
					<P1Regular center>
						To meet the requirements of anti-spam legislation, it&apos;s
						essential to integrate a valid physical address into your email
						correspondence.
					</P1Regular>
				)}
				{!userDetails && (
					<P1Regular center>
						Kindly provide information about your company before initiating a
						campaign.
					</P1Regular>
				)}
				<ButtonWrapper>
					{!userAddress && (
						<PressLink
							to="/account/address"
							text="Add address"
							onClick={onModalClose}
						/>
					)}
					{!userDetails && (
						<PressLink
							to="/account/details"
							text="Add details"
							onClick={onModalClose}
						/>
					)}
				</ButtonWrapper>
			</InfoWrapper>
		</Container>
	);
};

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
`;

const Container = styled.div`
	max-width: 30rem;
`;

const StyledImg = styled.img`
	width: 10rem;
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;
