import { OnboardingLayout, ProtectedLayout } from "components";
import { Login } from "modules/onboarding/pages/login";
import { Register } from "modules/onboarding/pages/register";
import { Campaigns } from "modules/campaigns/pages/campaigns";
import { Dashboard } from "modules/dashboard/pages/dashboard";
import { Lists } from "modules/lists/pages/lists";
import { Landing } from "modules/onboarding/pages/landing";
import { Templates } from "modules/templates/pages/templates";
import { createBrowserRouter } from "react-router-dom";
import { CreateList } from "modules/lists/pages/createList";
import { CreateContact } from "modules/lists/pages/createContact";
import { ContactDetails } from "modules/lists/pages/contactDetails";
import { ListDetailsLayout } from "modules/lists/components/ListDetailsLayout";
import { CreateTemplate } from "modules/templates/pages/createTemplate";
import { TemplateDetails } from "modules/templates/pages/templateDetails";
import { CreateCampaign } from "modules/campaigns/pages/createCampaign";
import { AccountLayout } from "modules/settings/screens/AccountLayout";
import { AccountDetails } from "modules/settings/screens/AccountDetails";
import { Analytics } from "modules/analytics/pages/analytics";
import { AnalyticsDetail } from "modules/analytics/pages/analyticsDetail";
import { CampaignDetail } from "modules/campaigns/pages/campaignDetail";
import { Pricing } from "modules/onboarding/pages/pricing";
import { ForgotPassword } from "modules/onboarding/pages/forgot-password";
import { PasswordReset } from "modules/onboarding/pages/password-reset";
import { AddSender } from "modules/settings/screens/addSender";
import { Profile } from "modules/settings/screens/Profile";
import { Senders } from "modules/settings/screens/Senders";
import { Settings } from "modules/settings/screens/Settings";
import { Settings as SettingsList } from "modules/lists/components/Settings";
import { CampaignContextProvider } from "modules/campaigns/context/campaignContext";
import { Subscribers } from "modules/lists/components/Subscribers";
import { Import } from "modules/lists/components/Import";
import { ContactUs } from "modules/onboarding/pages/ContactUs";
import { ListsLayout } from "modules/lists/components/ListsLayout";
import { Tags } from "modules/lists/pages/Tags";
import { Plan } from "modules/settings/screens/Plan";
import { PageNotFound } from "modules/onboarding/pages/PageNotFound";
import { Address } from "modules/settings/screens/Address";
import { Forms } from "modules/forms/pages/forms";
import { CreateForm } from "modules/forms/pages/createForm";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <OnboardingLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "password-reset/:uid/:token",
        element: <PasswordReset />,
      },
    ],
  },
  {
    path: "/pricing",
    element: <OnboardingLayout />,
    children: [
      {
        path: "",
        element: <Pricing />,
      },
    ],
  },
  {
    path: "/contact-us",
    element: <OnboardingLayout />,
    children: [
      {
        path: "",
        element: <ContactUs />,
      },
    ],
  },
  {
    path: "/lists",
    element: <ProtectedLayout />,
    children: [
      {
        path: "",
        element: <ListsLayout />,
        children: [
          { path: "", element: <Lists /> },
          {
            path: "tags",
            element: <Tags />,
          },
        ],
      },
      {
        path: "create",
        element: <CreateList />,
      },
      {
        path: ":listId",
        element: <ListDetailsLayout />,
        children: [
          { path: "subscribers", element: <Subscribers /> },
          { path: "import", element: <Import /> },
          { path: "settings", element: <SettingsList /> },
          { path: "subscribers/create", element: <CreateContact /> },
          { path: "subscribers/:contactId", element: <ContactDetails /> },
        ],
      },
    ],
  },
  {
    path: "templates",
    element: <ProtectedLayout />,
    children: [
      {
        path: "",
        element: <Templates />,
      },
      {
        path: "create",
        element: <CreateTemplate />,
      },
      {
        path: ":templateId",
        element: <TemplateDetails />,
      },
    ],
  },
  {
    path: "campaigns",
    element: <ProtectedLayout />,
    children: [
      {
        path: "",
        element: <Campaigns />,
      },
      {
        path: ":campaignId",
        element: <CampaignDetail />,
      },
      {
        path: "create",
        element: (
          <CampaignContextProvider>
            <CreateCampaign />
          </CampaignContextProvider>
        ),
        children: [
          {
            path: ":id",
            element: (
              <CampaignContextProvider>
                <CreateCampaign />
              </CampaignContextProvider>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/dashboard",
    element: <ProtectedLayout />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "/forms",
    element: <ProtectedLayout />,
    children: [
      {
        path: "",
        element: <Forms />,
      },
      {
        path: "create",
        element: <CreateForm />,
      },
    ],
  },
  {
    path: "/analytics",
    element: <ProtectedLayout />,
    children: [
      {
        path: "",
        element: <Analytics />,
      },
      {
        path: ":analyticsId",
        element: <AnalyticsDetail />,
      },
    ],
  },
  {
    path: "/account",
    element: <ProtectedLayout />,
    children: [
      {
        path: "",
        element: <AccountLayout />,
        children: [
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "details",
            element: <AccountDetails />,
          },
          {
            path: "address",
            element: <Address />,
          },
          {
            path: "senders",
            children: [
              { path: "", element: <Senders /> },
              { path: "add", element: <AddSender /> },
            ],
          },
          {
            path: "settings",
            element: <Settings />,
          },
          {
            path: "plan",
            element: <Plan />,
          },
        ],
      },
    ],
  },
  { path: "*", element: <PageNotFound /> },
]);
