import Toggle from "react-toggle";
import styled from "styled-components";
import "react-toggle/style.css";
import { SmallText } from "theme/typography";

interface BaseToggleProps {
  isChecked: boolean;
  onChange: () => void;
  label?: string;
  leftLabel?: string;
}

export const BaseToggle = ({
  isChecked,
  onChange,
  label,
  leftLabel,
}: BaseToggleProps) => {
  return (
    <Container>
      {leftLabel && <SmallText>{leftLabel}</SmallText>}
      <StyledToggle
        checked={isChecked}
        onChange={onChange}
        value={"test"}
        icons={false}
      />
      {label && <SmallText>{label}</SmallText>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledToggle = styled(Toggle)`
  &.react-toggle--checked .react-toggle-track {
    background-color: ${({ theme }) => theme.colors.main};
  }

  &.react-toggle--checked:hover .react-toggle-track:hover {
    background-color: ${({ theme }) => theme.colors.main};
  }

  &.react-toggle--checked .react-toggle-thumb {
    border-color: ${({ theme }) => theme.colors.main};
  }

  &.react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    border-color: ${({ theme }) => theme.colors.main};
    background-color: ${({ theme }) => theme.colors.main};
    opacity: 0.8;
  }

  &.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
  }
`;
