import styled from "styled-components";
import success from "assets/images/plan-success.png";
import error from "assets/images/plan-cancel.png";
import { Header, P1Regular } from "theme/typography";
import { Button } from "components/Button";
import { useModalContext } from "context/modalContext";
import { config } from "config/config";

export const SuccessPayment = () => {
  const { onModalClose } = useModalContext();
  return (
    <Container>
      <StyledImage src={success} />
      <Header color="main">Congratulations on your purchase!</Header>
      <P1Regular>
        You&apos;re now part of our exclusive user community, and we&apos;re excited to
        help you supercharge your experience. With your new subscription, you&apos;ve
        just unlocked a treasure trove of exciting features and benefits that
        will take your usage to the next level.
      </P1Regular>
      <Button text="Let&apos;s start!" onClick={onModalClose} />
    </Container>
  );
};

export const ErrorPayment = () => {
  const { onModalClose } = useModalContext();
  return (
    <Container>
      <ErrorImage src={error} />
      <Header color="main">Oops! Payment unsuccessful</Header>
      <P1Regular>
        We&apos;re sorry to inform you that there was an issue processing your
        payment. Don&apos;t worry, we&apos;re here to help you get back on track and
        unlock the full potential of our platform.
      </P1Regular>
      <ContactWrapper>
        <P1Regular>If you need extra support you can contact us for</P1Regular>
        <EmailSupport href={`mailto:${config.supportEmail}`}>
          support@targetemailpro.com
        </EmailSupport>
      </ContactWrapper>
      <Button text="Okay" onClick={onModalClose} />
    </Container>
  );
};

const ContactWrapper = styled.div`
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
  width: 40rem;
`;

const StyledImage = styled.img`
  width: 20rem;
`;

const ErrorImage = styled.img`
  width: 10rem;
`;

const EmailSupport = styled.a`
  color: ${({ theme }) => theme.colors.main};
  cursor: pointer;
`;
