import { styled } from "styled-components";

interface HeaderProps {
  title: string;
}

export const Header = ({ title }: HeaderProps) => {
  return <StyledHeader>{title}</StyledHeader>;
};

const StyledHeader = styled.h4`
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
`;
