import { Icon } from "assets/icons";
import { MainLogo } from "components/Logo";
import { type SyntheticEvent, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useExternalScripts from "hooks/useExternalScripts";

interface AuthLayoutProps {
  title: string;
  children: React.ReactNode;
  onSubmit: (e: SyntheticEvent) => Promise<void>;
  to?: string;
}

export const AuthLayout = ({
  title,
  children,
  onSubmit,
  to,
}: AuthLayoutProps) => {
  useExternalScripts({
    url: "https://www.google.com/recaptcha/enterprise.js?render=6LcdIlYoAAAAAPAbukx6-A2ZJZEeHbhALnHNN-ES",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <IconWrapper to={to ?? "/"}>
        <Icon name="back" color="white" />
      </IconWrapper>
      <Brand>
        <MainLogo />
      </Brand>
      <StyledForm onSubmit={onSubmit}>
        <Title>{title}</Title>
        {children}
      </StyledForm>
    </Container >
  );
};

const Title = styled.h3`
  text-align: center;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledForm = styled.form`
  width: 30rem;
  margin: 0 auto;
  box-shadow: 0px 4px 30px 8px rgb(255 255 255 / 15%);
  padding: 2rem;
  border-radius: ${({ theme }) => theme.dimensions.radius};
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  gap: 1rem;
  flex-direction: column;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.S}) {
    width: 100%;
    padding: 1rem;
  }
`;

const IconWrapper = styled(Link)`
  padding: 0.2rem;
  position: absolute;
  top: 2rem;
  left: 2rem;
`;

const Brand = styled.div`
  margin-bottom: 1rem;
`;
