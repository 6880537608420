import { Link } from "react-router-dom";
import styled from "styled-components";
import { SmallText } from "theme/typography";

type AuthScreens = "login" | "register";

interface AuthRedirectionProps {
  screen: AuthScreens;
}

type Options = Record<
  AuthScreens,
  {
    description: string;
    cta: string;
    to: string;
  }
>;

export const AuthRedirection = ({ screen }: AuthRedirectionProps) => {
  const text: Options = {
    login: {
      description: "Don't have an account?",
      cta: "Sign up",
      to: "/register",
    },
    register: {
      description: "Already have an account?",
      cta: "Log in",
      to: "/login",
    },
  };

  const { cta, description, to } = text[screen];
  return (
    <Container>
      {screen === "login" && (
        <ForgotLink to="/forgot-password">Forgot password?</ForgotLink>
      )}
      <BottomText>
        <SmallText>{description}</SmallText>
        <StyledLink to={to}>{cta}</StyledLink>
      </BottomText>
    </Container>
  );
};

const ForgotLink = styled(Link)`
  color: ${({ theme }) => theme.colors.main};
  font-size: 0.8rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: center;
`;

const BottomText = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.text.small};
  color: ${({ theme }) => theme.colors.main};
  margin-left: 0.3rem;
`;
